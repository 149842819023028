import React, { useState } from 'react';
import Button from 'components/global/button';
import AutoComplete from 'components/global/googleAutocomplete';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { FUNNEL } from 'lib/constants/translations';


const AddressMaster = (props = {}) => {
  const { fields = {}, setField, nextStep, isBuyerPage } = props;
  const { address = '', city, state, postalCode, searchType, isSpanishFunnel } = fields;

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';
  const currentType = isBuyerPage ? 'isBuyerPage' : (searchType || 'SELLER');

  const { placeholder } = FUNNEL.address.button[language].find(e => e.type === currentType) || {};
  const headline = FUNNEL.address.question[language][currentType];

  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const onFieldStatus = (state) => {
    setEnabled(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  } 

  const checkFields = () => {
    if (address && !city && !state && !postalCode) {
      setField('street', address); 
      setField('city', '');
      setField('state', '');
      setField('postalCode', '');
    }
  }

  const onSubmit = () => {
    if (address) {
      checkFields();
      onFieldStatus(true);
      nextStep();
    } else {
      onFieldStatus(false);
    }
  };

  return (
    <div className='form-wrapper'>
      <span className='form-subtitle'>{FUNNEL.address.subtitle[language]}</span>
      <h2 className='form-title'>{headline || FUNNEL.address.question[language].SELLER}</h2>
      
      <div>
        <AutoComplete
          address={address}
          placeholder={placeholder}
          setField={setField}
          enabledField={enabled}
          onFieldStatus={onFieldStatus}
          checkFields={checkFields}
          usedAutocomplete={usedAutocomplete}
        />
      </div>
      <Button
        text={FUNNEL.address.nextText[language]}
        color={BUTTON_COLORS.CTA.DEFAULT}
        onClick={onSubmit}
        dataCY={'search_cta'}
      />
    </div>
  );
};

export default AddressMaster;
