import React from 'react'
import classNames from 'classnames'

const Button = (props) => {
  const { color, classes, disabled, text, onClick, dataCY, dataCreditScore, loading = false } = props;

  const classList = {
    button: classNames('button', {
      [`button--${color}`]: !!color,
      [classes]: !!classes,
    })
  };

  return (
    <button
      className={classList.button}
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCY}
      data-cs={dataCreditScore}
      type={'button'}
    >{loading && <i className="lds-dual-ring"/>}{text}
    </button>
  );
};

export default Button
