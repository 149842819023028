import React from 'react';
import Button from 'components/global/button';
import { BUTTON_COLORS } from 'lib/constants/colors';

const BUY_OR_SELL_BUTTONS = [
  {
    type: 'SELLER',
    text: 'I\'m Selling',
    class: 'button--filled__seller'
  },
  {
    type: 'BUYER',
    text: 'I\'m Buying',
    class: 'button--filled__buyer'
  }
];
const Landing = ( props = {} ) => {
  const { setField, nextStep, componentID } = props;

  const setSearchType = (value) => () => {
    setField('searchType', value);
    setField('address', '');
    nextStep();
  };

  return [
    <div>
      { componentID === 'MyAgentFinderSkinny' ?
        <div className={'main-form--buy-or-sell-buttons'}>
          {BUY_OR_SELL_BUTTONS.map((button, idx) => (
            <Button
              text={button.text}
              color={BUTTON_COLORS.CTA.DEFAULT}
              classes={button.class}
              onClick={setSearchType(button.type)}
              key={button.text+idx}
              dataCY={`cta-${button.type}`}
            />
          ))}
        </div>
      :
        <div className="container--narrow">
          <div className='main-form__landing-header main-form__header'>
          </div>
          <div className={'main-form--buy-or-sell-buttons'}>
            {BUY_OR_SELL_BUTTONS.map((button, idx) => (
              <Button
                text={button.text}
                color={BUTTON_COLORS.CTA.DEFAULT}
                classes={button.class}
                onClick={setSearchType(button.type)}
                key={button.text+idx}
                dataCY={`cta-${button.type}`}
              />
            ))}
          </div>
        </div>
      }
    </div>
  ]
};

export default Landing;
