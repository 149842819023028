import React from 'react';
import Button from 'components/global/button';
import { FUNNEL } from 'lib/constants/translations';

const CreditScoreMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }

  const { creditScore = '', isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const setActiveButton = (value) => () => {

    //always pass EN value to SF
    let creditScoreValue = FUNNEL.creditScore.button['ENGLISH'].find(item => item.value === value);

    // Custom mapping for SF REF-876
    if (value === '300-579') {
      creditScoreValue.text = 'Very Poor';
    }

    setField('creditScore', creditScoreValue.text);
    nextStep();
  };

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{FUNNEL.creditScore.question[language]}</h2>
      <div className='form-buttons short-btns credit'>
      {FUNNEL.creditScore.button[language].map(item => {
          const englishText = FUNNEL.creditScore.button['ENGLISH'].find(enItem => enItem.value === item.value)?.text;
          const spanishText = FUNNEL.creditScore.button['SPANISH'].find(spItem => spItem.value === item.value)?.text;

          return (
            <Button
              text={item.text}
              color={(creditScore === englishText || creditScore === spanishText) ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
              onClick={setActiveButton(item.value)}
              key={item.value}
              classes={item.value + ' creditscore-button'}
              dataCY={'creditScore_button'}
              dataCreditScore={item.value}
            />
          );
      })}
      </div>
    </div>
  );
};

export default CreditScoreMaster;
