import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  centered: PropTypes.bool,
  loose: PropTypes.bool,
  tight: PropTypes.bool,
  color: PropTypes.string,
  subHeadingColor: PropTypes.string,
  subHeading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  subHeadingSize: PropTypes.string,
  isSubHeadingEqualHeading: PropTypes.bool,
  headline: PropTypes.shape({
    size: PropTypes.string,
    copy: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ])
  }),
  classes: PropTypes.string,
};

const Heading = (props) => {
  const { subHeadingSize = '', isSubHeadingEqualHeading = false, classes = '' } = props;
  const classList = {
    wrapper: classNames('heading', {
      'ta-center': !!props.centered,
      'heading--loose': !!props.loose,
      'heading--tight': !!props.tight,
    }),
    matchHeading: classNames({
      'subheading': !isSubHeadingEqualHeading,
      'heading': isSubHeadingEqualHeading,
      [classes]: !!classes,
    })
  };

  const styles = {
    wrapper: {
      color: !!props.color && props.color,
      paddingBottom: props.extraPadding ? '10vmin' : '0',
    },
    subHeading: {
      color: props.subHeadingColor,
    },
  };

  return (
    <div className={classList.wrapper} style={styles.wrapper}>
      {!!props.headline && <h1 className={`heading ${props.headline.size}`}>{props.headline.copy}</h1> }
      {!!props.subHeading &&
        <h2 style={styles.subHeading} className={`${classList.matchHeading} ${subHeadingSize}`}>
          {props.subHeading}
        </h2>
      }
    </div>
  )
};

Heading.propTypes = propTypes;

export default Heading
