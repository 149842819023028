import React from 'react';
import Accordion from 'components/global/accordion';
import StellarFiLogo from 'images/logos/stellar-fi-logo_white.svg';
import HamStellarFiLogo from 'images/logos/ham-stellarfi.svg';

const PoorCreditLegacy = () => {

  const onClick = () => {
    window.location.href = creditPartner.url;
  };

  const mortgageItems = {
    StellarFi : {
      brand: 'StellarFi',
      logo: StellarFiLogo,
      hamLogo: HamStellarFiLogo,
      url: 'https://stellarfi.com/home-and-money',
      videoUlr: 'https://www.youtube.com/embed/Z9-A4gogC94?si=XA5Xy551mDLqPjP_&amp;controls=0&rel=0',
      accordionItem: ''
    },
    
  }

  const creditPartner = mortgageItems.StellarFi;

  const ResponsiveIframe = ({ src, title }) => {
    return (
      <div className="responsive-iframe-wrapper">
        <iframe
          src={src}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const accordionItems = [
    {
      title: 'Why do I need to build credit?',
      content: 'In short, to get a loan, most agents require pre-approval to start, and pre-approval is unlikely with a credit score below 620. By increasing your score, we can improve your chances of mortgage pre-approval and match you with an agent successfully.'
    },
    {
      title: 'How does it work?',
      content: 'StellarFi works with you on something you already do - pay your bills. But most of your bill payments go unreported because they are not made on loans or credit cards. StellarFi\'s unique solution reports payments like your phone bill, rent, yoga class, or favorite streaming service to the credit bureaus. Now, the bills you already pay can build your credit. <br/> </br> Because they are on-time, successful payments, and recurring payments naturally build up positive payment history, they have the ability to increase your score.'
    },
  ];

  return (
    <div className='form-wrapper poor-credit-lp'>
       <style jsx>{`
        .funnel .page-wrapper {
          background-color: #7B81BC;
        }

        .poor-credit-lp .lp-hero .lp-title .title {
          color: #fff;
          font-size: 30px;
          font-family: "nocturne-serif";
          font-weight: 700;
          margin-bottom: 16px;
          line-height: 1.2;

          @media (min-width: 768px) {
            font-size: 40px;
          }
        }
        .poor-credit-lp .lp-hero .lp-title .partnerships .support-title {
          color: #fff;
        }
        .poor-credit-lp .lp-hero .lp-ctas .button {
          background: #373E82;
        }
        .poor-credit-lp .lp-hero .lp-ctas .button:hover {
          background: #2A2F64;
        }
      `}</style>
       <div className='lp-hero'>
        <div className='lp-title'>
          <h2 className='title'>Before connecting with an agent, we recommend building your credit.</h2>
          <div className='partnerships'>
            <span className='support-title'>With help from our credit-building partner</span>
            <span className='line'></span>
            <img src={creditPartner.logo} alt="Logo" />
          </div>
        </div>
        <div className='lp-ctas'>
          <button className='button button--active' onClick={onClick}>Start Now</button>
        </div>
      </div>

      <div className='lp-about-partnership'>

        <div className='lp-about-partnership-hero'>
          <h2 className='title'>Build Credit with StellarFi</h2>
          <ResponsiveIframe
            src={creditPartner.videoUlr}
            title="YouTube video player"
          />
          <p>To help you out, we've partnered with StellarFi, a credit-building service. Once your score improves, we'll gladly match you with an agent and help you find your dream home!</p>
        </div>

        <div className='lp-about-partnership-accordion'>
          <Accordion items={accordionItems} />
        </div>

        <div className='lp-about-partnership-ctas'>
          <button className='button' onClick={onClick}>Start Building Credit</button>
          <div className="partnerships">
            <img src={creditPartner.hamLogo} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoorCreditLegacy;
