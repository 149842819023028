import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import Heading from 'components/global/heading';
import AutoComplete from 'components/global/googleAutocomplete';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import PinkStars from 'images/funnel-steps/pink_stars.png';
import NavyStars from 'images/funnel-steps/navy_stars.png';
import validator from 'validator';

const NameAddress = (props = {}) => {
  const {fields = {}, setField, previousStep, nextStep, isBuyerPage, isBuyerPrograms } = props;
  const {name = '', address = '', searchType} = fields;
  const updateField = (field) => (e) => {
    onNameFieldStatus(true);
    setField(field, e.target.value);
  }

  let header = 'Let\'s connect you with the best agent in your area';
  if (isBuyerPage) {
    header = 'Let\'s connect you with your first-time home buyer specialist';
  }
  if (isBuyerPrograms) {
    header = 'What is your full name?';
  }

  const isNameValid = (name) => {
    return (validator.isAlpha(name, 'en-US', {
      ignore: ' -'
    }));
  }
  
  const onSubmit = () => {
    if (name && isNameValid(name)) {
      onNameFieldStatus(true);
    } else {
      onNameFieldStatus(false);
    }

    if (address) {
      checkFields();
      onAddressFieldStatus(true);
    } else {
      onAddressFieldStatus(false);
    }
    

    if (name && isNameValid(name) && address) {
      nextStep();
    }
  };

  const onBack = () => previousStep();
  
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [addressField, setAddressField] = useState(true);
  const [nameField, setNameField] = useState(true);

  const onAddressFieldStatus = (state) => {
    setAddressField(state);
  }

  const onNameFieldStatus = (state) => {
    setNameField(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  } 

  const checkFields = () => {
    //seller & buyer have different UI on this step, so different behavior
    if (searchType === 'SELLER' && !isAutocomplete) {
      setField('street', address); 
      setField('city', '');
      setField('state', '');
      setField('postalCode', '');
    }
  }
  
  return (
    <div className='container container--normal ta-center rebranding'>
      <div className={"main-form__header m-b-30"}>
        {!isBuyerPrograms &&
          <>
            <img
              src={isBuyerPage ? NavyStars : PinkStars}
              alt='reviews'
              className='rebranding-avatar-stars'
            />
            <Heading
              classes="italic"
              color={COLORS.WHITE}
              subHeading={['"They were there for me the whole way making sure my agent was working for me." ', <span className="rebranding-name-mobile">- Nikki, TN</span>]}
            />
            <span className="rebranding-name">- Nikki, TN</span>
         </>
        }
        
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            copy: header,
            size: 'h2'
          }}
        />
      </div>
      <div className='rebranding-group container'>
        { searchType === "SELLER" &&
        <div className='rebranding-group-inputs'>
          <div className={address ? 'rebranding-group-inputs-wrap' : 'rebranding-group-inputs-wrap required'}>
            <AutoComplete
              address={address} 
              placeholder='Property Address'
              setField={setField}
              enabledField={addressField}
              onFieldStatus={onAddressFieldStatus}
              checkFields={checkFields}
              usedAutocomplete={usedAutocomplete}
            />
          </div>
        </div>
        }
        <div className='rebranding-group-inputs'>
          <div className={name ? 'rebranding-group-inputs-wrap' : 'rebranding-group-inputs-wrap required'}>
            <Input
              value={name}
              onChange={updateField('name')}
              placeholder={'First & Last Name'}
              dataCY={'full_name'}
              className= { nameField ? null : 'input--invalid'}
            />
          </div>
          <Button
            text={'Next'}
            color={BUTTON_COLORS.CTA.DEFAULT}
            onClick={onSubmit}
            dataCY={'search_cta'}
          />
        </div>
      </div>
      <div className='find-agent-button-container'>
        <Button
          text={'Back'}
          color={BUTTON_COLORS.LINK.WHITE}
          onClick={onBack}
          classes={'back-button'}
          dataCY={'back_button'}
        />
      </div>
    </div>
  );
};

export default NameAddress;