import React from 'react';
import Button from 'components/global/button';
import { ANSWERS } from 'lib/constants/funnelMeta';
import { isMRCState } from "utils/mortgageRedirect";

const BUTTONS = [
  { text: 'Yes', value: ANSWERS.OTHERAGENT.YES },
  { text: 'No', value: ANSWERS.OTHERAGENT.NO },
  { text: 'I\'m already working with one', value: ANSWERS.OTHERAGENT.HASONE },
];

const ConnectMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }

  const { otherAgent = null, city, state } = fields;

  const setActiveButton = (value) => () => {
    setField('otherAgent', value);
    nextStep();
  };

  const subtitle = isMRCState(state) ? 'Home & Money offers buyers up to $1,000 cash back after closing.' : 'We have a network of 40,000+ buyer agents.';

  return (
    <div className='form-wrapper'>
      {
        <span className='form-subtitle'>{subtitle}</span>
      }
      <h2 className='form-title'>Would you like us to connect you with a couple great agents in {city || state}?</h2>
      <div>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={otherAgent === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
          />
        )}
        {
        isMRCState(state) &&
          <div className='disclaimer'>
            <span className='disclaimer-badge'>💰 You're eligible*</span>
            <span className='disclaimer-text'>Get up to $1,000 back after closing when you work with an agent we refer. See <a target='_blank' href='https://homeandmoney.com/first-time-home-buyer/offer/'> offer details.</a></span>
          </div>
        }
      </div>
    </div>
  );
};

export default ConnectMaster;
