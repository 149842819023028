const initSubnav = () => {
  const subnav = document.querySelector('.ham-content-custom-blocks-Subnav > .wp-block-group__inner-container');

  if (subnav) {
    subnav.onclick = () => {
      subnav.classList.toggle('subnav-open');
    }
  }
}

export {
  initSubnav,
}
