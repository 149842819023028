import React, { Component, useEffect } from 'react';
import Input from 'components/global/input';
import Button from 'components/global/button';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { sendToDataLayer } from 'lib/logGTM';
import { useSelector,useDispatch } from 'react-redux';
import { getSecondaryForm, setSecondaryField, setSecondaryComplete, submitForm, updateParams } from 'store/index';
import { maskValue } from 'utils/mask';
import { getExperimentID } from 'lib/trackingParams';
import { FORM, SOURCE } from 'lib/constants/funnelMeta';

const SecondaryForm = () => {
  const { complete = false, fields = {} } = useSelector(getSecondaryForm);
  const { name = '', phone = '' } = fields;
  const dispatch = useDispatch();
  const updateField = (field) => (e) => dispatch(setSecondaryField(field,e.target.value));
  const updatePhone = (e) => dispatch(setSecondaryField('phone',maskValue(e)));

  useEffect(() => {

    dispatch(setSecondaryField('leadsource', SOURCE.MAF_SHORT_ADDRESS));

  },[]);



  const completeForm = () => {

    sendToDataLayer({
      event: 'form_callmeback',
      formName: FORM.CALLMEBACK,
      fields
    });
    // send Optimize cookie value to Salesforce
    dispatch(updateParams(getExperimentID()));
    dispatch(setSecondaryComplete(true));
    dispatch(submitForm('secondary'));
  };

  const subMessage = complete ? `We'll get back to you soon!` : 'Simply enter your details below and we\'ll give you a call';

  const isPhoneValid = phone && (phone.toString()).length === 14; // (555) 555-5555
  const canSubmitForm = name && isPhoneValid;

  return (
    <div className={'ta-center'}>
      <span className={`call-me-msg${complete ? '-completed' : ''}`}>{subMessage}</span>
      <div className='form'>
        <div className={'flex form__fields'}>
          { !complete && <Input placeholder={'Full Name'} value={name} onChange={updateField('name')}/>}
          { !complete && <Input type="tel" placeholder={'Phone Number'} value={phone} onChange={updatePhone}/>}
          {!complete && <Button
          classes={'form__submit'}
          text={'Get Started'}
          color={BUTTON_COLORS.CTA.ACTIVE}
          onClick={completeForm}
          disabled={!canSubmitForm}
        />}
        </div>
      </div>
    </div>
  );
};

class CallMeBack extends Component {

  render() {
    return <SecondaryForm/>;
  };
}

export default CallMeBack
