import React from 'react';

const SvgSell = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 65 55" fill="none">
      <path d="M47.3976 29.4932C50.3567 16.5729 42.2816 3.70004 29.3613 0.740898C16.441 -2.21825 3.56821 5.85687 0.609062 18.7772C-2.35008 31.6975 5.72503 44.5703 18.6453 47.5294C31.5656 50.4886 44.4385 42.4135 47.3976 29.4932Z" fill="#CACCE4"/>
      <path d="M60.001 16.1313H18.001C16.8964 16.1313 16.001 17.0268 16.001 18.1313V50.1313C16.001 51.2359 16.8964 52.1313 18.001 52.1313H60.001C61.1055 52.1313 62.001 51.2359 62.001 50.1313V18.1313C62.001 17.0268 61.1055 16.1313 60.001 16.1313Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.001 17.1313C17.4487 17.1313 17.001 17.5791 17.001 18.1313V50.1313C17.001 50.6836 17.4487 51.1313 18.001 51.1313H60.001C60.5533 51.1313 61.001 50.6836 61.001 50.1313V18.1313C61.001 17.5791 60.5533 17.1313 60.001 17.1313H18.001ZM15.001 18.1313C15.001 16.4745 16.3441 15.1313 18.001 15.1313H60.001C61.6578 15.1313 63.001 16.4745 63.001 18.1313V50.1313C63.001 51.7882 61.6578 53.1313 60.001 53.1313H18.001C16.3441 53.1313 15.001 51.7882 15.001 50.1313V18.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.001 11.1313C10.001 10.5791 10.4487 10.1313 11.001 10.1313H62.001C62.5533 10.1313 63.001 10.5791 63.001 11.1313C63.001 11.6836 62.5533 12.1313 62.001 12.1313H11.001C10.4487 12.1313 10.001 11.6836 10.001 11.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.001 9.13135C25.5533 9.13135 26.001 9.57906 26.001 10.1313V16.1313C26.001 16.6836 25.5533 17.1313 25.001 17.1313C24.4487 17.1313 24.001 16.6836 24.001 16.1313V10.1313C24.001 9.57906 24.4487 9.13135 25.001 9.13135Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.001 9.13135C52.5533 9.13135 53.001 9.57906 53.001 10.1313V16.1313C53.001 16.6836 52.5533 17.1313 52.001 17.1313C51.4487 17.1313 51.001 16.6836 51.001 16.1313V10.1313C51.001 9.57906 51.4487 9.13135 52.001 9.13135Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M49.001 40.1313H54.001V42.1313H49.001V40.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M48.001 36.1313C48.001 35.5791 48.4487 35.1313 49.001 35.1313H55.001V37.1313H50.001V45.1313H55.001V47.1313H49.001C48.4487 47.1313 48.001 46.6836 48.001 46.1313V36.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M36.001 23.1313C36.001 22.5791 36.4487 22.1313 37.001 22.1313H41.001V24.1313H38.001V28.1313H36.001V23.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M36.001 28.1313C36.001 27.5791 36.4487 27.1313 37.001 27.1313H41.001V29.1313H38.001V32.1313H36.001V28.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M49.001 23.1313C49.001 22.5791 49.4487 22.1313 50.001 22.1313H52.001C53.6533 22.1313 55.001 23.4791 55.001 25.1313V26.1313C55.001 27.7836 53.6533 29.1313 52.001 29.1313H50.001C49.4487 29.1313 49.001 28.6836 49.001 28.1313V23.1313ZM51.001 24.1313V27.1313H52.001C52.5487 27.1313 53.001 26.6791 53.001 26.1313V25.1313C53.001 24.5836 52.5487 24.1313 52.001 24.1313H51.001Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M49.001 28.1313C49.001 27.5791 49.4487 27.1313 50.001 27.1313H51.001C53.2133 27.1313 55.001 28.9191 55.001 31.1313V32.1313H53.001V31.1313C53.001 30.0236 52.1087 29.1313 51.001 29.1313V32.1313H49.001V28.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.001 24.1313C42.001 23.0268 42.8964 22.1313 44.001 22.1313H46.001C47.1055 22.1313 48.001 23.0268 48.001 24.1313V30.1313C48.001 31.2359 47.1055 32.1313 46.001 32.1313H44.001C42.8964 32.1313 42.001 31.2359 42.001 30.1313V24.1313ZM46.001 24.1313H44.001V30.1313H46.001V24.1313Z" fill="#434CA0"/>
      <path d="M32.9564 26.6297L31.5286 25.3065C31.5169 25.2961 31.5108 25.2803 31.5108 25.2638V22.9944C31.5108 22.9414 31.4713 22.8981 31.4228 22.8981H30.1087C30.0602 22.8981 30.0207 22.9414 30.0207 22.9944V23.8572C30.0207 23.8779 29.999 23.8889 29.9845 23.8755L28.1814 22.204C28.0773 22.1071 27.9247 22.1071 27.8206 22.204L23.0456 26.6297C22.9565 26.7119 23.0099 26.8708 23.1263 26.8708H24.1587C24.2244 26.8708 24.2779 26.9293 24.2779 27.0011V31.8963C24.2779 32.0254 24.3748 32.1313 24.4928 32.1313H31.5103C31.6283 32.1313 31.7241 32.0266 31.7241 31.8975V27.0011C31.7241 26.9287 31.7775 26.8702 31.8438 26.8702H32.8757C32.992 26.8702 33.0455 26.7113 32.9564 26.6291V26.6297Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M28.3882 28.19C28.2707 28.1529 28.1405 28.1313 28.0019 28.1313C27.7908 28.1313 27.6032 28.178 27.444 28.2601C27.2822 28.3442 27.1545 28.4615 27.079 28.5983C27.0293 28.6902 27.001 28.7899 27.001 28.8974V32.1313H29.001V28.8974C28.9984 28.7369 28.9386 28.5922 28.829 28.4693C28.722 28.3464 28.5679 28.2486 28.3882 28.19Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.001 37.1216C25.4533 37.1216 25.001 37.5739 25.001 38.1216V38.6816C25.001 39.1146 25.2784 39.4983 25.6837 39.6317L25.6881 39.6332L25.6881 39.6332L28.9461 40.7225C30.1795 41.1298 31.001 42.2854 31.001 43.5716V44.1316C31.001 45.7839 29.6533 47.1316 28.001 47.1316H26.001C24.3487 47.1316 23.001 45.7839 23.001 44.1316H25.001C25.001 44.6793 25.4533 45.1316 26.001 45.1316H28.001C28.5487 45.1316 29.001 44.6793 29.001 44.1316V43.5716C29.001 43.1385 28.7235 42.7549 28.3182 42.6214L28.3139 42.62L28.3139 42.62L25.0558 41.5306C23.8225 41.1234 23.001 39.9677 23.001 38.6816V38.1216C23.001 36.4693 24.3487 35.1216 26.001 35.1216H28.001C29.6533 35.1216 31.001 36.4693 31.001 38.1216H29.001C29.001 37.5739 28.5487 37.1216 28.001 37.1216H26.001Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.001 37.1313C34.4533 37.1313 34.001 37.5836 34.001 38.1313V47.1313H32.001V38.1313C32.001 36.4791 33.3487 35.1313 35.001 35.1313H37.001C38.6533 35.1313 40.001 36.4791 40.001 38.1313V47.1313H38.001V38.1313C38.001 37.5836 37.5487 37.1313 37.001 37.1313H35.001Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M39.001 43.1313H33.001V41.1313H39.001V43.1313Z" fill="#434CA0"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M41.001 46.1313V35.1313H43.001V45.1313H47.001V47.1313H42.001C41.4487 47.1313 41.001 46.6836 41.001 46.1313Z" fill="#434CA0"/>
      <path d="M11.001 6.13135H6.00098V58.1313H11.001V6.13135Z" fill="#D17852"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.00098 6.13135C5.00098 5.57906 5.44869 5.13135 6.00098 5.13135H11.001C11.5533 5.13135 12.001 5.57906 12.001 6.13135V58.1313C12.001 58.6836 11.5533 59.1313 11.001 59.1313H6.00098C5.44869 59.1313 5.00098 58.6836 5.00098 58.1313V6.13135ZM7.00098 7.13135V57.1313H10.001V7.13135H7.00098Z" fill="#434CA0"/>
      <path d="M5.00098 58.1313H12.001C13.661 58.1313 15.001 59.4713 15.001 61.1313V63.1313H2.00098V61.1313C2.00098 59.4713 3.34098 58.1313 5.00098 58.1313Z" fill="#D17852"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.00098 59.1313C3.89326 59.1313 3.00098 60.0236 3.00098 61.1313V62.1313H14.001V61.1313C14.001 60.0236 13.1087 59.1313 12.001 59.1313H5.00098ZM1.00098 61.1313C1.00098 58.9191 2.78869 57.1313 5.00098 57.1313H12.001C14.2133 57.1313 16.001 58.9191 16.001 61.1313V63.1313C16.001 63.6836 15.5533 64.1313 15.001 64.1313H2.00098C1.44869 64.1313 1.00098 63.6836 1.00098 63.1313V61.1313Z" fill="#434CA0"/>
    </svg>
  );
};

export default SvgSell;
