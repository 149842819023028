import React, { useState } from 'react';

const CarouselArrow = ({ direction }) => (
  <div className='TestimonialCarouselArrow w-auto p-2 bg-[#eaecf0] hover:bg-[#D0D5DD] cursor-pointer rounded-[18px] justify-center items-center gap-3 flex'>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      {direction === 'left' ? (
        <path d="M12.5 15L7.5 10L12.5 5" stroke="#434CA0" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
      ) : (
        <path d="M7.5 5L12.5 10L7.5 15" stroke="#434CA0" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
      )}
    </svg>
  </div>
);

const Testimonials = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    const prevIndex = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
    setCurrentIndex(prevIndex);
  };

  const handleNextClick = () => {
    const nextIndex = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  return (
    <div className='inline-flex flex-col items-start justify-start w-full gap-4 FinderLinkWidgetWrapper'>
      <div className='flex flex-col items-start self-stretch justify-start gap-6 p-6 Testimonials bg-gray-50 rounded-xl'>
        <div className='flex flex-col items-start self-stretch justify-start gap-4 Frame898'>
          <div className="Headline pt-[.75rem] w-full text-[#2A2F64] text-center text-[19px] sm:text-[25px] not-italic font-medium leading-[120%] tracking-[-0.4px]">
            Testimonials
          </div>
          <div className="testimonial-slider" style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
            <div
              style={{
                display: 'flex',
                transition: 'transform 0.5s ease-in-out',
                transform: `translateX(${-currentIndex * 100}%)`,
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="testimonial-item flex flex-col gap-[16px] flex-[0_0_100%]"
                >
                  <div className="self-stretch text-[#667085] text-center text-[14px] not-italic font-normal leading-[150%]">
                    "{testimonial}"
                  </div>
                  <div className='flex flex-col items-center self-stretch justify-start gap-1 Frame578'>
                    <div className='flex flex-col items-center self-stretch justify-start gap-1 Frame870'>
                      <div className='Frame577 pt-1 justify-start items-start gap-1.5 inline-flex'>
                        {[...Array(5)].map((_, i) => (
                          <svg key={i} xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M11.9349 16.5C11.5892 16.4986 11.253 16.3828 10.9748 16.1693L8.66123 14.421C8.26736 14.1218 7.73272 14.1218 7.33884 14.421L5.02524 16.1693C4.45284 16.6019 3.67775 16.6019 3.10532 16.1694C2.53288 15.7369 2.29329 14.9702 2.51184 14.2702L3.39559 11.4413C3.54718 10.9591 3.38204 10.4303 2.98697 10.1328L0.673374 8.38448C0.100941 7.95191 -0.138598 7.18515 0.0800396 6.48521C0.298677 5.78527 0.925759 5.31138 1.63331 5.31138H4.49314C4.98077 5.3123 5.41316 4.98556 5.56302 4.5029L6.44664 1.674C6.66526 0.973971 7.2924 0.5 8.00003 0.5C8.70765 0.5 9.33479 0.973971 9.55341 1.674L10.437 4.5029C10.5869 4.98556 11.0193 5.3123 11.5069 5.31138H14.3667C15.0743 5.31141 15.7013 5.78531 15.92 6.48523C16.1386 7.18515 15.8991 7.9519 15.3267 8.38448L13.0131 10.1328C12.618 10.4303 12.4529 10.9591 12.6045 11.4413L13.4882 14.2702C13.715 14.9701 13.4739 15.7419 12.8948 16.1693C12.6167 16.3827 12.2805 16.4985 11.9349 16.5Z" fill="#ED9C7B"/>
                          </svg>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='flex flex-row justify-center gap-4 Frame897'>
          <a onClick={handlePrevClick} >
            <CarouselArrow direction="left" />
          </a>
          <a onClick={handleNextClick} >
            <CarouselArrow direction="right" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;