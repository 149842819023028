import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';

const BUTTONS = [
  { text: '0 - 3 Months', value: '0 - 3 Months' },
  { text: '3 - 6 Months', value: '3 - 6 Months' },
  { text: '6 - 12 Months', value: '6 - 12 Months' },
  { text: 'Over 1 Year', value: 'Over 1 Year' },
];

const HEADER = {
  Buyer: 'How soon are you looking to buy?',
  Seller: 'How soon are you looking to sell?',
};


const TimeFrame = ({ fields = {}, setField, nextStep, previousStep}) => {

  const BUTTON_STYLE = {
    DEFAULT: BUTTON_COLORS.CTA.DEFAULT,
    ACTIVE: BUTTON_COLORS.CTA.DARK_ROSE,
  }
  
  const onBack = () => { previousStep(); setField('timeframe', ''); };
  const { timeframe = '', searchType } = fields;
  const setActiveButton = (value) => () => {
    setField('timeframe', value);
    nextStep();
  };

  return (
    <div className='container container--normal ta-center rebranding'>
      <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: searchType === "BUYER" ? HEADER.Buyer : HEADER.Seller
          }}
        />
      </div>
      <div className='container container--narrow'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={timeframe === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'property-button'}
            dataCY={'timeFrame_button'}
          />
        )}
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeFrame;
