import Cookies from 'js-cookie';
import qs from 'qs';

// take VWO available experimentIDs and send it to SF
export const getExperimentID = () => {
  try {
    let data = {};

    const vwoIDs = getVWOExperimentIDs();
   
    data = {
      'experimentId' : [vwoIDs].filter(Boolean).join(", ")
    }
    return data;

  } catch (err) {
    return {};
  }
};

const getVWOExperimentIDs = () => {
  const vwoCookie = '_vis_opt_exp_'; //_vis_opt_exp_69_combi
  const vwoArr = document.cookie.split(';').filter(c => c.trim().indexOf(vwoCookie) === 0).map(c => c.trim());
  return vwoArr.join(', ');
}


export const loadParams = () => {

  const cookieName = 'trackingParams';
  try {
    let params = {};
    if (typeof window !== 'undefined') {
      params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    }

    // These params will be stored in Redux and passed via API to DB and Salesforce.
    // When updating this list, make sure to update services.js list as well.
    var paramWhiteList = [
      'utm_account',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_adgroup',
      'utm_content',
      'utm_keyword',
      'device',
      'devicemodel',
      'network',
      'placement',
      'macthtype',
      'adposition',
      'creative',
      'gclid',
      'wbraid',
      'iloc',
      'ploc',
      'msclkid',
      'fbclid',
      'affiliateAgentID',
      'affiliateLeadContactID',
    ];

    let allowedParams = Object.keys(params)
      .filter(key => paramWhiteList.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: params[key]
        };
      }, {});

    const originUrl = { originUrl: document.location.href };

    const paramKeys = Object.keys(allowedParams);
    if (paramKeys && paramKeys.length) {
      // If we have new UTM params, save all of them in a new `utm_par` query string
      allowedParams = {...allowedParams, 'utm_par': qs.stringify(allowedParams), ...originUrl };
      Cookies.set(cookieName, JSON.stringify(allowedParams), 90);
      return allowedParams;
    }

    const tempParams = Cookies.get(cookieName);
    if (!tempParams){
      Cookies.set(cookieName, originUrl, 90);
      return originUrl;
    }
    return JSON.parse(tempParams);
  } catch (err) {
    return {};
  }
};

export const setFBCookies = () => {
  let fbc = Cookies.get('_fbc');

  if (!fbc) {
    const urlParams = new URLSearchParams(window.location.search);
    fbc = createFBC(urlParams.get('fbclid'));
    if (fbc) {
      Cookies.set('_fbc', fbc, 90);
    }
  }
  return fbc;
};

export const getFBCookies = () => {
  const fbp = Cookies.get('_fbp');
  const fbc = Cookies.get('_fbc');

  const result = [];

  if (fbp) {
    result.push({ 'fbp' : fbp });
  }

  if (fbc) {
    result.push({ 'fbc' : fbc });
  }

  return result;
};

const createFBC = (fbclid) => {
  let fbc = '';
  if (fbclid) {
    fbc = 'fb.1.' + Date.now() + '.' + fbclid;
  }
  return fbc;
}

export default {
  getExperimentID,
  loadParams,
  setFBCookies,
  getFBCookies,
};
