import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
      default: PropTypes.bool,
    })
  ),
  placeholder: PropTypes.string,
};


const Dropdown = (props) => {
  const { options, placeholder, onChange, value, dataCY, className } = props;

  const handleChange = (e) => {
    onChange(e);
  };

  // map create option components
  const optionsComponents = [];
  options && options.forEach((el, i) => {
    optionsComponents.push(<option key={i} value={el.value} >{el.text}</option>)
  });

  const classList = {
    select: classNames('input select', { [className]: !!className })
  };

  return (
    <div className={'dropdown'}>
      <select 
        value={value} 
        onChange={handleChange} 
        data-cy={dataCY}
        className={classList.select}
      >
        <option hidden>{placeholder}</option>
        {optionsComponents}
      </select>
    </div>
  );
};

Dropdown.propTypes = propTypes;

export default Dropdown;
