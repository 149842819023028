import React, { useEffect } from 'react';
import MainForm from 'components/maf/form-wizard';
import { addBodyClass } from "utils/utils";
import { CLASS } from 'lib/constants/funnelMeta';

const Top = (props) => {
  const { componentID } = props;
  
  useEffect(() => {
    addBodyClass(CLASS.AGENTFINDER.DEFAULT);
  },[]);

  return (
    <React.Fragment>
      { componentID === 'MyAgentFinderSkinny' ? <MainForm {...props} />
      :
      <div className={'container container--wide'}>
        <div className='main-form'>
          <MainForm
            {...props }
            isBuyerPage={componentID === 'MyAgentFinderBuyer' || componentID === 'MyAgentFinderBuyerPrograms' }
            isBuyerPrograms= {componentID === 'MyAgentFinderBuyerPrograms'}
          />
        </div>
      </div>
      }
    </React.Fragment>
  );
};

export default Top;
