import React from 'react'
import ContactForm from './contactForm';
import OurMission from './ourMission';


const ContactPage = () => {
  return (
    <React.Fragment>
      <OurMission />
      <ContactForm />
    </React.Fragment>
  );
};

export default ContactPage;

