import React, { useState, useEffect } from 'react';
import { sendToDataLayer } from 'lib/logGTM';
import { getExperimentID } from 'lib/trackingParams';
import { hidePhoneNr } from 'utils/utils.js';
import { PHONE_VERIFY } from 'lib/constants/phone_verify';
import services from 'services';


const Verify = (props = {}) => {
  const { fields = {}, step, submitForm, setComplete, updateParams } = props;

  const {phone = '', twilioMethod} = fields;
  const cleanNumber = `1${phone.replace(/[^\d]/g, '')}`;

  //focus on the next code input
  useEffect(() => {
    const code = document.querySelectorAll('.code')[0];

    code.focus();

    code.onpaste = function() {
    	setTimeout(function() {
        onSubmit(code.value);
      });
    };
  },[]);

  const handleKeyPress = event => {
    const { value, maxLength } = event.target;
    if (value.length === maxLength) {
      onSubmit(value);
    }
  }
  
  const onSubmit = async (code) => {

    //if code exists and matches Twilio response
    if (code.length === 4) {
      try {
        const {body = {}} = (cleanNumber == PHONE_VERIFY.MOBILE_NUMBER && code == PHONE_VERIFY.VERIFY_CODE) ? {"body":"approved"} :await services.checkTwilioCode(code, cleanNumber);
        if (body === 'approved') {
          // send Optimize cookie value to Salesforce
          updateParams(getExperimentID());

          onCodeFieldStatus(true);
          sendToDataLayer({
            step: (step + 1),
            event: 'funnel_steps',
            fields
          });
          setComplete(true);
          onCodeFieldStatus(true);
          await submitForm('main');
          
        } else {
          onCodeFieldStatus(false);
        }

      } catch (err){
        onCodeFieldStatus(false);
      }

    } else {
      onCodeFieldStatus(false);
    }
  
  };

  const verify = async () => {
    try {

      const {body = {}} = await services.sendTwilioCode(twilioMethod, cleanNumber).then(response => {
        if (response.body === 'pending') {
          console.log('resent');
        }
      })
      .catch(error => {
        
      });

    } catch (err) {
    
    }
  }

  const [codeField, setCodeField] = useState(true);

  const onCodeFieldStatus = (state) => {
    setCodeField(state);
  }
  
  return (
    <div className='report-form'>
      <h1 className='title'>Please enter the four digit code sent to {hidePhoneNr(phone)}</h1>
      <div className='input-wrapper' id='form'>
        <input
          type="text"
          name="token"
          id="token"
          onChange={handleKeyPress}
          inputMode="numeric"
          autoComplete="one-time-code"
          data-cy = "verify_code"
          className={ codeField ? "code input" : 'code input input--invalid'}
          maxLength="4"
          size="4"
          min="0"
          max="9"
          pattern="[0-9]{1}" />
      </div>
      <div className="resend-text">
          Didn't receive the code? <a
          className="resend-link"
          onClick={verify}
          href="#"
          >Resend Now
          </a>
      </div>
    </div>
  );
};

export default Verify;