import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';

const BUTTONS = [
  { text: 'Single Family', value: 'SINGLE_FAMILY' },
  { text: 'Land or Lot', value: 'LAND_OR_LOT' },
  { text: 'Condo', value: 'CONDO' },
  { text: 'Mobile Home', value: 'MOBILE_HOME' },
  { text: 'Townhome', value: 'TOWNHOME' },
  { text: 'Commercial', value: 'COMMERCIAL' },
];


const PropertyType = ({ fields = {}, setField, nextStep, previousStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: BUTTON_COLORS.CTA.DEFAULT,
    ACTIVE: BUTTON_COLORS.CTA.DARK_ROSE,
  }

  const onBack = () => { previousStep(); setField('propertyType', null); };
  const { propertyType = null, searchType } = fields;
  const setActiveButton = (value) => () => {
    setField('propertyType', value);
    nextStep();
  };

  return (
    <div className='container container--narrow ta-center rebranding'>
      <style jsx="true">{`
        .bg-hero-maf:before, .bg-hero-influencer:before {
          position: fixed !important;
        }
      `}</style>
      <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: searchType === 'BUYER' ? 'What kind of home are you looking to buy?' : 'What kind of home are you looking to sell?',
          }}
        />
      </div>
      <div className='container container--narrow'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={propertyType === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'property-button button--filled__proptype button--filled__proptype_' + item.value}
            dataCY={'property_button'}
          />
        )}
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyType;
