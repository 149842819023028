import React from 'react';
import { formatCurrency } from 'utils/utils.js';

const PropDetailsWithVal = (props) => {

  const { fields = {}, intention } = props;
  const { propertyPhoto, street, sqft, beds, baths, priceAverage } = fields;

  const propSpecs = [beds + ' bd', baths + ' ba', sqft + ' sqft'];

  const topClass = intention === 'Curious' ? '' : 'top';

  return (
    <div className="prop-details-wrapper">
      <div className={`prop-details results ${topClass}`}>
        {propertyPhoto &&
          <div className="avatar">
            <img
              src={propertyPhoto}
              alt='property photo'
            />
          </div>
        }
        <div className={`prop-wrapper${!propertyPhoto ? ' centered' : ''}`}>
          <span className="priceAverage">{formatCurrency(priceAverage)}</span>
          <span className="prop-specs">Estimated value for {street}</span>
          <div className="prop-specs">
            {propSpecs.map((spec, index) => (
              <span key={index} className={spec.split(' ')[1]}>
                {spec}
                {index !== propSpecs.length - 1 && <span className="dot-separator"> &middot; </span>}
              </span>
            ))}
          </div>
        </div>
      </div>
      {intention !== 'Curious' &&
        <div className="prop-details results bottom">
          <div className='whatsnext'>
            <h1 className='title'>What's next?</h1>
              <ul className='form-list'>
                <li className='form-list__item'>We're searching our database of 40,000+ agents and brokers</li>
                <li className='form-list__item'>We'll match you with the perfect agent and <strong>connect you soon</strong></li>
              </ul>
          </div>
        </div>
      }
    </div>
  );
};

export default PropDetailsWithVal;
