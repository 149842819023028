import React from 'react';
import { useSelector } from 'react-redux';
import { getMainForm } from 'store/index';

import Content from './content';
import AccordionWrapper from './accordionWrapper';

const CompleteWrapper = () => {

  const { fields = {} } = useSelector(getMainForm);
  const { militaryVeteran } = fields;

  return (
    <div className="container container--wide">
      <div className="main-form">
        <div className="split-wrapper">
            <div className="split-wrapper__item split-wrapper__item--body">
              <Content />
            </div>
            <div className="split-wrapper__item_cards">
              <AccordionWrapper isMilitary={militaryVeteran} />
            </div>
        </div>
      </div>
    </div>
  )
};

export default CompleteWrapper;
