import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { PROGRAMS } from 'lib/constants/funnelMeta';
import IntroLendCTA from "components/global/introLendCTA";
import Button from 'components/global/button';

//https://www.npmjs.com/package/react-accessible-accordion

const AccordionWrapper = (props) => {

	const onClick = () => {
		window.location.href = '/first-time-home-buyer/offer/';
	};

	//TODO: make a child component
  return (
    <Accordion allowMultipleExpanded allowZeroExpanded >
    	<AccordionItem uuid={PROGRAMS.REBATE.ID} className={`accordion__item ${PROGRAMS.REBATE.ID}`}>
    		<AccordionItemHeading>
					<AccordionItemButton>
							<span className="name">{PROGRAMS.REBATE.NAME}</span>
							<span className="divider"></span>
							<span className="accordion-title">{PROGRAMS.REBATE.TITLE}</span>
					</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
					<span className="contentWrapper">
						<span className="vector"/>
						<span className="content">
							{PROGRAMS.REBATE.CONTENT}
						</span>
						<span className="ctas">
							<span className="vector desktop"/>
							<Button
								text={'Offer Terms'}
								onClick={() => onClick()}
								classes='button--primary'
							/>
						</span>
					</span>
        </AccordionItemPanel>
      </AccordionItem>
			<AccordionItem uuid={PROGRAMS.FHA.ID} className={`accordion__item ${PROGRAMS.FHA.ID}`}>
				<AccordionItemHeading>
						<AccordionItemButton>
							<span className="name">{PROGRAMS.FHA.NAME}</span>
							<span className="divider"></span>
							<span className="accordion-title">{PROGRAMS.FHA.TITLE}</span>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
					<span className="contentWrapper">
						<span className="vector"/>
						<span className="content">
							{PROGRAMS.FHA.CONTENT}
						</span>
						<span className="ctas">
							<span className="vector desktop"/>
							<IntroLendCTA
								text={'Get Pre-Approved'}
								classes='button--primary'
							/>
						</span>
					</span>
        </AccordionItemPanel>
			</AccordionItem>
      <AccordionItem uuid={PROGRAMS.HOMEONE.ID} className={`accordion__item ${PROGRAMS.HOMEONE.ID}`}>
				<AccordionItemHeading>
						<AccordionItemButton>
							<span className="name">{PROGRAMS.HOMEONE.NAME}</span>
							<span className="divider"></span>
							<span className="accordion-title">{PROGRAMS.HOMEONE.TITLE}</span>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
					<span className="contentWrapper">
						<span className="vector"/>
						<span className="content">
							{PROGRAMS.HOMEONE.CONTENT}
						</span>
						<span className="ctas">
							<span className="vector desktop"/>
							<IntroLendCTA
								text={'Get Pre-Approved'}
								classes='button--primary'
							/>
						</span>
					</span>
        </AccordionItemPanel>
			</AccordionItem>
			{props.isMilitary &&
				<AccordionItem uuid={PROGRAMS.VA.ID} className={`accordion__item ${PROGRAMS.VA.ID}`}>
					<AccordionItemHeading>
							<AccordionItemButton>
								<span className="name">{PROGRAMS.VA.NAME}</span>
								<span className="divider"></span>
								<span className="accordion-title">{PROGRAMS.VA.TITLE}</span>
							</AccordionItemButton>
						</AccordionItemHeading>
						<AccordionItemPanel>
						<span className="contentWrapper">
							<span className="vector"/>
							<span className="content">
								{PROGRAMS.VA.CONTENT}
							</span>
							<span className="ctas">
								<span className="vector desktop"/>
								<IntroLendCTA
								text={'Get Pre-Approved'}
								classes='button--primary'
							/>
							</span>
						</span>
					</AccordionItemPanel>
				</AccordionItem>
			}
			<AccordionItem uuid={PROGRAMS.LTV.ID} className={`accordion__item ${PROGRAMS.LTV.ID}`}>
				<AccordionItemHeading>
						<AccordionItemButton>
							<span className="name">{PROGRAMS.LTV.NAME}</span>
							<span className="divider"></span>
							<span className="accordion-title">{PROGRAMS.LTV.TITLE}</span>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
					<span className="contentWrapper">
						<span className="vector"/>
						<span className="content">
							{PROGRAMS.LTV.CONTENT}
						</span>
						<span className="ctas">
							<span className="vector desktop"/>
							<IntroLendCTA
								text={'Get Pre-Approved'}
								classes='button--primary'
							/>
						</span>
					</span>
        </AccordionItemPanel>
			</AccordionItem>
         
      </Accordion>
  );
};

export default AccordionWrapper;
