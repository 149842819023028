import request from 'superagent';

const go = (url = '', options = {}, isJsonp = false) => {
  options = {
    ...options,
    headers: { ...options.headers },
  };
  return req(url, options, isJsonp);
};

const req = (url, options) => {
  return request(options.method, url)
    .set(options.headers)
    .send(options.body)
    .then(data => data);
};

const reqForm = (url, sendGridData) => {
  return request
    .post(url)
    .set('Content-Type', 'application/json')
    .set('Sec-Fetch-Mode', 'cors')
    .set('Sec-Fetch-Site', 'cross-site')
    .query(sendGridData);
};

export default {
  go,
  reqForm,
};
