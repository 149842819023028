import React from 'react';
import Dropdown from 'components/global/dropdown';
import { FUNNEL } from 'lib/constants/translations';

const BudgetMaster = ({ fields = {}, setField, nextStep }) => {

  const { priceRange = null, searchType, isInfluencerLead, isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const getHeadline = () => {
    if (searchType === 'BUYER') {
      return isInfluencerLead ? FUNNEL.budget.question.BUYER[language].influencer : FUNNEL.budget.question.BUYER[language].default;
    } else {
      return FUNNEL.budget.question.SELLER[language];
    }
  }

  const handleChange = (event) => {
    setField('priceRange', event.target.value);
    nextStep();
  }

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{getHeadline()}</h2>
      <div>
        <Dropdown
          value={priceRange || ''}
          options={FUNNEL.budget.dropdownOptions[language]}
          onChange={handleChange}
          placeholder={FUNNEL.budget.placeholder[language]}
          dataCY={'price_range_dropdown'}
        />
      </div>
    </div>
  );
};

export default BudgetMaster;
