import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import services from '../services';
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as queryString from 'query-string';
import { SLUG, SOURCE } from 'lib/constants/funnelMeta';

// Store Data
const base = {
  form: {
    main: {
      fields: {
        searchType: '',
        address: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        propertyType: null,
        priceRange: null,
        priceLow: null,
        priceHigh: null,
        priceAverage: null,
        status: '',
        timeframe: '',
        creditScore: '',
        buyingAndSelling: null,
        name: '',
        phone: '',
        email: '',
        leadsource: SOURCE.HAM_VERIFIED,
        leadId: '',
        experimentId: '',
        variantId: '',
        leadEntry: '',
        firstHome: null,
        householdIncome: '',
        militaryVeteran: null,
        otherAgent: '',
        affiliate: '',
        affiliateLeadContact: '',
        isInfluencerLead: null,
        coborrower: null,
        isSpanishFunnel: false,
        isHomeOwner: false,
        selectedAgent: '',
      },
      step: 0,
      complete: false,
      visitedSteps: [],
    },
    shortsale: {
      fields: {
        searchType: '',
        address: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        name: '',
        phone: '',
        email: '',
        leadsource: 'SSAF',
        leadId: '',
        experimentId: '',
        variantId: '',
      },
      complete: false,
    },
    secondary: {
      fields: {
        name: '',
        phone: '',
        creative: SOURCE.MAF_SHORT_ADDRESS,
        experimentId: '',
        variantId: '',
        leadsource: '',
        affiliate: '',
        affiliateLeadContact: '',
        isInfluencerLead: null,
      },
      complete: false,
    },
    contact: {
      fields: {
        name: '',
        email: '',
        phone: '',
        category: '',
        message: '',
      },
      complete: false,
    },
    homeVal: {
      fields: {
        reportFound: false,
        searchType: 'SELLER',
        address: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
        propertyPhoto: '',
        propertyType: null,
        priceRange: null,
        priceLow: null,
        priceHigh: null,
        priceAverage: null,
        valuationHistory: null,
        beds: null,
        baths: null,
        sqft: null,
        name: '',
        phone: '',
        email: '',
        intention: '',
        leadsource: SOURCE.HOMEVAL,
        leadId: '',
        experimentId: '',
      },
    },
  },
  optimize: {
    homevalCTA: false,
  },
  ui: {
    showModal: false,
  }
};

// Action Types
export const actionTypes = {
  FORM: {
    MAIN: {
      SET_FIELD: 'SET_FIELD',
      SET_STEP: 'SET_STEP',
      SET_COMPLETE: 'SET_COMPLETE',
    },
    SHORTSALE: {
      SET_FIELD: 'SET_SHORTSALE_FIELD',
      SET_COMPLETE: 'SET_SHORTSALE_COMPLETE',
    },
    SECONDARY: {
      SET_FIELD: 'SET_SECONDARY_FIELD',
      SET_COMPLETE: 'SET_SECONDARY_COMPLETE',
    },
    CONTACT: {
      SET_FIELD: 'SET_CONTACT_FIELD',
      SET_COMPLETE: 'SET_CONTACT_COMPLETE',
    },
    ALL: {
      UPDATE_PARAMS: 'UPDATE_PARAMS',
    },
    HOMEVAL: {
      SET_FIELD: 'SET_HOME_VAL_FIELD',
      SET_STEP: 'SET_HOME_VAL_STEP',
      SET_COMPLETE: 'SET_HOME_VAL_COMPLETE',
      SET_REFINANCE: 'SET_HOME_VAL_REFINANCE',
    },
  },
  OPTIMIZE: {
    BOOT: 'BOOT OPTIMIZE',
  },
  UI: {
    TOGGLE_MODAL: 'TOGGLE_MODAL',
  }
};

// Actions

export const setField = (field, value) => {
  return { type: actionTypes.FORM.MAIN.SET_FIELD, payload: {field, value} }
};

export const setStep = (step, path) => {
  return { type: actionTypes.FORM.MAIN.SET_STEP, payload: {step, path} }
};

export const setComplete = (value) => {
  return { type: actionTypes.FORM.MAIN.SET_COMPLETE, payload: {value} }
};

export const setShortsaleField = (field, value) => {
  return { type: actionTypes.FORM.SHORTSALE.SET_FIELD, payload: {field, value} }
};

export const setShortsaleComplete = (value) => {
  return { type: actionTypes.FORM.SHORTSALE.SET_COMPLETE, payload: {value} }
};

export const setSecondaryField = (field, value) => {
  return { type: actionTypes.FORM.SECONDARY.SET_FIELD, payload: {field, value} }
};

export const setSecondaryComplete = (value) => {
  return { type: actionTypes.FORM.SECONDARY.SET_COMPLETE, payload: {value} }
};

export const setContactField = (field, value) => {
  return { type: actionTypes.FORM.CONTACT.SET_FIELD, payload: {field, value} }
};

export const setContactComplete = (value) => {
  return { type: actionTypes.FORM.CONTACT.SET_COMPLETE, payload: {value} }
};

export const updateParams = (params) => {
  return { type: actionTypes.FORM.ALL.UPDATE_PARAMS, payload: { params } }
};

export const toggleModal = () => {
  return { type: actionTypes.UI.TOGGLE_MODAL };
};

export const submitForm = (type = 'main') => {
  return async (dispatch, getStore) => {
    try {
      const store = getStore();
      const {fields = {}} = store.form[type];
      const {body = {}} = await services.form(fields);
      const {response = {}} = body
      if (response.leadId){
        return response.leadId;
      }
    } catch (err) {

    }
  };
};

export const sendContactEmail = () => {
  return async (dispatch, getStore) => {
    try {
      const store = getStore();
      const {fields = {}} = store.form['contact'];
      const {body = {}} = await services.sendContactEmail(fields);
    } catch (err) {

    }
  };
};

export const sendUpdate = (opts = {}, id = '') => async () => {
  try {
    const {body = {}} = await services.update(opts, id); 
    return body;
  } catch (err){

  }
}

export const setHomeValField = (field, value) => {
  return { type: actionTypes.FORM.HOMEVAL.SET_FIELD, payload: {field, value} }
};

export const setHomeValStep = (step, path) => {
  return { type: actionTypes.FORM.HOMEVAL.SET_STEP, payload: {step, path} }
};

export const setHomeValComplete = (value) => {
  return { type: actionTypes.FORM.HOMEVAL.SET_COMPLETE, payload: {value} }
};

export const setHomeValRefinance = (value) => {
  return {type: actionTypes.FORM.HOMEVAL.SET_REFINANCE, payload: {value}}
};

export const bootOptimize = (variants) => {
  return { type: actionTypes.OPTIMIZE.BOOT, payload: {variants} }
};

// Reducers
export const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.FORM.MAIN.SET_FIELD: {
      const { field = null, value = '' } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          main: {
            ...state.form.main,
            fields: {
              ...state.form.main.fields,
              [field]: value,
            }
          },
        },
      };
    }
    case actionTypes.FORM.MAIN.SET_STEP: {
      const { step = 0, path } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          main: {
            ...state.form.main,
            step,
            visitedSteps: {
              ...state.form.main.visitedSteps,
              [step] : path
            }
          },
        },
      };
    }
    case actionTypes.FORM.MAIN.SET_COMPLETE: {
      const { value = 0 } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          main: {
            ...state.form.main,
            complete: value,
          },
        },
      };
    }
    case actionTypes.FORM.HOMEVAL.SET_FIELD: {
      const { field = null, value = '' } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          homeVal: {
            ...state.form.homeVal,
            fields: {
              ...state.form.homeVal.fields,
              [field]: value,
            }
          },
        },
      };
    }
    case actionTypes.FORM.HOMEVAL.SET_STEP: {
      const { step = 0, path } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          homeVal: {
            ...state.form.homeVal,
            step,
            visitedSteps: {
              ...state.form.homeVal.visitedSteps,
              [step] : path
            }
          },
        },
      };
    }
    case actionTypes.FORM.HOMEVAL.SET_COMPLETE: {
      const { value = 0 } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          homeVal: {
            ...state.form.homeVal,
            complete: value,
          },
        },
      };
    }
    case actionTypes.FORM.HOMEVAL.SET_REFINANCE: {
      const { value = false } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          homeVal: {
            ...state.form.homeVal,
            isRefinance: value
          },
        },
      };
    }
    case actionTypes.FORM.SHORTSALE.SET_FIELD: {
      const { field = null, value = ''} = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          shortsale: {
            ...state.form.shortsale,
            fields: {
              ...state.form.shortsale.fields,
              [field]: value,
            }
          },
        },
      };
    }
    case actionTypes.FORM.SHORTSALE.SET_COMPLETE: {
      const { value = 0 } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          shortsale: {
            ...state.form.shortsale,
            complete: value,
          },
        },
      };
    }
    case actionTypes.FORM.SECONDARY.SET_FIELD: {
      const { field = null, value = ''} = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          secondary: {
            ...state.form.secondary,
            fields: {
              ...state.form.secondary.fields,
              [field]: value,
            }
          },
        },
      };
    }
    case actionTypes.FORM.SECONDARY.SET_COMPLETE: {
      const { value = 0 } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          secondary: {
            ...state.form.secondary,
            complete: value,
          },
        },
      };
    }
    case actionTypes.FORM.CONTACT.SET_FIELD: {
      const { field = null, value = ''} = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          contact: {
            ...state.form.contact,
            fields: {
              ...state.form.contact.fields,
              [field]: value,
            }
          },
        },
      };
    }
    case actionTypes.FORM.CONTACT.SET_COMPLETE: {
      const { value = 0 } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          contact: {
            ...state.form.contact,
            complete: value,
          },
        },
      };
    }
    case actionTypes.FORM.ALL.UPDATE_PARAMS: {
      const { params = {} } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          main: {
            ...state.form.main,
            fields: {
              ...state.form.main.fields,
              ...params,
            }
          },
          shortsale: {
            ...state.form.shortsale,
            fields: {
              ...state.form.shortsale.fields,
              ...params,
            }
          },
          secondary: {
            ...state.form.secondary,
            fields: {
              ...state.form.secondary.fields,
              ...params
            }
          },
        },
      };
    }
    case actionTypes.OPTIMIZE.BOOT: {
      const { variants = {} } = action.payload;
      return {
        ...state,
        optimize: {
          ...state.optimize,
          ...variants,
        }
      }
    }
    case actionTypes.UI.TOGGLE_MODAL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showModal: !state.ui.showModal,
        },
      };
    }
    default:
      return state;
  }
};

// Selectors
export const getMainForm = (state = {}) => state.form.main;
export const getShortsaleForm = (state = {}) => state.form.shortsale;
export const getSecondaryForm = (state = {}) => state.form.secondary;
export const getContactForm = (state = {}) => state.form.contact;
export const getOptimize = (state = {}) => state.optimize;
export const getShowModal = (state = {}) => state.ui.showModal;
export const getHomeValForm = (state = {}) => state.form.homeVal;



const persistConfig = {
  key: 'root',
  storage,
}

// Setup
const initializeStore = (initialState = base) => {
  let store;

  const persistedReducer = persistReducer(persistConfig, reducer);


  const parsed = queryString.parse(location.search);
  //clear storage on the funnel entrance, TODO!!
  if (location.pathname.includes(SLUG.AGENTFINDER) && !parsed.step ||
      location.pathname.includes(SLUG.SELLER) && !parsed.step ||
      location.pathname.includes(SLUG.SHORTSALE) ||
      location.pathname.includes(SLUG.CONTACT) ||
      location.pathname.includes('buyer') && !parsed.step ||
      location.pathname === '/' && !parsed.step ||
      location.pathname.includes(SLUG.GRANTS) ||
      location.pathname.includes(SLUG.HOMELOANS) ||
      location.pathname.includes('/buying/') && !parsed.step ||
      location.pathname.includes('/selling/') && !parsed.step ||
      location.pathname === '/home-valuation/' && !parsed.step
      ) {
    purgeStoredState(persistConfig);
  }

  store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
      {
        serializableCheck: {
          // Ignore these action types
          ignoredActions: ['persist/PERSIST'],
          // Ignore these paths in the state
          ignoredPaths: ['some.path.to.ignore']
        }
      }
    ).concat(thunkMiddleware),
    preloadedState: initialState,
    devTools: true,
  });

  store.__PERSISTOR = persistStore(store);
  return store;
}

export const store = initializeStore();
