import React from 'react';
import Button from 'components/global/button';

const BUTTONS = [
  { text: 'I\'m Buying', value: 'Buying' },
  { text: 'I\'m Selling', value: 'Selling' },
  { text: 'Just Curious', value: 'Curious' },
];

const Intention = ({ fields = {}, setField, nextStep, previousStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  };

  const { intention = null } = fields;
  const setActiveButton = (value) => () => {
    setField('intention', value);

    if (value === 'Buying') {
      setField('searchType', 'BUYER');
    } else {
      setField('searchType', 'SELLER');
    }
    nextStep();
  };

  const onBack = () => { previousStep(); };

  return (
    <div className='report-form'>
      <h1 className='title'>What are you looking for?</h1>
      <div className='cta-full-width'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={intention === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'cta-white cta_' + item.value}
          />
        )}
      </div>
      <Button
        text={'Back'}
        onClick={onBack}
        classes={'back-button'}
      />
    </div>
  );
};

export default Intention;
