import Icon4 from 'images/how-it-works/1.svg';
import SearchDatabase from 'images/how-it-works/2.svg';
import SetupCall from 'images/how-it-works/3.svg';

export const WHATS_NEXT = [
  {
    image: SearchDatabase,
    copy: `Complete! We're searching our database of over 40,000 licensed real estate agents and brokers`,
  },
  {
    image: Icon4,
    copy: `A trained MyAgentFinder concierge will build your real estate profile`
  },
  {
    image: SetupCall,
    copy: `We'll match you with the perfect real estate agent and connect you with them`
  },
];

