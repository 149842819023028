import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import BuyerTab from './buyerTab';
import SellerTab from './sellerTab';
import Cookies from 'js-cookie';

const BuyerSellerTabs = ( props ) => {

  const { componentID } = props;

  const cookieName = 'activeTabIndex';
  const initialActiveTabIndex = parseInt(Cookies.get(cookieName) || '0');
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex);

  useEffect(() => {
    Cookies.set(cookieName, activeTabIndex, { path: '/' });
    updateTabs(activeTabIndex);
  }, [activeTabIndex]);


  const updateTabs = (index) => {
    setActiveTabIndex(index);
    const heroWrapper = document.querySelector('#ham-hero-wrapper');
    heroWrapper.classList.toggle('buyer-tab', index !== 1);
    heroWrapper.classList.toggle('seller-tab', index === 1);

    if (componentID !== 'CreatorHero') {
      //defined in the Elementor component
      const stepsWrapper = document.querySelector('#ham-steps-wrapper');
      stepsWrapper.classList.toggle('buyer-tab', index !== 1);
      stepsWrapper.classList.toggle('seller-tab', index === 1);

      //another Elementor component
      toggleActiveButtons(index);
    }
  }

  const toggleActiveButtons = (index) => {
    const buttons = document.querySelectorAll('#ham-steps-buttons-wrapper a');
    buttons.forEach((button, buttonIndex) => {
      if (buttonIndex === index) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    });
  };

  return [

    <div id='ham-hero-wrapper' className='buyer-tab'>
      <Tabs defaultIndex={activeTabIndex} onSelect={(index) => updateTabs(index)}>
        <TabList>
          <Tab key={0}>Buying a home</Tab>
          <Tab key={1}>Selling a home</Tab>
        </TabList>

        <TabPanel key={0}>
          <BuyerTab key={0} />
        </TabPanel>
        <TabPanel key={1}>
          <SellerTab key={1} />
        </TabPanel>
      </Tabs>
    </div>
  ]
};

export default BuyerSellerTabs;
