import React, { useState } from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import AutoComplete from 'components/global/googleAutocomplete';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import { FORM } from 'lib/constants/funnelMeta';


const AddressBuyer = (props = {}) => {
  const { fields = {}, setField, nextStep, isBuyerPrograms } = props;
  const { address = '' } = fields;

  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const onFieldStatus = (state) => {
    setEnabled(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  }

  const resetFields = () => {
    setField('address', ''); 
    setField('street', ''); 
    setField('city', '');
    setField('state', '');
    setField('postalCode', '');
    setField('county', '');
    setField('neighborhood', '');
  }

  const onSubmit = () => {
    if (address) {
      setField('searchType', 'BUYER');
      setField('leadEntry', FORM.MAFBUYER );
      
      onFieldStatus(true);
      if (!isAutocomplete) {
        searchManually();
      }
      nextStep();
    } else {
      onFieldStatus(false);
    }
  };

  const searchManually = () => {
    const service = new google.maps.places.AutocompleteService();

    const options = {
      types: ['postal_code', 'neighborhood', 'locality', 'administrative_area_level_2', 'administrative_area_level_3'],
      componentRestrictions: {country: 'us'}
    };

    service.getPlacePredictions({
      input: address,
      options
    }, displaySuggestions);

  };

  const displaySuggestions = (predictions, status) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
        onFieldStatus(false);
        return;
    }
    getDetailsManually(predictions[0].place_id);    
  };

  const getDetailsManually = async placeId => {
    new Promise((resolve, reject) => {
      if (!placeId) reject("placeId not provided")
      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          details => {
            const address = details.address_components.reduce((seed, { long_name, types }) => {
              types.forEach(t => {
                seed[t] = long_name;
              });
            
              return seed;
            }, {});

            const city = address.locality;
            const state = address.administrative_area_level_1;
            const county = address.administrative_area_level_2;
            const neighborhood = address.neighborhood;
            const postalCode = address.postal_code;
            const addressFull = `${(city ? (city+', ') : '')}${(state ? (state+', ') : '')}`;
    
            resetFields();

            setField('address', addressFull ? `${addressFull}USA` : '');
            setField('city', city);
            setField('state', state);
            setField('postalCode', postalCode);
            setField('county', county);
            setField('neighborhood', neighborhood);
            
            return resolve(details)
          }
        )
      } catch (e) {
        reject(e)
      }
    })
  };

  return (
    <div className='emailFirst_wrapper'>
      <div className={"main-form__header"}>
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            copy: isBuyerPrograms ? 'You may qualify for as little as $0 down payment on a new home!' : 'Buying your first home can be complicated. We make it easy.',
            size: 'h2'
          }}
          subHeading={isBuyerPrograms ? 'We\'ll match you with the perfect free programs to lower your down payment AND get cash back after you close.' : `Our step-by-step approach will help you find an agent, get funding, and become a homeowner (without the stress)`}
        />
      </div>
      
      <div className={`sellerLandingPage`}>
        <div className='rebranding-group-inputs email'>
          <div className={'rebranding-group-inputs-wrap rebranding-address ' + (address ? '' : 'required')}>
            <AutoComplete
              geoType={['postal_code', 'neighborhood', 'locality', 'administrative_area_level_2', 'administrative_area_level_3']}
              //address={address}
              placeholder={'Enter a Zip, Neighborhood or City'}
              setField={setField}
              enabledField={enabled}
              onFieldStatus={onFieldStatus}
              usedAutocomplete={usedAutocomplete}
            />
          </div>
          <Button
            text={'Next'}
            color={BUTTON_COLORS.CTA.DEFAULT}
            onClick={onSubmit}
            dataCY={'search_cta'}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressBuyer;
