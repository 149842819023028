export const FUNNEL = {
  firstHome: {
    question: {
      ENGLISH: 'Is this your first time buying a home?',
      SPANISH: '¿Es esta tu primera vez comprando una casa?'
    },
    button: {
      ENGLISH: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      SPANISH: [
        { text: 'Sí', value: true },
        { text: 'No', value: false },
      ]
    }
  },
  propertyType: {
    question: {
      ENGLISH: {
        BUYER: 'What kind of home are you looking to buy?',
        SELLER: 'What kind of home are you looking to sell?'
      },
      SPANISH: {
        BUYER: '¿Qué tipo de casa estás buscando comprar?',
        SELLER: '¿Qué tipo de casa buscas vender?',
      }
    },
    button: {
      ENGLISH: [
        { text: 'Single Family', value: 'SINGLE_FAMILY' },
        { text: 'Land or Lot', value: 'LAND_OR_LOT' },
        { text: 'Condo', value: 'CONDO' },
        { text: 'Mobile Home', value: 'MOBILE_HOME' },
        { text: 'Townhome', value: 'TOWNHOME' },
        { text: 'Commercial', value: 'COMMERCIAL' },
      ],
      SPANISH: [
        { text: 'Unifamiliar', value: 'SINGLE_FAMILY' },
        { text: 'Terreno o Lote', value: 'LAND_OR_LOT' },
        { text: 'Condominio', value: 'CONDO' },
        { text: 'Casa móvil', value: 'MOBILE_HOME' },
        { text: 'Casa adosada', value: 'TOWNHOME' },
        { text: 'Comercial', value: 'COMMERCIAL' },
      ]
    }
  },
  address: {
    question: {
      ENGLISH: {
        SELLER: 'What is the full address of the property you want to sell?',
        BUYER: 'Where are you looking to buy?',
      },
      SPANISH: {
        SELLER: '¿Cuál es la dirección completa de la propiedad que desea vender?',
        BUYER: '¿Qué tipo de casa estás buscando comprar?',
      }
    },
    button: {
      ENGLISH: [
        { type: 'SELLER', placeholder: 'Enter address' },
        { type: 'BUYER', placeholder: 'Zip, Neighborhood, or City' },
        { type: 'isBuyerPage', placeholder: 'Enter the location you prefer' }
      ],
      SPANISH: [
        { type: 'SELLER', placeholder: 'Ingrese dirección' },
        { type: 'BUYER', placeholder: 'Código postal, Barrio o Ciudad' },
        { type: 'isBuyerPage', placeholder: 'Ingrese la ubicación que prefiere' }
      ]
    },
    subtitle: {
      ENGLISH: 'Programs differ by city and even zip code.',
      SPANISH: 'Los programas varían según la ciudad e incluso el código postal.'
    },
    nextText: {
      ENGLISH: 'Next',
      SPANISH: 'Siguiente'
    }
  },
  budget: {
    question: {
      BUYER: {
        ENGLISH: {
          default: 'Great! What was the max budget you were pre-approved for?',
          influencer: 'Roughly, how much is your budget?'
        },
        SPANISH: {
          default: '¡Genial! ¿Cuál fue el presupuesto máximo para el que fue preaprobado?',
          influencer: 'Aproximadamente, ¿cuánto es su presupuesto?'
        }
      },
      SELLER: {
        ENGLISH: 'Roughly, how much is your home worth?',
        SPANISH: 'Aproximadamente, ¿cuánto vale su casa?'
      }
    },
    placeholder: {
      ENGLISH: 'Select Price Range',
      SPANISH: 'Selecciona un rango de precio',
    },
    dropdownOptions: {
      ENGLISH: [
        { text: 'Under $50K', value: 'Under $50,000' },
        { text: '$50k - $100k', value: '$50,000 - $100,000' },
        { text: '$100K - $150K', value: '$100,000 - $150,000' },
        { text: '$150K - $200K', value: '$150,000 - $200,000' },
        { text: '$200K - $250K', value: '$200,000 - $250,000' },
        { text: '$250K - $300K', value: '$250,000 - $300,000' },
        { text: '$300K - $400K', value: '$300,000 - $400,000' },
        { text: '$400K - $500K', value: '$400,000 - $500,000' },
        { text: '$500K - $600K', value: '$500,000 - $600,000' },
        { text: '$600K - $800K', value: '$600,000 - $800,000' },
        { text: '$800K - $1M', value: '$800,000 - $1,000,000' },
        { text: 'Over $1M', value: 'Over $1,000,000' }
      ],
      SPANISH: [
        { text: 'Menos de $50K', value: 'Under $50,000' },
        { text: '$50k - $100k', value: '$50,000 - $100,000' },
        { text: '$100K - $150K', value: '$100,000 - $150,000' },
        { text: '$150K - $200K', value: '$150,000 - $200,000' },
        { text: '$200K - $250K', value: '$200,000 - $250,000' },
        { text: '$250K - $300K', value: '$250,000 - $300,000' },
        { text: '$300K - $400K', value: '$300,000 - $400,000' },
        { text: '$400K - $500K', value: '$400,000 - $500,000' },
        { text: '$500K - $600K', value: '$500,000 - $600,000' },
        { text: '$600K - $800K', value: '$600,000 - $800,000' },
        { text: '$800K - $1M', value: '$800,000 - $1,000,000' },
        { text: 'Más de $1M', value: 'Over $1,000,000' }
      ]
    }
  },
  preApproved: {
    title: {
      ENGLISH: {
        influencerLead: 'Would you like our help getting pre-approved for a loan?',
        default: 'Have you been pre-approved for a mortgage?',
      },
      SPANISH: {
        influencerLead: '¿Le gustaría nuestra ayuda para obtener una pre-aprobación para un préstamo?',
        default: '¿Ha sido pre-aprobado para una hipoteca?',
      },
    },
    subtitle: {
      ENGLISH: 'First, let\'s determine your budget.',
      SPANISH: 'Primero, determinemos su presupuesto.',
    },
    buttons: {
      yes: {
        ENGLISH: 'Pre-Approved',
        SPANISH: 'Pre-aprobado',
      },
      no: {
        ENGLISH: 'Haven\'t Applied Yet',
        SPANISH: 'Aún no he solicitado',
      },
      inProcess: {
        ENGLISH: 'I am in the process of getting pre-approved',
        SPANISH: 'Estoy en el proceso de obtener una pre-aprobación',
      },
    },
  },
  creditScore: {
    question: {
      ENGLISH: 'How do you rate your credit score?',
      SPANISH: '¿Cómo califica su puntaje de crédito?'
    },
    button: {
      ENGLISH: [
        { text: 'Exceptional', value: '740-850' },
        { text: 'Very Good', value: '680-739' },
        { text: 'Good', value: '620-679' },
        { text: 'Fair', value: '580-619' },
        { text: 'Poor', value: '300-579' },
      ],
      SPANISH: [
        { text: 'Excepcional', value: '740-850' },
        { text: 'Muy Bueno', value: '680-739' },
        { text: 'Bueno', value: '620-679' },
        { text: 'Regular', value: '580-619' },
        { text: 'Pobre', value: '300-579' },
      ]
    },
  },
  timeFrame: {
    question: {
      BUYER: {
        ENGLISH: 'How soon are you looking to buy?',
        SPANISH: '¿Cuánto tiempo planeas comprar?',
      },
      SELLER: {
        ENGLISH: 'How soon are you looking to sell?',
        SPANISH: '¿Cuánto tiempo planeas vender?',
      }
    },
    button: {
      ENGLISH: [
        { text: '0 - 3 Months', value: '0 - 3 Months' },
        { text: '3 - 6 Months', value: '3 - 6 Months' },
        { text: '6 - 12 Months', value: '6 - 12 Months' },
        { text: 'Over 1 Year', value: 'Over 1 Year' },
      ],
      SPANISH: [
        { text: '0 - 3 Meses', value: '0 - 3 Months' },
        { text: '3 - 6 Meses', value: '3 - 6 Months' },
        { text: '6 - 12 Meses', value: '6 - 12 Months' },
        { text: 'Más de 1 año', value: 'Over 1 Year' },
      ],
    }
  },
  name: {
    question: {
      ENGLISH: 'What is your full name?',
      SPANISH: '¿Cuál es tu nombre completo?'
    },
    subTitle: {
      ENGLISH: "Let's connect you with the best agent in your area.",
      SPANISH: "Conectémoslo con el mejor agente en su área."
    },
    placeholder: {
      ENGLISH: 'First & Last Name',
      SPANISH: 'Nombre y Apellido'
    },
    nextButton: {
      ENGLISH: 'Next',
      SPANISH: 'Siguiente'
    }
  },
  email: {
    question: {
      ENGLISH: 'What is your email address?',
      SPANISH: '¿Cuál es tu dirección de correo electrónico?'
    },
    list: {
      ENGLISH: [
        "We won't sell your information",
        "No spam calls, texts, or emails",
        "100% free service, no hidden fees"
      ],
      SPANISH: [
        "No venderemos tu información",
        "Sin llamadas, textos o correos electrónicos no deseados",
        "Servicio 100% gratuito, sin tarifas ocultas"
      ]
    },
    placeholder: {
      ENGLISH: 'Email Address',
      SPANISH: 'Dirección de Correo Electrónico'
    },
    button: {
      ENGLISH: 'Agree and Continue',
      SPANISH: 'Aceptar y Continuar'
    },
    disclaimer: {
      ENGLISH: "I have read and agree to Home & Money's <a target='_blank' href='/terms'>Terms of Service</a>, <a target='_blank' href='/privacy'>Privacy Policy</a> and <a target='_blank' href='/electronic-communications-consent'>Consent to the Use of Electronic Communications</a>",
      SPANISH: "He leído y acepto los <a target='_blank' href='/terms'>Términos de Servicio</a>, la <a target='_blank' href='/privacy'>Política de Privacidad</a> y el <a target='_blank' href='/electronic-communications-consent'>Consentimiento para el Uso de Comunicaciones Electrónicas</a> de Home & Money"
    }
  },
  phone: {
    header: {
      ENGLISH: 'What phone number can we use to verify your account?',
      SPANISH: '¿Qué número de teléfono podemos usar para verificar su cuenta?'
    },
    placeholder: {
      ENGLISH: 'Phone Number',
      SPANISH: 'Número de Teléfono'
    },
    verify: {
      text: {
        ENGLISH: 'Text',
        SPANISH: 'Texto'
      },
      call: {
        ENGLISH: 'Call',
        SPANISH: 'Llamada'
      }
    },
    phoneVerification: {
      verifyVia: {
        ENGLISH: 'Verify via:',
        SPANISH: 'Verificar a través de:'
      }
    },
    nextButton: {
      ENGLISH: 'Next',
      SPANISH: 'Siguiente'
    }
  },
  phoneCodeVerification: {
    enterCode: {
      ENGLISH: 'Please enter the four digit code sent to',
      SPANISH: 'Por favor ingrese el código de cuatro dígitos enviado a'
    },
    didntReceive: {
      ENGLISH: "Didn't receive the code?",
      SPANISH: "¿No recibiste el código?"
    },
    resendNow: {
      ENGLISH: 'Resend Now',
      SPANISH: 'Reenviar Ahora'
    }
  },
  completed1Master: {
    stepDone: {
      ENGLISH: 'Step 1: Done 🎉',
      SPANISH: 'Paso 1: Finalizado 🎉'
    },
    searchingDatabase: {
      ENGLISH: {
        text1: "We're searching our database of 40,000+ agents and brokers",
        text2: "We'll match you with the perfect agent and <strong> connect you soon</strong>"
      },
      SPANISH: {
        text1: "Estamos buscando en nuestra base de datos de más de 40,000 agentes y corredores",
        text2: "Te emparejaremos con el agente perfecto y <strong> te conectaremos pronto</strong>"
      }
    },
    buildingPortfolio: {
      ENGLISH: {
        text1: "A trained concierge is building your real estate portfolio",
        text2: "We'll email you helpful resources"
      },
      SPANISH: {
        text1: "Un conserje capacitado está construyendo tu cartera inmobiliaria",
        text2: "Te enviaremos recursos útiles por correo electrónico"
      }
    },
    financeWhileWait: {
      ENGLISH: 'Finance Your Home Purchase While You Wait',
      SPANISH: 'Financia la Compra de Tu Casa Mientras Esperas'
    }
  },
  cta: {
    nextStep: {
      ENGLISH: 'Next Step',
      SPANISH: 'Próximo Paso'
    },
    getPreApproved: {
      ENGLISH: 'Get Pre-Approved',
      SPANISH: 'Obtener Preaprobación'
    },
    onlyTakesMinutes: {
      ENGLISH: 'It only takes a few minutes',
      SPANISH: 'Solo toma unos minutos'
    },
    financeWhileWait: {
      ENGLISH: 'Finance Your Home Purchase While You Wait',
      SPANISH: 'Financia la Compra de Tu Casa Mientras Esperas'
    }
  },
  buyMaster: {
    question: {
      ENGLISH: 'Are you also looking to buy a home?',
      SPANISH: '¿También estás buscando comprar una casa?'
    },
    buttons: [
      { 
        text: {
          ENGLISH: 'Yes',
          SPANISH: 'Sí'
        },
        value: true 
      },
      { 
        text: {
          ENGLISH: 'No',
          SPANISH: 'No'
        },
        value: false 
      }
    ]
  },
  nameAddressMaster: {
    question: {
      ENGLISH: "Let's connect you with the best agent in your area.",
      SPANISH: "Conectémoslo con el mejor agente en su área."
    },
    placeholders: {
      propertyAddress: {
        ENGLISH: 'Property Address',
        SPANISH: 'Dirección de la Propiedad'
      },
      name: {
        ENGLISH: 'First & Last Name',
        SPANISH: 'Nombre y Apellido'
      }
    },
    button: {
      ENGLISH: 'Next',
      SPANISH: 'Siguiente'
    }
  },
  completed1Seller: {
    stepDone: {
      ENGLISH: 'Step 1: Done 🎉',
      SPANISH: 'Paso 1: Finalizado 🎉'
    },
    searchingDatabase: {
      ENGLISH: "We're searching our database of 40,000+ agents and brokers",
      SPANISH: "Estamos buscando en nuestra base de datos de más de 40,000 agentes y corredores"
    },
    matchingAgent: {
      ENGLISH: "We'll match you with the perfect agent and <strong> connect you soon</strong>",
      SPANISH: "Te emparejaremos con el agente perfecto y <strong> te conectaremos pronto</strong>"
    },
    financeWhileWait: {
      ENGLISH: 'Finance Your Home Purchase While You Wait',
      SPANISH: 'Financia la Compra de Tu Casa Mientras Esperas'
    }
  },
  completed2Master: {
    title: {
      ENGLISH: 'Before connecting with an agent, we recommend building your credit.',
      SPANISH: 'Antes de conectarse con un agente de bienes raíces, le recomendamos desarrollar su crédito.'
    },
    supportTitle: {
      ENGLISH: 'With help from our credit-building partner',
      SPANISH: 'Con la ayuda de nuestro socio de creación de crédito'
    },
    startNowButton: {
      ENGLISH: 'Start Now',
      SPANISH: 'Comenzar Ahora'
    },
    buildCreditWith: {
      ENGLISH: 'Build Credit with',
      SPANISH: 'Mejorar el crédito con'
    },
    startBuildingCredit: {
      ENGLISH: 'Start Building Credit',
      SPANISH: 'Comenzar a desarrollar su crédito'
    },
    whyDoINeedToBuildCredit: {
      ENGLISH: 'Why do I need to build credit?',
      SPANISH: '¿Por qué necesito mejorar el crédito?'
    },
    inShort: {
      ENGLISH: 'In short, to get a loan, most agents require pre-approval to start, and pre-approval is unlikely with a credit score below 620. By increasing your score, we can improve your chances of mortgage pre-approval and match you with an agent successfully.',
      SPANISH: 'La mayoría de los agentes requieren una aprobación previa para comenzar, y la aprobación previa es poco probable con un puntaje de crédito inferior a 620. Al aumentar su puntaje, podemos mejorar sus posibilidades de aprobación previa de la hipoteca y conectarlo con un agente exitosamente.'
    },
    howDoesItWork: {
      ENGLISH: 'How does it work?',
      SPANISH: '¿Cómo funciona?'
    },
    SmartCreditCanHelp: {
      ENGLISH: 'SmartCredit can help you track, build and maximize your credit score fast. Their ScoreBoost feature will show you how much to pay on your credit cards and by when to get the most points the fastest. If you have late payments or negative accounts on your credit, they will mail or fax your creditors to help you address them. Also included in the membership are money management tools, identify theft and privacy alerts and $1M fraud insurance.',
      SPANISH: 'SmartCredit puede ayudarlo a rastrear, desarrollar y maximizar su puntaje crediticio rápidamente. Su función ScoreBoost le mostrará cuánto pagar con sus tarjetas de crédito y cuándo obtener la mayor cantidad de puntos lo más rápido posible. Si tiene pagos atrasados ​​o cuentas negativas en su crédito, ellos enviarán por correo o fax a sus acreedores para ayudarlo a abordarlos. También se incluyen en la membresía herramientas de administración de dinero, alertas de privacidad y robo de identidad y un seguro contra fraude de 1 millón.'
    },
    StellarFiWorksWith: {
      ENGLISH: 'StellarFi works with you on something you already do - pay your bills. But most of your bill payments go unreported because they are not made on loans or credit cards. StellaFi\'s unique solution reports payments like your phone bill, rent, yoga class, or favorite streaming service to the credit bureaus. Now, the bills you already pay can build your credit. <br/> <br/> Because they are on-time, successful payments, and recurring payments naturally build up positive payment history, they have the ability to increase your score.',
      SPANISH: 'StellarFi trabaja contigo en algo que ya haces: pagar tus facturas. Pero la mayoría de los pagos de sus facturas no se declaran porque no se realizan mediante préstamos o tarjetas de crédito. La solución única de StellarFi informa pagos como la factura del teléfono, el alquiler, la clase de yoga o el servicio de transmisión favorito a las agencias de crédito. <br/> <br/> Ahora, las facturas que ya paga pueden fortalecer su crédito. Debido a que son pagos puntuales y exitosos, y los pagos recurrentes naturalmente crean un historial de pagos positivo, tienen la capacidad de aumentar su puntaje.'
    },
    ToHelpOut: {
      ENGLISH: 'a credit-building service. Once your score improves, we\'ll gladly match you with an agent and help you find your dream home!',
      SPANISH: 'un servicio de creación de crédito. Una vez que mejore su puntaje, con gusto lo conectaremos con un agente y lo ayudaremos a encontrar la casa de sus sueños.'
    },
    ToHelpOutWe: {
      ENGLISH: 'To help you out, we\'ve partnered with',
      SPANISH: 'Para ayudarlo, contamos con socios de'
    }
  },
  

};
