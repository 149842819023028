import React from 'react';
//https://mui.com/x/react-charts/line-demo/
import { LineChart } from '@mui/x-charts/LineChart';
import { formatCurrency } from 'utils/utils.js';

const ValuationGraph = (props) => {

  const { fields = {} } = props;
  const { valuationHistory } = fields;

  const generateDateAndValueArrays = ({ BaseMonth, BaseYear, Values }) => {
    let currentMonth = BaseMonth - 1;
    let currentYear = BaseYear;

    const dateArray = [];
    const valueArray = [];

    for (const value of Values) {
        const date = new Date(currentYear, currentMonth);
        dateArray.push(date);
        valueArray.push(value);
        
        currentMonth -= 1;
        if (currentMonth < 0) {
          currentMonth = 11;
          currentYear -= 1;
        }
    }

    return [dateArray.reverse(), valueArray.reverse()];
  }

  const [dateArray, valueArray] = generateDateAndValueArrays(valuationHistory);

  const lineChartsParams = {
    series: [
      {
        data: valueArray,
        showMark: false,
        color: '#6471ED',
        label: 'Est. Value',
      },
    ],
    height: 400,
  };

  return (
    <div className="valuation-graph">
      <h2 className='title'>Historical Values</h2>
      <div className='vector-wrapper'>
        <LineChart
          tooltip={{ trigger: 'axis' }}
          margin={{ top: 60, bottom: 50, left: 80, right:40 }}
          {...lineChartsParams}
          series={lineChartsParams.series.map((s) => ({
            ...s,
            valueFormatter: formatCurrency,
          }))}
          xAxis={[{
            data: dateArray,
            scaleType: 'time',
            color: '#6471ED',
            hideTooltip: true,
            valueFormatter: (date) => {
              if (date.getDate() === 1) {
                const shortYear = `'${date.getFullYear().toString().slice(-2)}`;
                return `${date.toLocaleString('default', { month: 'short' })} ${shortYear}`;
              }
              return date.getFullYear().toString();
            }
          },]}
        />
      </div>
    </div>
  );
};

export default ValuationGraph;
