import React from 'react';
import HeroCTA from './heroCTA';

const BuyerTab = ( ) => {

  const bulletPoints = ['See what you can afford', 'Find grants and programs', 'Match with an agent and lender'];
  const buttonUrl = '/buying/';

  return [
    <React.Fragment>
      <div className='hero-content'>
        <HeroCTA
          title='Buy your home'
          bulletPoints={bulletPoints}
          buttonUrl={buttonUrl}
        />
      </div>
      <div className='hero-image'></div>
    </React.Fragment>
  ]
};

export default BuyerTab;
