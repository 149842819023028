import React from 'react';
import HeroCTA from './heroCTA';

const SellerTab = ( ) => {

  const bulletPoints = ['Get your home\'s value', 'Connect with a listing agent', 'Set your price and sell fast'];
  const buttonUrl = '/selling/';

  return [
    <React.Fragment>
      <div className='hero-content'>
        <HeroCTA
          title='Sell your home'
          bulletPoints={bulletPoints}
          buttonUrl={buttonUrl}
        />
      </div>
      <div className='hero-image'></div>
    </React.Fragment>
  ]
};

export default SellerTab;
