import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import Heading from 'components/global/heading';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import { PHONE_VERIFY } from 'lib/constants/phone_verify';
import { sendToDataLayer } from 'lib/logGTM';
import { getExperimentID } from 'lib/trackingParams';
import { maskValue } from 'utils/mask';

import Security from 'images/funnel-steps/pink_security.png';

import services from '../../../services';

const Phone = (props = {}) => {
  const {fields = {}, step, setField, previousStep, nextStep, updateParams, isBuyerPage, isBuyerPrograms} = props;
  const {
    phone = '',
    leadEntry,
    twilioMethod = 'sms'
  } = fields;

  let header = 'What phone number should we use to verify your account?';

  if (isBuyerPrograms) {
    header = 'What is your phone number?';
  }
  
  const updatePhone = (e) => setField('phone', maskValue(e));

  //twilio route
  const onTwilioSubmit = async () => {
    if (phone && (phone.toString()).length === 14) {
      onPhoneFieldStatus(true);

      // send Optimize cookie value to Salesforce
      updateParams(getExperimentID());

      sendToDataLayer({
        step: (step + 1),
        event: 'funnel_steps',
        formName: leadEntry,
        fields,
      });

      try {

        //adding US code manually
        const cleanNumber = `1${phone.replace(/[^\d]/g, '')}`;
        if(cleanNumber == PHONE_VERIFY.MOBILE_NUMBER){
          nextStep();
        }else{
          const {body = {}} = await services.sendTwilioCode(twilioMethod, cleanNumber).then(response => {
            if (response.body === 'pending') {
              nextStep();
            }
          })
          .catch(error => {
            onPhoneFieldStatus(false);
          });
        }
      } catch (err){
        onPhoneFieldStatus(false);
      }
    } else {
      onPhoneFieldStatus(false);
    }
  }

  const onBack = () => previousStep();
  const [phoneField, setPhoneField] = useState(true);


  const onPhoneFieldStatus = (state) => {
    setPhoneField(state);
  }

  const onRadioChange = (event) => {
    setField('twilioMethod', event.target.value);
  }

  
  return (
    <div className='container container--narrow ta-center rebranding'>
      <div className="main-form__header m-b-30">
        {!isBuyerPrograms &&
          <>
            <img
              src={Security}
              alt='avatar'
              className='rebranding-avatar'
            />
            <Heading
              color={COLORS.WHITE}
              subHeading={'We will never sell your information or send you spam messages.'}
              key={1}
            />
          </>
        }
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            copy: header,
            size: 'h2'
          }}
        />
      </div>
      <div className='rebranding-group container'>
        <div className='rebranding-group-inputs twilio'>
          <div className={'rebranding-group-inputs-wrap'}>
          <Input
              value={phone}
              onChange={updatePhone}
              placeholder={'Phone Number'}
              autoComplete="phone"
              type="tel"
              dataCY={'phone'}
              className= { phoneField ? null : 'input--invalid'}
            />
            <div className="radiogroup" onChange={event => onRadioChange(event)}>
              <label>
                <input type="radio" name="radio-button" value="sms" defaultChecked />
                <span>Verify via text message</span>
              </label>
              <label>
                <input type="radio" name="radio-button" value="call" />
                <span>Verify via phone call</span>
              </label>
            </div>
          </div>
          
          <Button
            text={isBuyerPage ? 'Submit' : 'Find an Agent'}
            color={BUTTON_COLORS.CTA.DEFAULT}
            onClick={onTwilioSubmit}
            classes={'find-agent-button'}
            dataCY={'find_agent'}
          />
        </div>
      </div>
  
      <div className='find-agent-button-container'>
        <Button
          text={'Back'}
          color={BUTTON_COLORS.LINK.WHITE}
          onClick={onBack}
          classes={'back-button'}
          dataCY={'back_button'}
        />
      </div>
    </div>
  );
};

export default Phone;
