import React, { useState } from 'react';
 
const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const PlusIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 5V19M5 12H19" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
  const MinusIcon = () => <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.5 12H19.5" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  return (
    <div className="accordion-item">
      <button className="accordion-button" onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        {isOpen ? <MinusIcon /> : <PlusIcon />}
      </button>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

const Accordion = ({ items }) => {
  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title}>
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
        </AccordionItem>
      ))}
    </div>
  );
};

export default Accordion;