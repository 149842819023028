import React, { useState } from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import AutoComplete from 'components/global/googleAutocomplete';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import classNames from 'classnames';

const BUY_OR_SELL_BUTTONS = [
  {
    type: 'SELLER',
    placeholder: 'Enter address',
    headline: 'What is the full address of the property you want to sell?',
  },
  {
    type: 'BUYER',
    placeholder: 'Enter a Zip, Neighborhood or City',
    headline: 'Where are you looking to buy?',
  }
];

const Address = (props = {}) => {
  const { fields = {}, setField, nextStep, previousStep, isBuyerPage } = props;
  const { address = '', city, state, postalCode, searchType } = fields;
  const { placeholder = isBuyerPage ? 'Where are you looking to buy?' : 'Enter your address' } = (searchType ? BUY_OR_SELL_BUTTONS.find(e => e.type === searchType) : '');
  const { headline = 'What is the full address of the property you want to sell?' } = (searchType ? BUY_OR_SELL_BUTTONS.find(e => e.type === searchType) : '');
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const onBack = () => {
    setField('address', '');
    previousStep(); 
  };

  const onFieldStatus = (state) => {
    setEnabled(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  } 

  const resetFields = () => {
    setField('address', ''); 
    setField('street', ''); 
    setField('city', '');
    setField('state', '');
    setField('postalCode', '');
    setField('county', '');
    setField('neighborhood', '');
  }

  const checkFields = () => {
    if (address && !city && !state && !postalCode) {
      setField('street', address); 
      setField('city', '');
      setField('state', '');
      setField('postalCode', '');
    }
  }

  const geoType = () => {
    let geo= ['geocode'];
    if (isBuyerPage || searchType === 'BUYER') {
      geo = ['postal_code', 'neighborhood', 'locality', 'administrative_area_level_2', 'administrative_area_level_3'];
    }
    return geo;
  }

  const onSubmit = () => {
    if (address) {

      if (isBuyerPage) {
        setField('searchType', 'BUYER');
      }

      if (!isAutocomplete) {
        searchManually();
      }
    
    
      onFieldStatus(true);
      nextStep();
    } else {
      onFieldStatus(false);
    }
  };

  const searchManually = () => {
    const service = new google.maps.places.AutocompleteService();

    const options = {
      types: geoType(),
      componentRestrictions: {country: 'us'}
    };

    service.getPlacePredictions({
      input: address,
      options
    }, displaySuggestions);
  };

  const displaySuggestions = (predictions, status) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
        onFieldStatus(false);
        return;
    }
    getDetailsManually(predictions[0].place_id);    
  };

  const getDetailsManually = async placeId => {
    new Promise((resolve, reject) => {
      if (!placeId) reject("placeId not provided")
      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          details => {
            const address = details.address_components.reduce((seed, { long_name, types }) => {
              types.forEach(t => {
                seed[t] = long_name;
              });
            
              return seed;
            }, {});

            const city = address.locality;
            const state = address.administrative_area_level_1;
            const county = address.administrative_area_level_2;
            const neighborhood = address.neighborhood;
            const postalCode = address.postal_code;
            const addressFull = `${(city ? (city+', ') : '')}${(state ? (state+', ') : '')}`;
    
            resetFields();

            setField('address', addressFull ? `${addressFull}USA` : '');
            setField('city', city);
            setField('state', state);
            setField('postalCode', postalCode);
            setField('county', county);
            setField('neighborhood', neighborhood);

            return resolve(details)
          }
        )
      } catch (e) {
        reject(e)
      }
    })
  };

  const classList = {
    wrapper: classNames({
      'container': !isBuyerPage,
      'sellerLandingPage': isBuyerPage,
    }),
    header: classNames({
      'container--wide ta-center rebranding': !isBuyerPage,
    })
  };

  return (
    <div className={classList.header}>
      {!isBuyerPage &&
        <div className="main-form__header m-b-30">
          <Heading
            tight
            color={COLORS.WHITE}
            headline={{
              size: 'h2',
              copy: headline,
            }}
          />
        </div>
      }
      <div className={`rebranding-group ${classList.wrapper}`}>
        <div className='rebranding-group-inputs address'>
          <div className={'rebranding-group-inputs-wrap rebranding-address ' + (address ? '' : 'required')}>
            <AutoComplete
              geoType={ geoType() }
              placeholder={placeholder}
              setField={setField}
              enabledField={enabled}
              onFieldStatus={onFieldStatus}
              checkFields={checkFields}
              usedAutocomplete={usedAutocomplete}
            />
          </div>
          <Button
            text={'Next'}
            color={BUTTON_COLORS.CTA.DEFAULT}
            onClick={onSubmit}
            dataCY={'search_cta'}
          />
        </div>
        {!isBuyerPage &&
          <div className="p-t-20">
            <Button
              text={'Back'}
              color={BUTTON_COLORS.LINK.WHITE}
              onClick={onBack}
              classes={'back-button'}
              dataCY={'back_button'}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default Address;
