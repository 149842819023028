import fetch from './superagent';

const defaultGetOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

const defaultPutOptions = {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
};

const defaultPatchOptions = {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
  },
};

const defaultPostOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

const defaultPostFormOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'x-www-form-urlencoded',
  },
};

const defaultDeleteOptions = {
  method: 'DELETE',
  headers: {
    'Content-Type': 'text/plain',
  },
};

export default {
  get: (url = '', options = {}, jsonp = false) => {
    const getOptions = {
      ...defaultGetOptions,
      ...options,
    };
    return fetch.go(url, getOptions, jsonp);
  },
  simpleGet: (url = '', options) => {
    const getOptions = {
      ...defaultGetOptions,
      ...options,
      mode: 'no-cors',
    };
    return fetch.go(url, getOptions);
  },
  put: (url = '', body = {}) => {
    const putOptions = {
      ...defaultPutOptions,
      body,
    };
    return fetch.go(url, putOptions);
  },
  patch: (url = '', body = {}) => {
    const patchOptions = {
      ...defaultPatchOptions,
      body,
    };
    return fetch.go(url, patchOptions);
  },
  post: (url = '', body = {}) => {
    const postOptions = {
      ...defaultPostOptions,
      body,
    };

    return fetch.go(url, postOptions);
  },
  postWithOptions: (url = '', body = {}, options = {}) => {
    const postOptions = {
      ...defaultPostOptions,
      ...options,
      body,
    };
    return fetch.go(url, postOptions);
  },
  postAsForm: (url = '', body = {}, options = {}) => {
    return fetch.reqForm(url, body);
  },
  delete: (url = '') => {
    const deleteOptions = {
      ...defaultDeleteOptions,
    };
    return fetch.go(url, deleteOptions);
  },
};
