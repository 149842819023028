import React from 'react';

const Connect = ({ socialLinks }) => {

  return (
    <div className='AreasServed self-stretch pt-6 pb-4 border-t border-[#d0d5dd] flex-col justify-start items-start gap-4 flex'>
      <div className='Text self-stretch text-[#2a2f64] text-[19px] sm:text-[25px] font-medium leading-normal'>
        Connect
      </div>
      <div className='inline-flex items-start self-stretch justify-center ColContentSection'>
        {Object.entries(socialLinks).map(([platform, url], index) => (
          url && (
            <div
              key={platform}
              className={`flex h-[80px] justify-center items-center gap-[4px] flex-[1_0_0] ${
                index < Object.keys(socialLinks).filter(key => socialLinks[key]).length - 1 ? 'border-r border-[#d0d5dd]' : ''
              }`}
            >
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className='w-[24px] h-[24px]'
                  src={`/wp-content/themes/ham/assets/images/agent-profile/social/${platform}.png`}
                  alt={platform}
                />
              </a>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Connect;
