import React from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import Heading from 'components/global/heading';
import { maskValue } from 'utils/mask';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import validator from 'validator';
import { getExperimentID } from 'lib/trackingParams';

const Details = (props = {}) => {
  const {fields = {}, setField, previousStep, setComplete, submitForm, updateParams } = props;
  const {name = '', phone = '', email = ''} = fields;
  const updateField = (field) => (e) => setField(field, e.target.value);
  const updatePhone = (e) => setField('phone', maskValue(e));
  const onSubmit = async () => {

    // send Optimize cookie value to Salesforce
    updateParams(getExperimentID());

    setComplete(true);
    await submitForm('main');
  };
  const onBack = () => previousStep();
  const isPhoneValid = phone && (phone.toString()).length === 14; // (555) 555-5555
  const isEmailValid = email && validator.isEmail(email);
  const canSubmitForm = name && isPhoneValid && isEmailValid;
  
  return (
    <div className='container container--normal ta-center'>
      <div className={"main-form__header 'm-b-30"}>
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            copy: `Let's connect you with the best agent in your area`,
            size: 'h2'
          }}
        />
      </div>
      <div className='leadform-wrapper'>
        <div className='input-container'>
          <Input
            value={name}
            onChange={updateField('name')}
            placeholder={'*Full Name'}
            dataCY={'full_name'}
          />
        </div>
        <div className='input-container'>
          <Input
            value={phone}
            onChange={updatePhone}
            placeholder={'*Phone'}
            type="tel"
            dataCY={'phone'}
          />
        </div>
        <div className='input-container leadform-wrapper-email'>
          <Input
            value={email}
            onChange={updateField('email')}
            placeholder={'*Email'}
            type={'email'}
            dataCY={'email'}
          />
        </div>
        
        <div className='find-agent-button-container'>
          { <Button
            text={'Find an Agent'}
            color={BUTTON_COLORS.CTA.DEFAULT}
            onClick={onSubmit}
            classes={'find-agent-button'}
            disabled={!canSubmitForm}
            dataCY={'find_agent'}
          />
          }
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
