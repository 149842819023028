import React from 'react';
import Dropdown from 'components/global/dropdown';

const OPTIONS = [
  { text: '$0 - $25,000', value: '$0 - $25,000' },
  { text: '$25,001 - $50,000', value: '$25,001 - $50,000' },
  { text: '$50,001 - $75,000', value: '$50,001 - $75,000' },
  { text: '$75,001 - $100,000', value: '$75,001 - $100,000' },
  { text: '$100,001 - $125,000', value: '$100,001 - $125,000' },
  { text: '$125,001 - $150,000', value: '$125,001 - $150,000' },
  { text: '$150,001 - $175,000', value: '$150,001 - $175,000' },
  { text: '$175,001 - $200,000', value: '$175,001 - $200,000' },
  { text: '$200,001 - $225,000', value: '$200,001 - $225,000' },
  { text: '$225,001 - $250,000', value: '$225,001 - $250,000' },
  { text: 'More Than $250,000', value: 'More Than $250,000' },
];


const IncomeMaster = ({ fields = {}, setField, nextStep }) => {

  const { householdIncome = null } = fields;

  const handleChange = (event) => {
    setField('householdIncome', event.target.value);
    nextStep();
  }

  return (
    <div className='form-wrapper'>
      <span className='form-subtitle'>No problem. We'll calculate how much house you might be able to afford.</span>
      <h2 className='form-title'>What is your annual household income?</h2>
      <div>
        <Dropdown
          value={householdIncome || ''}
          options={OPTIONS}
          onChange={handleChange}
          placeholder='Yearly income (before taxes)'
          dataCY={'price_range_dropdown'}
        />
      </div>
    </div>
  );
};

export default IncomeMaster;
