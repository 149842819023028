import React from 'react'
import classNames from 'classnames'

const Input = (props) => {
  const { className } = props;

  const classList = {
    input: classNames('input', { [className]: !!className })
  };
  return (
    <input
      value={props.value}
      type={props.type || 'text'}
      className={classList.input}
      placeholder={props.placeholder}
      onChange={props.onChange}
      onKeyPress={props.onKeyPress}
      autoComplete={props.autoComplete}
      ref={props.returnRef}
      data-cy={props.dataCY}
    />
  );
};

export default Input
