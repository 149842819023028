import React from 'react';

const styles = {
  spinner: {

    transformOrigin: 'center',
    animation: 'spinnerAnimation .75s infinite linear',
    strokeWidth: '4px',
    stroke: '#131313',
  },
  '@keyframes spinnerAnimation': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};

const SpinnerIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <style>
      {`
        @keyframes spinnerAnimation {
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
    <path
      d="M46 23C46 26.9658 44.928 30.8578 42.8975 34.2643C40.867 37.6708 37.9534 40.4651 34.4651 42.3515C30.9767 44.238 27.0433 45.1465 23.081 44.9808C19.1187 44.8151 15.2749 43.5815 11.9562 41.4105C8.63746 39.2394 5.9673 36.2117 4.22821 32.6476C2.48911 29.0835 1.74575 25.1156 2.07677 21.1637C2.4078 17.2117 3.80089 13.4228 6.10867 10.1976C8.41645 6.97252 11.5531 4.43117 15.1867 2.84247"
      style={styles.spinner}
    />
  </svg>
);

export default SpinnerIcon;