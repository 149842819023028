const initFloatingCTAs = () => {
  var hero = document.querySelector('#floating-bar');
  var floating = document.querySelector('.floating-cta');

  if (hero && floating) {
    var bounding = hero.getBoundingClientRect();
    window.addEventListener('scroll', () => {
      bounding = hero.getBoundingClientRect();

      if (
        bounding.top >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      ) {
        floating.classList.remove('visible');
      } else {
        floating.classList.add('visible');
      }
    });
  }
}

export {
  initFloatingCTAs,
}
