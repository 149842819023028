import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import * as queryString from 'query-string';
import * as nprogress from 'nprogress';
import { FORM, SLUG, CLASS, ANSWERS, SOURCE } from 'lib/constants/funnelMeta';
import { sendToDataLayer } from 'lib/logGTM';
import { addBodyClass, removeBodyClass } from "utils/utils";
import { isPoorCredit } from "utils/mortgageRedirect";

import RedirectModal from 'components/mrc/modalWrapper';

//legacy funnel
import Landing from './landing';
import Address from './address';
import AddressBuyer from './addressBuyer';
import PropertyType from './propertyType';
import Budget from './budget';
import Income from './income';
import Military from './military';
import Connect from './connect';
import PreApproved from './preApproved';
import TimeFrame from './timeFrame';
import CreditScore from './creditScore';
import FirstHome from './firstHome';
import Buy from './buy';
import NameAddress from './nameAddress';
import Email from './email';
import Phone from './phone';
import Verify from './verify';
import Details from './details';
import WhatsNext from 'components/maf/whatsnext/';
import Complete from 'components/maf/complete/';

//Master Funnel
import FirstHomeMaster from './masterFunnel/firstHomeMaster';
import PropertyTypeMaster from './masterFunnel/propertyTypeMaster';
import TimeFrameMaster from './masterFunnel/timeframeMaster';
import PreApprovedMaster from './masterFunnel/preApprovedMaster';
import BudgetMaster from './masterFunnel/budgetMaster';
import IncomeMaster from './masterFunnel/incomeMaster';
import CreditMaster from './masterFunnel/creditMaster';
import MilitaryMaster from './masterFunnel/militaryMaster';
import AddressMaster from './masterFunnel/addressMaster';
import ConnectMaster from './masterFunnel/connectMaster';
import NameMaster from './masterFunnel/nameMaster';
import EmailMaster from './masterFunnel/emailMaster';
import PhoneMaster from './masterFunnel/phoneMaster';
import VerifyMaster from './masterFunnel/verifyMaster';
import CoSignMaster from './masterFunnel/coSignMaster';
import BuyMaster from './masterFunnel/buyMaster';
import NameAddressMaster from './masterFunnel/nameAddressMaster';
import Completed1Master from './masterFunnel/complete/completed1Master';
import Completed2Master from './masterFunnel/complete/completed2Master';
import Completed1Seller from './masterFunnel/complete/completed1Seller';

//HomeVal
import OwnerHomeVal from 'components/maf/form-wizard/homeval/funnel/owner';
import IntentionHomeVal from 'components/maf/form-wizard/homeval/funnel/intention';
import LeadFormHomeVal from 'components/maf/form-wizard/homeval/funnel/leadform';
import PhoneHomeVal from 'components/maf/form-wizard/homeval/funnel/phone';
import VerifyHomeVal from 'components/maf/form-wizard/homeval/funnel/verify';
import CompleteHomeVal from 'components/maf/form-wizard/homeval/funnel/complete';


import { setField, setStep, setComplete, getMainForm, submitForm, updateParams, toggleModal, getShowModal } from 'store';


let initialFunnel = [Landing, PropertyType, Address, Budget, TimeFrame, Buy, Details, WhatsNext];
let initialUrlsList = ['user', 'type', 'address', 'budget', 'timeframe', 'buy', 'info', 'complete'];

let sellerList = [Landing, PropertyType, Budget, TimeFrame, Buy, NameAddress, Email, Phone, Verify, WhatsNext];
let sellerUrlList = ['user', 'type', 'budget', 'timeframe', 'buy', 'name', 'email', 'phone', 'verify', 'complete'];

let buyerList = [Landing, PropertyType, Address, Budget, PreApproved, CreditScore, TimeFrame, FirstHome, NameAddress, Email, Phone, Verify, WhatsNext];
let buyerUrlList = ['user', 'type', 'address', 'budget', 'preapproved', 'creditscore', 'timeframe', 'firstHome', 'name', 'email', 'phone', 'verify', 'complete'];

const buyerNoCreditList = [Landing, PropertyType, Address, Budget, PreApproved, TimeFrame, FirstHome, NameAddress, Email, Phone, Verify, WhatsNext];
const buyerNoCreditUrlList = ['user', 'type', 'address', 'budget', 'preapproved', 'timeframe', 'firstHome', 'name', 'email', 'phone', 'verify', 'complete'];

//For Buyer Page Only
const buyerPageList = [AddressBuyer, PropertyType, Budget, PreApproved, CreditScore, TimeFrame, NameAddress, Email, Phone, Verify, WhatsNext];
const buyerPageUrlList = ['address', 'type', 'budget', 'preapproved', 'creditscore', 'timeframe', 'name', 'email', 'phone', 'verify', 'complete'];

const buyerPageNoCreditList = [AddressBuyer, PropertyType, Budget, PreApproved, TimeFrame, NameAddress, Email, Phone, Verify, WhatsNext];
const buyerPageNoCreditUrlList = ['address', 'type', 'budget', 'preapproved', 'timeframe', 'name', 'email', 'phone', 'verify', 'complete'];

//Grants And Programs Page Only
const buyerProgramsList = [AddressBuyer, TimeFrame, Income, CreditScore, Military, FirstHome, Connect,  NameAddress, Email, Phone, Verify, Complete];
const buyerProgramsUrlList = ['address', 'timeframe', 'income', 'creditscore', 'military', 'firstHome', 'connect', 'name', 'email', 'phone', 'verify', 'complete'];

//Grants And Programs Page Only
const buyerProgramsNoPhoneList = [AddressBuyer, TimeFrame, Income, CreditScore, Military, FirstHome, Connect, NameAddress, Email, Complete];
const buyerProgramsNoPhoneUrlList = ['address', 'timeframe', 'income', 'creditscore', 'military', 'firstHome', 'connect', 'name', 'email', 'complete'];

let isCreatorPage = false;

const MainForm = ( props ) => {

  const {isBuyerPage = false, isBuyerPrograms = false, isMasterFunnel = false, isHomeVal = false} = props;
  const { fields, step = 0, visitedSteps } = useSelector(getMainForm);
  const { status, otherAgent, creditScore, timeframe, coborrower, searchType, leadEntry, isSpanishFunnel } = fields;

  let navigate = useNavigate();
  let location = useLocation();

  const dispatch = useDispatch();
  const dispatchSetField = (field, value) => {
    dispatch(setField(field, value));
  }

  const showMRCModal = useSelector(getShowModal);

  useEffect(() => {
    // Hide modal when browser's back button is clicked
    const handleBackButton = () => {
      if (showMRCModal) {
        dispatch(toggleModal());
      }
    };

    // Hide modal when the page is reloaded
    const handleReload = () => {
      if (showMRCModal) {
        dispatch(toggleModal());
      }
    };

    window.addEventListener('popstate', handleBackButton);
    window.addEventListener('beforeunload', handleReload);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
      window.removeEventListener('beforeunload', handleReload);
    };
  }, [showMRCModal]);


  useEffect(() => {

    if (showMRCModal) {
      // Disable scrolling on the main page
      document.body.style.overflow = 'hidden';

      setTimeout(() => {
        // Re-enable scrolling on the main page
        document.body.style.overflow = 'auto';

        dispatch(toggleModal()); // Hide the modal
      }, 5000);
    }
  }, [showMRCModal]);


  //set leadsources
  useEffect(() => {

    //default leadsource
    dispatchSetField('leadsource', SOURCE.HAM_VERIFIED);

    if (isBuyerPrograms) {
      dispatchSetField('leadsource', SOURCE.PROGRAMS);
    }

    if (isHomeVal) {
      dispatchSetField('leadsource', SOURCE.HOMEVAL);
    }

    // creator pages
    const parsed = queryString.parse(window.location.search);
    if (parsed['affiliateAgentID'] || parsed['affiliateLeadContactID']) {
      isCreatorPage = true;
      dispatchSetField('affiliate', parsed['affiliateAgentID']);
      dispatchSetField('affiliateLeadContact', parsed['affiliateLeadContactID']);
      dispatchSetField('isInfluencerLead', true);
      dispatchSetField('leadsource', SOURCE.HAM_INFLUENCERS);
    }

    //Daniel spanish funnel
    if (parsed['affiliateAgentID'] && parsed['affiliateAgentID'] === '003Rl000001j8lhIAA') {
      dispatchSetField('isSpanishFunnel', true);
    }

    //from agent profiles only
    if (parsed['agentID']) {
      dispatchSetField('selectedAgent', parsed['agentID']);
      dispatchSetField('leadsource', SOURCE.HAM_AGENT_PROFILE);
    }

  },[]);


  //set leadEntries
  useEffect(() => {

    //default leadEntry
    dispatchSetField('leadEntry', FORM.AGENTFINDER);

    if (isBuyerPage) {
      dispatchSetField('leadEntry', FORM.MAFBUYER);
    }

    if (isBuyerPrograms) {
      dispatchSetField('leadEntry', FORM.GRANTSANDPROGRAMS);
    }

  },[]);

  useEffect(() => {
    if (!isMasterFunnel) {
      nprogress.configure({
        minimum: 0.0,
        trickle: false,
        showSpinner: false,
        parent: 'body'
      });
    }
  }, []);

  const isCompletedStep = (step) => {
    const totalSteps = initialFunnel.length - 1;

    // Remove previous step class
    for (let i = 0; i <= totalSteps; i++) {
      removeBodyClass(`step_${i}`);
    }

    //mainly for hiding the footer if you're on the active funnel
    if (!isHomeVal) {
      step > 0
        ? addBodyClass(CLASS.AGENTFINDER.ACTIVE)
        : removeBodyClass(CLASS.AGENTFINDER.ACTIVE);
    }

    //completion page
    //Buyer Widget popup doesn't have a completion page
    if (step === totalSteps){
      addBodyClass(CLASS.AGENTFINDER.COMPLETED);
      removeBodyClass(CLASS.AGENTFINDER.ACTIVE);
    } else {
      removeBodyClass(CLASS.AGENTFINDER.COMPLETED);
    }

    // Add current step class
    addBodyClass('step_' + step);
  }
  
  const displayProgressBar = (step) => {
    isCompletedStep(step);
    const totalSteps = initialFunnel.length - 1;
    const bar = document.getElementById("nprogress");
    if (bar && step !== totalSteps ){
      bar.style.display = "block";
    }
    nprogress.set(step/totalSteps);
    
  }


  //ToDo: LEGACY
  if (!isMasterFunnel && searchType === "BUYER" && !isBuyerPage) {
    initialFunnel = buyerList;
    initialUrlsList = buyerUrlList;
  }

  if (isBuyerPage) {
    initialFunnel = buyerPageList;
    initialUrlsList = buyerPageUrlList;

    if (isBuyerPrograms) {

      initialFunnel = buyerProgramsList;
      initialUrlsList = buyerProgramsUrlList;

      if (otherAgent === ANSWERS.OTHERAGENT.NO || otherAgent === ANSWERS.OTHERAGENT.HASONE ) {
        initialFunnel = buyerProgramsNoPhoneList;
        initialUrlsList = buyerProgramsNoPhoneUrlList;
      }
    }
  }

  if (!isMasterFunnel && searchType === "SELLER") {
    initialFunnel = sellerList;
    initialUrlsList = sellerUrlList;
  }

  if (!isMasterFunnel && status === ANSWERS.STATUS.YES) {
    initialFunnel = isBuyerPage ? buyerPageNoCreditList : buyerNoCreditList;
    initialUrlsList = isBuyerPage ? buyerPageNoCreditUrlList : buyerNoCreditUrlList;
  }
  
  //used with Elementor CTAs
  const path = window.location.pathname;

  const isBuyingFunnel = () => {
    return path.includes('buying');
  }

  const isSellingFunnel = () => {
    return path.includes('selling');
  }
  
  const dontConnectWithAgent = () => {
    return otherAgent === ANSWERS.OTHERAGENT.NO || otherAgent === ANSWERS.OTHERAGENT.HASONE;
  }

  //https://docs.google.com/spreadsheets/d/111kMW--OXsztLbwP7w_JLDDkPh7Gu6EhSVXUvidvqdA/edit#gid=0
  const funnelConditions = {
 
    masterBuyer: !!isMasterFunnel && isBuyingFunnel(),

    masterBuyer1: !!isMasterFunnel && isBuyingFunnel() &&
                timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
                status === ANSWERS.STATUS.YES &&
                (otherAgent === ANSWERS.OTHERAGENT.YES || !otherAgent),

    masterBuyer2: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.YES &&
              dontConnectWithAgent(),

    masterBuyer3: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              !isPoorCredit(creditScore) &&
              (otherAgent === ANSWERS.OTHERAGENT.YES || !otherAgent),

    masterBuyer4: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              !isPoorCredit(creditScore) &&
              (dontConnectWithAgent() || !otherAgent),

    masterBuyer5: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO && 
              isPoorCredit(creditScore) &&
              coborrower &&
              (otherAgent === ANSWERS.OTHERAGENT.YES || !otherAgent),

    masterBuyer6: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              isPoorCredit(creditScore) &&
              coborrower &&
              (otherAgent === ANSWERS.OTHERAGENT.NO || !otherAgent),

    masterBuyer7: !!isMasterFunnel&& isBuyingFunnel() &&
              timeframe !== ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              isPoorCredit(creditScore) &&
              !coborrower,

    masterBuyer8: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe === ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.YES,

    masterBuyer9: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe === ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              !isPoorCredit(creditScore),

    masterBuyer10: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe === ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              isPoorCredit(creditScore) &&
              coborrower,
    
    masterBuyer11: !!isMasterFunnel && isBuyingFunnel() &&
              timeframe === ANSWERS.TIMEFRAME.Months_12 &&
              status === ANSWERS.STATUS.NO &&
              isPoorCredit(creditScore) &&
              !coborrower,


    legacyBuyer: !!isMasterFunnel && isBuyingFunnel() && isCreatorPage,

    legacyBuyerNoCreditList: !!isMasterFunnel && isBuyingFunnel() && isCreatorPage &&
              status === ANSWERS.STATUS.YES,

    legacyBuyerPoorCredit: !!isMasterFunnel && isBuyingFunnel() && 
              isCreatorPage && 
              isPoorCredit(creditScore),

    masterSeller: !!isMasterFunnel && isSellingFunnel(),

    legacySeller: !!isMasterFunnel && isSellingFunnel() && isCreatorPage,

    isHomeVal: isHomeVal,

  }

  const funnelConfig = [
    {
      conditionName: 'masterBuyer',
      condition: funnelConditions.masterBuyer,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, BudgetMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'budget', 'military', 'address', 'connect', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'masterBuyer1',
      condition: funnelConditions.masterBuyer1,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, BudgetMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'budget', 'military', 'address', 'connect', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'masterBuyer2',
      condition: funnelConditions.masterBuyer2,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, BudgetMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'budget', 'military', 'address', 'connect', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer3',
      condition: funnelConditions.masterBuyer3,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'creditscore', 'military', 'address', 'connect', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'masterBuyer4',
      condition: funnelConditions.masterBuyer4,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'creditscore', 'military', 'address', 'connect', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer5',
      condition: funnelConditions.masterBuyer5,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, CoSignMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'creditscore', 'cosigner', 'military', 'address', 'connect', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'masterBuyer6',
      condition: funnelConditions.masterBuyer6,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, CoSignMaster, MilitaryMaster, AddressMaster, ConnectMaster, NameMaster, EmailMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'creditscore', 'cosigner', 'military', 'address', 'connect', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer7',
      condition: funnelConditions.masterBuyer7,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, CoSignMaster, NameMaster, EmailMaster, Completed2Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'creditscore', 'cosigner', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer8',
      condition: funnelConditions.masterBuyer8,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, BudgetMaster, MilitaryMaster, AddressMaster, NameMaster, EmailMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'budget', 'military', 'address', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer9',
      condition: funnelConditions.masterBuyer9,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, MilitaryMaster, AddressMaster, NameMaster, EmailMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'credit', 'military', 'address', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer10',
      condition: funnelConditions.masterBuyer10,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, CoSignMaster, MilitaryMaster, AddressMaster, NameMaster, EmailMaster, Completed1Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'credit', 'cosigner', 'military', 'address', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterBuyer11',
      condition: funnelConditions.masterBuyer11,
      funnel: [FirstHomeMaster, PropertyTypeMaster, TimeFrameMaster, PreApprovedMaster, IncomeMaster, CreditMaster, CoSignMaster, NameMaster, EmailMaster, Completed2Master],
      urls: ['firstHome', 'type', 'timeframe', 'preapproved', 'income', 'credit', 'cosigner', 'name', 'email', 'complete']
    },
    {
      conditionName: 'masterSeller',
      condition: funnelConditions.masterSeller,
      funnel: [ PropertyTypeMaster, BudgetMaster, TimeFrameMaster, BuyMaster, NameAddressMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Seller],
      urls: ['type', 'budget', 'timeframe', 'buy', 'address', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'legacyBuyer',
      condition: funnelConditions.legacyBuyer,
      funnel: [PropertyTypeMaster, AddressMaster, BudgetMaster, PreApprovedMaster, CreditMaster, TimeFrameMaster, FirstHomeMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Master],
      urls: ['type', 'address', 'budget', 'preapproved', 'creditscore', 'timeframe', 'firstHome', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'legacyBuyerPoorCredit',
      condition: funnelConditions.legacyBuyerPoorCredit,
      funnel: [PropertyTypeMaster, AddressMaster, BudgetMaster, PreApprovedMaster, CreditMaster, TimeFrameMaster, FirstHomeMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed2Master],
      urls: ['type', 'address', 'budget', 'preapproved', 'creditscore', 'timeframe', 'firstHome', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'legacyBuyerNoCreditList',
      condition: funnelConditions.legacyBuyerNoCreditList,
      funnel: [PropertyTypeMaster, AddressMaster, BudgetMaster, PreApprovedMaster, TimeFrameMaster, FirstHomeMaster, NameMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Master],
      urls: ['type', 'address', 'budget', 'preapproved', 'timeframe', 'firstHome', 'name', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'legacySeller',
      condition: funnelConditions.legacySeller,
      funnel: [PropertyTypeMaster, BudgetMaster, TimeFrameMaster, BuyMaster, NameAddressMaster, EmailMaster, PhoneMaster, VerifyMaster, Completed1Seller],
      urls: ['type','budget', 'timeframe', 'buy', 'address', 'email', 'phone', 'verify', 'complete']
    },
    {
      conditionName: 'isHomeVal',
      condition: funnelConditions.isHomeVal,
      funnel: [OwnerHomeVal, IntentionHomeVal, LeadFormHomeVal, PhoneHomeVal, VerifyHomeVal, CompleteHomeVal],
      urls: ['owner', 'intention', 'info', 'phone', 'verify', 'complete']
    }
  ];
  
  for (const config of funnelConfig) {
    if (config.condition) {
      console.log('Condition name: ', config.conditionName);
      initialFunnel = config.funnel;
      initialUrlsList = config.urls;
    }
  }
  
  const goToStep = (step, isBuyerPage) => async () => {

    await dispatch(setStep(step, initialUrlsList[step]));

    navigate('?step=' + initialUrlsList[step]);

    //GTM tracking
    let formName = FORM.AGENTFINDER;

    if (isBuyerPage) {
      formName = FORM.MAFBUYER;
    }

    if (isHomeVal) {
      formName = FORM.HOMEVAL;
    }

    sendToDataLayer({
      step,
      event: 'funnel_steps',
      formName,
      fields,
    });
  };

  const dispatchSetComplete = (value) => {
    dispatch(setComplete(value));
    nprogress.set(1.0);
  };
  const dispatchUpdateParams = (value) => dispatch(updateParams(value));

  const displayMrcModalAndRedirect = () => {

    dispatch(toggleModal());
    
    setTimeout(() => {
      redirect('/lender-match');
    }, 5000);
  }

  const redirect = (url) => {
    window.location.href = url;
  };

  const dispatchSubmitForm = async () => {

    const respLeadId = await dispatch(submitForm());
    if (respLeadId) {
      dispatchSetField('leadId', respLeadId);
      trackLeadId(respLeadId);
    }

    isHomeVal ? trackHomeVal() : defaultTrack();
    defaultRedirect();
  };

  const trackHomeVal = () => {
    sendToDataLayer({
      event: 'funnel_completed_homeval',
      fields,
    });
  };

  const defaultTrack = () => {
    sendToDataLayer({
      event: 'funnel_completed_' + leadEntry,
      formName: leadEntry,
      fields,
    });
  };

  const trackLeadId = (leadId) => {
    sendToDataLayer({
      event: 'setLeadId',
      fields : {
        leadId
      }
    });
  };


  const defaultRedirect = () => {
      if (!isPoorCredit(creditScore) && status === ANSWERS.STATUS.INPROCESS && !isSpanishFunnel) {
        displayMrcModalAndRedirect();
      } else {
        navigate('?step=' + initialUrlsList[initialUrlsList.length - 1]);
      }
  };

  const forceToStep = (step) => {
    dispatch(setStep(step, initialUrlsList[step]));
    displayProgressBar(step);
  }

  const forceToHomepage = () => {
    dispatch(setStep(0));
    displayProgressBar(0);
  }

  useEffect(() => {
    if (path.includes('buying')) {
      dispatchSetField('searchType', 'BUYER');
    }
  },[]);

  //if a user previously visited a path, then redirect to that path
  //otherwise redirect to the homepage
  useEffect(() => {
    const parsed = queryString.parse(location.search);
    //if no step qs found, redirect to home

    if (!parsed.step) {
      forceToHomepage();
    }

    //the step qs found
    if (parsed.step && parsed.step !== initialUrlsList[step]) {
      //found in previously visited steps array
      if (Object.values(visitedSteps).includes(parsed.step)) {

        //force to the step if visited before
        const tempStep = parseInt(Object.keys(visitedSteps).find(key => visitedSteps[key] === parsed.step));
        forceToStep(tempStep);
      } else {
        //a custom redirect for widgets
        if (parsed.step === SLUG.FUNNEL_COMPLETED) {
          forceToStep(initialFunnel.length - 1);
        } else {
          //forceToHomepage();
          forceToStep(step + 1);
        }
      }
    }
  },[]);


  useEffect(() => {

    displayProgressBar(step);
  
    const routeChangeHandler = (step) => {
      const next = initialUrlsList.findIndex((item) => item === step);
      if (next >= 0) {
        dispatch(setStep(next, initialUrlsList[next]));
        displayProgressBar(next);
      } else {
        //if step not found, redirect to MAF homepage
        forceToHomepage();
      }
    };

    
      const parsed = queryString.parse(location.search);
      //need it for back button to work with the modal
      routeChangeHandler(parsed.step);
  
  },[location.search]);

  const goBack = () => {
    navigate(-1);
  }

  const Component = initialFunnel[step];
  return (
    <>
    { showMRCModal ?
        <RedirectModal /> :
        <Component
          // Data
          fields={fields}
          step={step}
          allSteps={initialFunnel.length}
          key={`${step}-form`}
          //Actions
          setField={dispatchSetField}
          setComplete={dispatchSetComplete}
          nextStep={goToStep(step+1, isBuyerPage)}
          previousStep={goBack}
          submitForm={dispatchSubmitForm}
          updateParams={dispatchUpdateParams}
          {...props}
        />
    }
    </>
  );
};

export default MainForm;
