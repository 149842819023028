import React from 'react';
import { COLORS } from 'lib/constants/colors';
import Heading from "components/global/heading";
import { WHATS_NEXT } from 'lib/constants/whatsnext';
import { HEADERS_SIZE } from 'lib/constants/results';


const WhatsNext = () => {

  return (
    <div className='wn-wrapper__split-pane wn-wrapper__left'>
      <div className='wn-wrapper__content inner_wrapper'>
        <Heading
          centered
          loose
          color={COLORS.NAVY}
          headline={{
            copy: `What's Next?`,
            size: HEADERS_SIZE.MAIN,
          }}
        />
        
        <div className='wn ta-center'>
          <div className='wn__steps'>
            {WHATS_NEXT.map((step, i) => (
              <span key={`step_` + i} className='wn__step'>
                <span key={i}>
                  <img className="wn__step__illo" src={step.image} />
                </span>
                <span key={`copy_` + i} className="copy">{step.copy}</span>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNext;
