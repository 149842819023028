// Color usage
export const COLORS = {
  NAVY: '#283567',
  WHITE: '#FFF',
  DARK_BACKGROUND: '#2B2B2B',
};

export const BUTTON_COLORS = {
  LIGHT: {
    PRIMARY: 'light--primary',
    SECONDARY: 'light--secondary',
    RED: 'light--red',
  },
  CTA: {
    PRIMARY: 'cta--primary',
    PRIMARY_IRIS: 'cta--primary-iris',
    SECONDARY: 'cta--secondary',
    DEFAULT: 'cta--default',
    ACTIVE: 'cta--active',
    DARKEN_PEACH: 'cta--darken-peach',
    DARK_ROSE: 'cta--dark-rose',
    OUTLINE: 'cta--outline',
  },
  LINK: {
    GREY: 'link--default',
    WHITE: 'link--white'
  },
  UNDERLINE: {
    WHITE: 'underline--white',
    NONE: 'underline--none',
  },
};

