import React from 'react';
import { COLORS } from 'lib/constants/colors';
import Heading from "components/global/heading";

const ShortSaleComplete = () => {

  return (
    <div className="bg_homes bg-color__lighter-peach">
      <div className='container container--full ss-content'>
        <Heading
          centered
          tight
          color={COLORS.NAVY}
          headline={{
            copy: 'Thanks! You’re on your way to finding the Best Agent!',
            size: 'h5',
          }}
        />
        <Heading
          centered
          tight
          color={COLORS.NAVY}
          headline={{
            copy: 'What\'s Next?',
            size: 'h4',
          }}
        />
        <hr className="text-image-block__hr"></hr>
        <div className='container container--narrow'>
          <span className="paragraph-title text-color__primary-navy">We'll Contact You Very Soon</span>
          <p className="body-copy">
            One of our Agent Specialists will be personally contacting you shortly to confirm your details and explain the next steps. We want to make sure the agent we have in mind can meet all your needs. (Don't worry, we're not selling anything!)
          </p>
          <span className="paragraph-title text-color__primary-navy">Be Prepared to Talk</span>
          <p className="body-copy">
            We'll only connect you with a top agent after we speak to you first to confirm your needs. When you connect with your agent, it's important you know what type of questions to ask him. Here's a good guide to keep in mind when speaking with a realtor: <a href="https://www.thebalance.com/how-to-interview-a-real-estate-agent-1798905" target="_blank">How to Interview a Real Estate Agent</a>. I highly recommend meeting in person with your agent to make sure you have a good connection.
          </p>
          <span className="paragraph-title text-color__primary-navy">Did You Know?</span>
          <p className="body-copy">
            If for some reason you can't get in contact with the agent we recommend or you don’t end up clicking with the agent, don’t worry! Simply call or email us and we’ll be happy to make a new introduction for you. Our services are FREE, and you're under no obligation to work with any agent we recommend.
          </p>
        </div>
      </div>
    </div>
  )
};

export default ShortSaleComplete;
