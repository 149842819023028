import React from 'react';
import { BUTTON_COLORS, COLORS } from 'lib/constants/colors';
import Heading from "components/global/heading";
import { HEADERS_SIZE } from 'lib/constants/results';
import IntroLendCTA from "components/global/introLendCTA";

const Finance = () => {

  return (
    <div className='wn-wrapper__split-pane wn-wrapper__right' data-cy={'finance_component'}>
      <div className='ta-center wn-wrapper__right-container'>
        <div className='wn-wrapper__content'>
          <div className='inner_wrapper'>
            <Heading
              centered
              loose
              color={COLORS.NAVY}
              headline={{
                copy: 'Finance Your Home Now',
                size: HEADERS_SIZE.MAIN,
              }}
            />
            <IntroLendCTA
              text={'Get Pre-Approved Today'}
              color={BUTTON_COLORS.CTA.ACTIVE}
              displayLogo={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
