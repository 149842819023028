import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/global/button';
import AutoComplete from 'components/global/googleAutocomplete';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { SLUG } from 'lib/constants/funnelMeta';
import { setField, getMainForm } from '../../../store';

const MortgageField = () => {

  const { fields = {} } = useSelector(getMainForm);
  const {
    address,
  } = fields;

  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const onFieldStatus = (state) => {
    setEnabled(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  }

  const onSubmit = () => {

    if (address) {
      onFieldStatus(true);
      if (!isAutocomplete) {
        searchManually();
      }
      redirect();
    } else {
      onFieldStatus(false);
    }
  };

  const redirect = () => {
    window.location.href = '/' + SLUG.LENDERMATCH;
  }

  const searchManually = () => {
    const service = new google.maps.places.AutocompleteService();

    const options = {
      types: ['postal_code', 'neighborhood', 'locality', 'administrative_area_level_2', 'administrative_area_level_3'],
      componentRestrictions: {country: 'us'}
    };

    service.getPlacePredictions({
      input: address,
      options
    }, displaySuggestions);

  };

  const displaySuggestions = (predictions, status) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
        onFieldStatus(false);
        return;
    }
    getDetailsManually(predictions[0].place_id);    
  };

  const getDetailsManually = async placeId => {
    new Promise((resolve, reject) => {
      if (!placeId) reject("placeId not provided")
      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          details => {
            const address = details.address_components.reduce((seed, { long_name, types }) => {
              types.forEach(t => {
                seed[t] = long_name;
              });
            
              return seed;
            }, {});

            const city = address.locality;
            const state = address.administrative_area_level_1;
            const county = address.administrative_area_level_2;
            const neighborhood = address.neighborhood;
            const postalCode = address.postal_code;
            const addressFull = `${(city ? (city+', ') : '')}${(state ? (state+', ') : '')}`;
    
   
            setField('address', addressFull ? `${addressFull}USA` : '');
            setField('city', city);
            setField('state', state);
            setField('postalCode', postalCode);
            setField('county', county);
            setField('neighborhood', neighborhood);

            return resolve(details)
          }
        )
      } catch (e) {
        reject(e)
      }
    })
  };

  return (
    <div className='mrc-address'>
      <div className={address ? 'mrc-input' : 'mrc-input required'}>
        <AutoComplete
          geoType={['postal_code', 'neighborhood', 'locality', 'administrative_area_level_2', 'administrative_area_level_3']}
          placeholder={'Where are you looking to buy?'}
          setField={setField}
          enabledField={enabled}
          onFieldStatus={onFieldStatus}
          usedAutocomplete={usedAutocomplete}
        />
      </div>
      <Button
        text={'Get Rates'}
        color={BUTTON_COLORS.CTA.DEFAULT}
        onClick={onSubmit}
      />
    </div>
  );
};

export default MortgageField;
