import React from 'react';
import { SLUG } from 'lib/constants/funnelMeta';
import { FUNNEL } from 'lib/constants/translations';

const CTA = ({ title, isSpanishFunnel = false }) => {

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = '/' + SLUG.LENDERMATCH;
  };

  return (
    <div className="completed_cta">
      <div className="ham-badge">
        {FUNNEL.cta.nextStep[language]}
      </div>
      <p className="headline">
        {title || FUNNEL.cta.financeWhileWait[language]}
      </p>
      <div className="cta">
        <a className="button-home-hero" href="#" onClick={handleClick}>
          <div className="get-started">{FUNNEL.cta.getPreApproved[language]}</div>
          <div className="badge">
            <p className="label valign-text-middle">{FUNNEL.cta.onlyTakesMinutes[language]}</p>
          </div>
        </a>
        <span className='broker_logos'></span>
      </div>
    </div>
  )
};

export default CTA;