import React from 'react';
import { formatCurrency } from 'utils/utils.js';

const ValuationRange = (props) => {

  const { fields = {} } = props;
  const { priceLow, priceHigh, priceAverage } = fields;


  return (
    <div>
      <h1 className='form-title'>Our Estimate</h1>

      <div className='range-wrapper'>
        <div className="valuation-range">
          <div className="top-values">
              <span className="min-amount">{formatCurrency(priceLow)}</span>
              <span className="mid-amount">{formatCurrency(priceAverage)}</span>
              <span className="max-amount">{formatCurrency(priceHigh)}</span>
          </div>
          <div className="range-line">
              <div className="split"></div>
          </div>
          <div className="bottom-labels">
              <span className="min-label">Low Range</span>
              <span className="mid-label">Our Estimate</span>
              <span className="max-label">High Range</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationRange;
