export const testAgents = [
  {
    "sfid": "0033a00002ev7BbAAI",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "She guided us through the process and truly set our doubts aside! We highly recommend Christina Cardenas to be your trustworthy, hardworking, communicative realtor. From our first conversation with her on the phone to meeting in person, she provided us with both confidence and respect to find a home and neighborhood that would fit our needs as a family. We were first-time home buyers and were new to the state of Texas, she guided us through the process and truly set our doubts aside. She fought for us in negotiations, listened to our concerns, and made sure we asked the right questions and did the right inspections as well. She performed not just as a realtor but as a guide through the process, and she did this with integrity. We felt cared for, even after the purchase, as she has made sure we are still checking our warranties and doing what needs to be done even after the paper has been signed, which means so much.",
      "In the chaos of my busy military life, I couldn't have asked for a more compassionate and dedicated real estate professional. Balancing my duties with parenting my two wonderful kids, the prospect of finding a home was daunting, but Christina truly exceeded all expectations. From the moment we started working together, she showed an unparalleled commitment to meeting our needs. Her market knowledge, expertise, and dedication made the home-buying process not only manageable but enjoyable. With her help, we found a home that perfectly fits our needs, in a neighborhood we absolutely love. What particularly impressed me was her deep understanding and respect for the unique circumstances and needs of military families. She demonstrated exceptional flexibility, fitting in house showings and meetings around my unpredictable schedule. Her patience and willingness to answer all my questions was greatly appreciated. In short, Christina’s passion, professionalism, and personal touch have left a lasting impact on my family. Thank you for turning our dream into reality!"
    ]
  },
  {
    "sfid": "0033a00002qn2PKAAY",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "Sheana is an amazing realtor! If you are in the market to purchase or sell a house, I highly recommend her. She is quick to respond, knowledgeable, and genuinely cares about her clients. She is the agent you want! I can honestly say we have bought many homes over the years, but she has by far been the most reliable agent and wonderful to work with. Love her!",
      "Sheana was absolutely amazing straight from the start. Being a first-time homebuyer, I had no idea where to even start in finding my first home. Sheana guided me through to understanding everything, and anytime I had questions or concerns, she was there to walk me through. We looked at several homes before finally deciding on a place. Even with the challenges and delays we had in closing, Sheana was constantly at work getting us as much information and reassurance as we needed. I highly recommend her, and I would absolutely request her as my realtor again in the future!"
    ]
  },
  {
    "sfid": "003j0000015uFZRAA2",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "Troy Sanders was amazing to work with. As a first-time home buyer, Troy made me feel very comfortable with the process and was always willing to answer my questions and concerns. I'm very thankful to have worked with Troy. He is an outstanding human being, very easy to talk to, and always respected my time. He made the whole process as easy as possible.",
      "If I could give Troy 20 stars I would. Troy was always there for us when we needed him. Extremely knowledgeable, kind, courteous, and welcoming. Troy is an all-around good person. I would trust him with my life. He helped us work out a great deal on our home and snag one in a perfect neighborhood for our family. It's a tough market with slim pickings, but Troy helped us get the perfect home at a fair price."
    ]
  },
  {
    "sfid": "0033a00002gCh4KAAS",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "My husband and I worked with Jennifer Hemstrom to purchase our first house. Jennifer went above and beyond to make us feel comfortable and supported through this very stressful process.",
      "After all the hardships and hoops and lack of technical knowledge, Jennifer provided me with encouragement and much-needed help throughout the entire process! I wanted to give up on several occasions, but Jennifer stood by me and kept me going! She explained things in a language I could understand. Her patience and perseverance are undeniable, and even when I was frustrated, she maintained professionalism and calmed my frayed nerves. Jennifer is the reason I have a home today! Her guidance and support made all the difference."
    ]
  },
  {
    "sfid": "003Rl000001ynTXIAY",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "My husband and I learned so much and had a wonderful experience with Brian as our home-buying agent. He was always available for showings and persistent in helping us find our new home. We were in constant communication, and his experience as a selling and buying agent was invaluable. I would definitely recommend him to any close friend or colleague looking to buy in this market.",
      "Brian will go above and beyond to make sure you get what you want and will also work with builders to ensure your home is being built correctly. Brian has a ton of connections to make decisions easy on upgrades like epoxy floors, screen rooms, and more. Great guy! If we're ever in need of a realtor again, Brian will be our only choice!"
    ]
  },
  {
    "sfid": "0033a00002lhy9RAAQ",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "I was so blessed to work with Jen Gotlewski when it came time to purchase my new home. Jen was so knowledgeable and gave me sound advice, acted quickly, and made me feel confident and secure throughout the entire process. She worked tirelessly to help me find the perfect house, made sure I got the best deal possible, and stayed by my side throughout closing. I would highly recommend Jen to anyone thinking of buying or selling a home.",
      "I contacted Jen out of the blue, not knowing anything about Florida real estate. I had just taken a job in Orlando and needed a house quickly. Jen got me a list of available homes ASAP, was able to meet me at my time, and we found a house on the first day of looking. She saw me through all of it, checking in, walking me through the paperwork, and even after the fact, she has been amazingly helpful. She connected me with fence companies, lawn care services, and more. If I had to do it over, I would choose Jen again and again!"
    ]
  },
  {
    "sfid": "003Rl0000026VRNIA2",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "Monique was a fantastic agent to work with. After an unsuccessful go with another agency, I found Monique by chance and am so glad I did! She was extremely professional, informative, and responsive. We ended up finding the PERFECT home for me and she worked efficiently with the seller’s agent to facilitate the process. I would highly recommend working with her!",
      "Monique Castro is an amazing Realtor to work with! We both found the perfect home for her client here in DR Horton Sorrento, Richmond TX!  Monique's strong follow up, quick response time and friendly personality helped every piece of the transaction to be able to close super smoothly and on time.  I look forward to working with Monique into the future here within our SW DR Horton communities!"
    ]
  },
  {
    "sfid": "003j000001MZYIuAAP",
    "video_url": null,
    "starRating": 5,
    "testimonials": [
      "Cody did a terrific job as our real estate agent. First he showed us dozens of houses in a very competitive market and eventually helped us find exactly what we were looking for below list price. Then he managed the process of listing and staging our previous home and we had 12 showings the first day and 4 above list offers! He is trustworthy, hardworking and knows his stuff! Highly recommended.",
      "Cody is the Realtor you should choose, look no further. Cody is amazing. He helped me find the perfect house. This house is my dream home. Cody helped me negotiate the house for 2 months as it was a long process because of issues with the owner - basically there was no water to the house. He helped every step of the way until closing. There is no better Realtor than Cody McMillian. If you are looking for a professional Realtor, Cody is the person. We bought the house on 5/10/2021 and it's been the best year of living in this house. He has also helped me find several rentals before I got to the stage in my life to buy a house. So if you are looking to rent a house, go with Cody. If you are trying to buy a house, go with Cody. I am so grateful for Cody and so glad I could work with him on 2 lease agreements and 1 home purchase! Thank you Cody!"
    ]
  }
];