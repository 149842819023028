import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import Dropdown from 'components/global/dropdown';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';

const OPTIONS = [
  { text: 'Under $50K', value: 'Under $50,000' },
  { text: '$50k - $100k', value: '$50,000 - $100,000' },
  { text: '$100K - $150K', value: '$100,000 - $150,000' },
  { text: '$150K - $200K', value: '$150,000 - $200,000' },
  { text: '$200K - $250K', value: '$200,000 - $250,000' },
  { text: '$250K - $300K', value: '$250,000 - $300,000' },
  { text: '$300K - $400K', value: '$300,000 - $400,000' },
  { text: '$400K - $500K', value: '$400,000 - $500,000' },
  { text: '$500K - $600K', value: '$500,000 - $600,000' },
  { text: '$600K - $800K', value: '$600,000 - $800,000' },
  { text: '$800K - $1M', value: '$800,000 - $1,000,000' },
  { text: 'Over $1M', value: 'Over $1,000,000' },
];

const HEADER = {
  Buyer: 'Roughly, how much is your budget?',
  Seller: 'Roughly, how much is your home worth?',
};

const Budget = ({ fields = {}, setField, nextStep, previousStep }) => {


  const onBack = () => { previousStep(); setField('priceRange', null); };
  const { priceRange = null, searchType } = fields;

  const handleChange = (event) => {
    setField('priceRange', event.target.value);
    nextStep();
  }

  return (
    <div className='container container--narrow ta-center rebranding'>
      <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: searchType === "BUYER" ? HEADER.Buyer : HEADER.Seller
          }}
        />
      </div>
      <div className='container container--extra-narrow pricerange'>
        <Dropdown
          value={priceRange || ''}
          options={OPTIONS}
          onChange={handleChange}
          placeholder='Select Price Range'
          dataCY={'price_range_dropdown'}
        />
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default Budget;
