import React from 'react';
import Input from 'components/global/input';
import TextArea from 'components/global/textarea';
import Button from 'components/global/button';
import Dropdown from 'components/global/dropdown';
import {BUTTON_COLORS, COLORS} from 'lib/constants/colors';
import {useSelector, useDispatch} from 'react-redux';
import {getContactForm, setContactComplete, setContactField, sendContactEmail} from 'store/index';
import Heading from "components/global/heading";
import validator from 'validator';
import { maskValue } from 'utils/mask';

const ContactForm = () => {
  const { complete = false, fields = {} } = useSelector(getContactForm);
  const { name = '', phone = '', email = '', category = '', message = '' } = fields;
  const dispatch = useDispatch();
  const updateField = (field) => (e) => dispatch(setContactField(field,e.target.value));
  const updatePhone = (e) => dispatch(setContactField('phone',maskValue(e)));
  const completeForm = () => {
    dispatch(setContactComplete(true));
    dispatch(sendContactEmail(fields));
  };

  const isPhoneValid = phone && (phone.toString()).length === 14; // (555) 555-5555
  const isEmailValid = email && validator.isEmail(email);
  const canSubmitForm = name && isPhoneValid && isEmailValid;

  return !complete ? (
    <div className='contact-form container container--narrow ta-center'>
      <form className='flex input-wrapper ta-center space-between'>
        <div className='field field--narrow'>
          <Input value={name} name='fullname' onChange={updateField('name')} placeholder='Your Name' />
        </div>
        <div className='field field--narrow'>
          <Input value={email} name='email' onChange={updateField('email')} placeholder='Email Address' />
        </div>
        <div className='field field--narrow'>
          <Input value={phone} name='phone' onChange={updatePhone} placeholder='Phone Number' />
        </div>
        <div className='field field--narrow'>
          <Dropdown
            value={category}
            options={[
              {value: '', text: `Category`, default: true },
              {value: `I'm selling`, text: `I'm selling`},
              {value: `I'm buying`, text: `I'm buying`},
              {value: `I'm an agent`, text: `I'm an agent`},
              {value: `Other`, text: `Other`},
            ]}
            onChange={updateField('category')}
            placeholder='Category'
            dataCY={'category'}
          />
        </div>
        <div className='field field--wide'>
          <TextArea value={message} onChange={updateField('message')} rows='4' placeholder='Message' />
        </div>
      </form>
      <div className='submit-wrapper ta-right'>
        <Button
          disabled={!canSubmitForm}
          text={'Submit'}
          onClick={completeForm}
          color={BUTTON_COLORS.CTA.ACTIVE}
          classes='contact-form-button'
        />
      </div>
    </div>
  ) : (
    <div className='contact-form container container--narrow ta-center'>
      <Heading
        loose
        color={COLORS.NAVY}
        headline={{
          copy: <b>We'll get back to you soon!</b>,
          size: 'h4'
        }}
        subHeadingColor={COLORS.DARK_BACKGROUND}
      />
    </div>
  );
};

export default ContactForm;
