import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { sendToDataLayer } from 'lib/logGTM';
import validator from 'validator';
import { FUNNEL } from 'lib/constants/translations';

const EmailMaster = (props = {}) => {
  const { fields = {}, step, setField, nextStep, setComplete, submitForm, allSteps } = props;
  const { email = '', leadEntry, isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  let header = FUNNEL.email.question[language];
  const updateField = (field) => (e) => {
    onEmailFieldStatus(true);
    setField(field, e.target.value);
  }


  const onSubmit = async () => {

    if (email && validator.isEmail(email)) {
      onEmailFieldStatus(true);
      sendToDataLayer({
        step: (step + 1),
        event: 'email_submitted',
        email,
        formName: leadEntry,
        fields
      });

      //email step is the second to last step
      if (step === allSteps-2) {

        setComplete(true);
        await submitForm('main');
      } else {
        nextStep();
      }
      
    } else {
      onEmailFieldStatus(false);
    }
  };


  const [emailField, setEmailField] = useState(true);

  const onEmailFieldStatus = (state) => {
    setEmailField(state);
  }
  
  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{header}</h2>

      <ul className='form-list'>
        {FUNNEL.email.list[language].map((item, index) => (
          <li key={index} className='form-list__item'>{item}</li>
        ))}
      </ul>
      
      <div className='form-inputs short-btns'>
        <Input
          value={email}
          onChange={updateField('email')}
          placeholder={FUNNEL.email.placeholder[language]}
          type={'email'}
          dataCY={'email'}
          className= { emailField ? null : 'input--invalid'}
        />
        <Button
          text={FUNNEL.email.button[language]}
          color={BUTTON_COLORS.CTA.DEFAULT}
          onClick={onSubmit}
          dataCY={'search_cta'}
        />
      </div>

      <div className='email-disclaimer'>
        <span dangerouslySetInnerHTML={{ __html: FUNNEL.email.disclaimer[language] }}></span>
      </div>
    </div>
  );
};

export default EmailMaster;
