import async from './async';

// API Form
const path = window.location.pathname === '/' ? '' : window.location.pathname;
const landingpage = typeof window !== 'undefined' ? window.location.hostname + path : '';
const CREATE = `${process.env.API_URL}/api/v0/leads/create`;
const UPDATE = `${process.env.API_URL}/api/v0/leads/update`;
const api_key = process.env.API_KEY;
const secret_key = process.env.API_SECRET;
const isProd = document.location.hostname === 'homeandmoney.com';

const form = async (data = {}) => {
  const {
    searchType = '',
    address = '',
    street = '',
    city = '',
    state = '',
    postalCode = '',
    propertyType = '',
    priceRange = '',
    name = '',
    phone = '',
    email = '',
    leadsource = '',
    gclid = '',
    wbraid = '',
    msclkid = '',
    utm_account,
    utm_source, 
    utm_medium,
    utm_campaign,
    utm_adgroup,
    utm_content,
    utm_keyword,
    device,
    devicemodel,
    network,
    placement,
    macthtype,
    adposition,
    creative,
    utm_par,
    iloc,
    ploc,
    originUrl,
    priceLow,
    priceHigh,
    priceAverage,
    experimentId,
    variantId,
    creditScore,
    buyingAndSelling,
    status,
    timeframe,
    intention,
    firstHome,
    householdIncome,
    militaryVeteran,
    otherAgent,
    affiliate,
    affiliateLeadContact,
    isInfluencerLead,
    twilioMethod,
    fbp,
    fbc,
    coborrower,
    isHomeOwner,
  } = data;

  const options = {
    // User Data
    prop_location: address,
    street,
    city,
    state,
    postalcode: postalCode,
    leadtype: searchType,
    price: priceRange,
    hometype: propertyType,
    lastname: name,
    phone,
    email,
    // Tracking Data
    wbraid,
    gclid,
    msclkid,
    utm_account,
    utm_source, 
    utm_medium,
    utm_campaign,
    utm_adgroup,
    utm_content,
    utm_keyword,
    device,
    devicemodel,
    network,
    placement,
    macthtype,
    adposition,
    creative,
    utm_par,
    iloc,
    ploc,
    originUrl,
    priceLow,
    priceHigh,
    priceAverage,
    experimentId,
    variantId,
    creditScore,
    buyingAndSelling,
    status,
    timeframe,
    intention,
    firstHome,
    householdIncome,
    militaryVeteran,
    otherAgent,
    //Staging SF doesn't have affiliates, so we have to erase them on local/QA
    affiliate: isProd ? affiliate : '',
    affiliateLeadContact: isProd ? affiliateLeadContact : '',
    isInfluencerLead,
    twilioMethod,
    fbp,
    fbc,
    coborrower,
    // MAF Data
    leadsource,
    channelwebsite: 'HomeAndMoney.com',
    landingpage,
    api_key,
    secret_key,
    isHomeOwner,
    // Unused postalcode4:'', personality:'', county:'', street_number:'', route:'',
  };

  return async.post(CREATE,options);
};

const update = async (opts = {}, id = '' ) => {
  const url = `${UPDATE}/${id}`;

  const options = {
    ...opts,
    api_key,
    secret_key,
  }

  return async.post(url, options);
};

const sendTwilioCode = async (type, phone) => {

  const url = `${process.env.API_URL}/api/v0/twilio/sendCode`;
  const sid = process.env.TWILIO_SID;
  
  const options = {
    type,
    phone,
    sid,
    api_key,
    secret_key,
  }

  return async.post(url, options);

}

const checkTwilioCode = async (code, phone) => {

  const url = `${process.env.API_URL}/api/v0/twilio/confirm`;
  const sid = process.env.TWILIO_SID;
  
  const options = {
    code,
    phone,
    sid,
    api_key,
    secret_key,
  }

  return async.post(url, options);

}

const getHomeValuation = async (street, postalCode, reportType, isTest) => {

  const url = `${process.env.API_URL}/api/v0/homeval/getValuation?api_key=${api_key}&secret_key=${secret_key}&street=${encodeURIComponent(street)}&postalCode=${encodeURIComponent(postalCode)}&report=${encodeURIComponent(reportType)}&isTest=${isTest}`;
  return async.get(url);
}



// Send Grid
const SEND_GRID = 'https://api.sendgrid.com/api/mail.send.json';
const sendGridData = {
  api_user: 'MAF',
  api_key: process.env.SEND_GRID_API_KEY,
  subject: 'MyAgentFinder Contact Form',
  to: 'support@myagentfinder.com',
  toname: 'MyAgentFinder',
  from: 'support@myagentfinder.com'
};

export const sendContactEmail = async (contact = {}) => {
  const messageHtml = `
    <table border='0' cellpadding='2' cellspacing='0'>
    <tr><td>Name:</td><td>${contact.name}</td></tr>
    <tr><td>Email:</td><td>${contact.email}</td></tr>
    <tr><td>Phone Number:</td><td>${contact.phone}</td></tr>
    <tr><td>Category:</td><td>${contact.category}</td></tr>
    <tr><td>Message:</td><td>${contact.message}</td></tr>
    </table>
  `;

  const data = {
    ...sendGridData,
    html: messageHtml,
  };

  return async.postAsForm(SEND_GRID,data);
};

export default {
  form,
  sendContactEmail,
  update,
  sendTwilioCode,
  checkTwilioCode,
  getHomeValuation,
};