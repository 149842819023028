import React from 'react';
import ContactDetails from './contactDetails';
import CTAButton from './cta';
import SvgBuy from './svgBuy';
import SvgSell from './svgSell';

const Sidebar = ({ agentID, contactDetails }) => {
  return (
    <div className='inline-flex flex-col-reverse items-start justify-start w-full gap-[24px] md:flex-row-reverse lg:flex-col FinderLinkWidgetWrapper'>
      <div className='FinderLinkWidget justify-center self-stretch p-6 bg-[#f0f1fd] rounded-xl flex-col items-start gap-8 flex'>
        <div className='flex flex-col items-start self-stretch justify-start h-6 gap-1 TextWrapper'>
          <div className="Headline w-full text-[#2A2F64] text-center text-[20px] not-italic font-medium leading-[120%] tracking-[-0.4px]">
            Get Started with {contactDetails.name}
          </div>
        </div>
        <div className='flex flex-col items-start self-stretch justify-start gap-3 Ctas'>
          <CTAButton
            href={`/selling?agentID=${agentID}`}
            icon={<SvgSell />}
            label="I'm Selling"
          />
          <CTAButton
            href={`/buying?agentID=${agentID}`}
            icon={<SvgBuy />}
            label="I'm Buying"
          />
        </div>
      </div>
      <ContactDetails details={contactDetails} />
    </div>
  );
};

export default Sidebar;

