import React, {useEffect} from 'react';
import { COLORS } from 'lib/constants/colors';
import Heading from 'components/global/heading';
import ShortSaleForm from './form';
import { addBodyClass, removeBodyClass } from 'utils/utils';
import { getShortsaleForm } from '../../store';
import { useSelector } from 'react-redux';
import ShortSaleComplete from './complete';
import { useLocation } from "react-router-dom";
import * as queryString from 'query-string';
import { CLASS, SLUG } from 'lib/constants/funnelMeta';

const ShortSale = () => {
  const { complete = false } = useSelector(getShortsaleForm);
  
  let location = useLocation();
  const parsed = queryString.parse(location.search);
  const completed = parsed.step && complete;

  useEffect(() => {
    addBodyClass(CLASS.SHORTSALE.DEFAULT);
  },[]);

  if (parsed.step && parsed.step === SLUG.FUNNEL_COMPLETED) {
    addBodyClass(CLASS.SHORTSALE.COMPLETED);
  } else {
    removeBodyClass(CLASS.SHORTSALE.COMPLETED);
  }
  
  return !completed ? (
    <div className="container container--wide">
      <div className="main-form">
        <div className="split-wrapper">
            <div className="split-wrapper__item split-wrapper__item--body">
              <Heading
                tight
                color={COLORS.WHITE}
                headline={{
                  copy: 'Connect With a Qualified Short Sale Agent in Your Market',
                  size: 'h4'
                }}
                subHeading={`We match buyers and sellers with qualified short sale agents, for free.`}
              />
            </div>
            <ShortSaleForm />
        </div>
      </div>
    </div>
  ) : (
    <ShortSaleComplete />
  );
};

export default ShortSale;
