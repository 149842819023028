import React from 'react';

const AboutSection = ({ aboutSection }) => {
  return (
    aboutSection.description && (
    <div className='flex flex-col items-start self-stretch justify-start gap-6 Content'>
      <div className='Headline text-[#2A2F64] text-[27px] sm:text-[39px] not-italic font-normal leading-[135%] font-new-spirit'>About {aboutSection.firstname}</div>
      <div className='Paragraph self-stretch text-[#667085] text-[16px] font-normal leading-normal'>
        {aboutSection.description}
      </div>
    </div>
    )
  );
};

export default AboutSection;
