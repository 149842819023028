import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';

const BUTTONS = [
  { text: 'Yes', value: 'I am in the process of getting pre-approved'},
  { text: 'No', value: 'Haven\'t Applied Yet' },
  { text: 'I\'m already pre-approved', value: 'Pre-Approved' }
];

const PreApproved = ({ fields = {}, setField, nextStep, previousStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: BUTTON_COLORS.CTA.DEFAULT,
    ACTIVE: BUTTON_COLORS.CTA.DARK_ROSE,
  }

  const onBack = () => { previousStep(); setField('status', null); };
  const { status = null } = fields;
  const setActiveButton = (value) => () => {
    setField('status', value);
    nextStep();
  };

  return (
    <div className='container container--normal ta-center rebranding'>
      <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: 'Would you like our help getting pre-approved for a loan?'
          }}
        />
      </div>
      <div className='container container--narrow'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={status === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'property-button'}
            dataCY={'property_button'}
          />
        )}
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default PreApproved;
