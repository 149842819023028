import React from 'react';
import CTA from './cta';
import { FUNNEL } from 'lib/constants/translations';

const Completed1Seller = (props = {}) => {

  const { fields = {} } = props; 
  const { state, name, email, phone, isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH'; // Based on language preference

  const ctaProps = { name, email, phone, state, isSpanishFunnel };

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{FUNNEL.completed1Seller.stepDone[language]}</h2>
      <ul className='form-list'>
        <li className='form-list__item'>{FUNNEL.completed1Seller.searchingDatabase[language]}</li>
        <li className='form-list__item' dangerouslySetInnerHTML={{__html: FUNNEL.completed1Seller.matchingAgent[language]}}></li>
      </ul>

      <CTA
        {...ctaProps}
        title={FUNNEL.completed1Seller.financeWhileWait[language]}
      />
    </div>
  );
};

export default Completed1Seller;
