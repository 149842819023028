import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMainForm, sendUpdate } from '../../store';
import qs from 'qs';
import { getFirstName, getLastName, addBodyClass } from "utils/utils";
import { CLASS } from 'lib/constants/funnelMeta';

const LenderMatch = (props) => {
  let { fields = {} } = useSelector(getMainForm);
  const dispatch = useDispatch();
  let isRefinance = '';
  
  // REF-305 if available, set these attributes from the qs (e.g. ActiveCampaign)
  let params = {};
  if (typeof window !== 'undefined') {
    params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
  }

  let { name, email, city, state, propertyType, priceRange, phone, leadId, militaryVeteran, timeframe, utm_par, leadsource } = fields;
  
  if (params.name) {
    name = params.name;
  }
  if (params.email) {
    email = params.email;
  }
  if (params.phone) {
    phone = params.phone;
  }
  if (params.refi) {
    isRefinance = params.refi;
  }
  if (params.lid) {
    leadId = params.lid;
  }

  const updateLender = async (lender = '') => {
    const opts = {
      lender: lender,
    }
    if (leadId && lender) {
      await dispatch(sendUpdate(opts, leadId));
    }
  }

  const getMatchingValue = (value) => {
    switch (value) {
      case '0 - 3 Months':
        return '2-3 Months';
      case '3 - 6 Months':
        return '3-6 Months';
      case '6 - 12 Months':
      case 'Over 1 Year':
        return '6+ Months';
      default:
        return value;
    }
  }

  useEffect(() => {
    if (props.componentID === 'MRC') {
      addBodyClass(CLASS.LENDERMATCH.DEFAULT);
    }

    const params = new URLSearchParams(utm_par);

    //for MRC widget
    const utm_term_values = ['affiliateAgentID', 'affiliateLeadContactID', 'gclid', 'msclkid', 'fbclid'];


    const leadFormObj = {
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      phone,
      property_city: city,
      property_state: state,
      property_value: priceRange,
      property_type: propertyType,
      loan_type: isRefinance,
      militaryVeteran: militaryVeteran ? 'Y' : 'N',
      purchase_when: getMatchingValue(timeframe),
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_campaign: params.get('utm_campaign'),
      utm_content: leadsource,
      utm_term: utm_term_values.find(term => params.get(term)),
    }
    if (typeof window !== 'undefined') {
      window.scrollTo(0,0);
      window.Fmcty = {};
      window.Fmcty.values = leadFormObj;
      window.submissionReturn = function(data) {
        if (data.lenders && data.lenders.length > 0) {
            updateLender(data.lenders[0].company_name);
        }
      }
    }

    //loads on /lender-match
    const scriptLM = document.createElement('script');
    scriptLM.src = 'https://www.mortgageresearchcenter.com/scripts/boomtown.9579363849.js';
    scriptLM.async = true;
    scriptLM.setAttribute('data-mrc', 'BoomTown');
    scriptLM.setAttribute('data-mrc-header', '#standard');
    scriptLM.setAttribute('data-mrc-success-callback', 'submissionReturn');
    
    //loads on MRC LP
    const scriptMRC = document.createElement('script');
    scriptMRC.src = 'https://www.mortgageresearchcenter.com/scripts/homeAndMoney.js';
    scriptMRC.async = true;
    scriptMRC.setAttribute('data-mrc', 'HomeAndMoney');

    const script = props.componentID === 'MRC' ? scriptMRC : scriptLM;
    const wrapper = document.getElementById('mrc-wrapper');
    wrapper.appendChild(script);

    return () => {
      wrapper.removeChild(script);
    }
  },[props.componentID]);

  return (
    <div id="mrc-wrapper"></div>
  )
};

export default LenderMatch;
