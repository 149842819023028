import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { PROGRAMS } from 'lib/constants/funnelMeta';
import IntroLendCTA from "components/global/introLendCTA";
import Button from 'components/global/button';

const CustomAccordionItem = ({program, onClick, ctaComponent}) => {
  return (
    <AccordionItem uuid={program.ID} className={`accordion__item ${program.ID}`}>
      <AccordionItemHeading>
        <AccordionItemButton>
          <span className="name">{program.NAME}</span>
          <span className="divider"></span>
          <span className="accordion-title">{program.TITLE}</span>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <span className="contentWrapper">
          <span className="vector"/>
          <span className="content">{program.CONTENT}</span>
          <span className="ctas">
            <span className="vector desktop"/>
            {ctaComponent}
          </span>
        </span>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

const Accordion1Master = (props) => {

  const onClick = () => {
    window.location.href = '/first-time-home-buyer/offer/';
  };

  return (
    <Accordion allowMultipleExpanded allowZeroExpanded >

      <CustomAccordionItem
        program={PROGRAMS.REBATE}
        onClick={onClick} 
        ctaComponent={
          <Button
            text={'Offer Terms'}
            onClick={() => onClick()}
            classes='button--primary'
          />
        }
      />

      <CustomAccordionItem
        program={PROGRAMS.FHA}
        ctaComponent={
          <IntroLendCTA
            isMasterFunnel={true}
            text={'Get Pre-Approved'}
            classes='button--primary'
          />
        }
      />

      <CustomAccordionItem
        program={PROGRAMS.HOMEONE} 
        ctaComponent={
          <IntroLendCTA
            isMasterFunnel={true}
            text={'Get Pre-Approved'}
            classes='button--primary'
          />
        }
      />

      {props.isMilitary &&
        <CustomAccordionItem
          program={PROGRAMS.VA} 
          ctaComponent={
            <IntroLendCTA
              isMasterFunnel={true}
              text={'Get Pre-Approved'}
              classes='button--primary'
            />
          }
        />
      }

      {props.firstHome &&
        <CustomAccordionItem
          program={PROGRAMS.LTV}
          ctaComponent={
            <IntroLendCTA
              isMasterFunnel={true}
              text={'Get Pre-Approved'}
              classes='button--primary'
            />
          }
        />
      }
         
    </Accordion>
  );
};

export default Accordion1Master;
