import React from 'react';
import Button from 'components/global/button';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { SLUG } from 'lib/constants/funnelMeta';

const Engagement = () => {
  const onClick = () => {
    window.location = '/' + SLUG.AGENTFINDER;
  };
  return (
    <div className='section primary-bg' style={{paddingTop: '1em', paddingBottom: '1em'}}>
      <div className={'container container--normal ta-center'}>
        <span className={'body-copy h6'} style={{paddingRight: '2em', color: 'white'}}>We can help match you with one of our local experts too!</span>
        <Button
          text={'Get Started'}
          color={BUTTON_COLORS.LIGHT.PRIMARY}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default Engagement;
