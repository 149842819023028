import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import Dropdown from 'components/global/dropdown';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';

const OPTIONS = [
  { text: '$0 - $25,000', value: '$0 - $25,000' },
  { text: '$25,001 - $50,000', value: '$25,001 - $50,000' },
  { text: '$50,001 - $75,000', value: '$50,001 - $75,000' },
  { text: '$75,001 - $100,000', value: '$75,001 - $100,000' },
  { text: '$100,001 - $125,000', value: '$100,001 - $125,000' },
  { text: '$125,001 - $150,000', value: '$125,001 - $150,000' },
  { text: '$150,001 - $175,000', value: '$150,001 - $175,000' },
  { text: '$175,001 - $200,000', value: '$175,001 - $200,000' },
  { text: '$200,001 - $225,000', value: '$200,001 - $225,000' },
  { text: '$225,001 - $250,000', value: '$225,001 - $250,000' },
  { text: 'More Than $250,000', value: 'More Than $250,000' },
];


const Income = ({ fields = {}, setField, nextStep, previousStep }) => {


  const onBack = () => { previousStep(); setField('householdIncome', null); };
  const { householdIncome = null } = fields;

  const handleChange = (event) => {
    setField('householdIncome', event.target.value);
    nextStep();
  }

  return (
    <div className='container container--narrow ta-center rebranding'>
      <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: 'What\'s your annual household income?'
          }}
        />
      </div>
      <div className='container container--extra-narrow pricerange'>
        <Dropdown
          value={householdIncome || ''}
          options={OPTIONS}
          onChange={handleChange}
          placeholder='Yearly income (before taxes)'
          dataCY={'price_range_dropdown'}
        />
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default Income;
