import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Input from 'components/global/input';
import Script from 'react-load-script';
import { setField, setHomeValField } from '../../store';

const propTypes = {
  onFieldStatus: PropTypes.func,
  setField: PropTypes.func,
  enabledField: PropTypes.bool,
  usedAutocomplete: PropTypes.func,
  address: PropTypes.string,
  placeholder: PropTypes.string,
  geoType: PropTypes.array,
  isHomeVal: PropTypes.bool,
};

const autoCompleteInputs = document.getElementsByClassName("autocomplete");

const getAddressComponent = (place, componentName, property) => {
  if (!place || !place.address_components) return null;
  var comps = place.address_components.filter(function(component) {
    return component.types.indexOf(componentName) !== -1;
  });
  if(comps && comps.length && comps[0] && comps[0][property]) {
    return comps[0][property]; 
  }
  return null;
};

const GoogleAutocomplete = (props) => {
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  const dispatchSetField = (field,value) => {
    if (props.isHomeVal) {
      dispatch(setHomeValField(field,value));
    } else {
      dispatch(setField(field,value));
    }
  }

  const handleScriptLoaded = () => setLoaded(true);
  const updateField = (field) => (e) => {
    props.setField(field, e.target.value);
    props.onFieldStatus(true);
  };

  useEffect(() => {
    //required for maps API callback
    window.initMap = () => {};

    if (loaded) {
      const options = {
        types: props.geoType || ['geocode'], //types: ['(regions)']
        componentRestrictions: {country: 'us'}
      };

      for (var i = 0; i < autoCompleteInputs.length; i++) {

        let autocomplete = new google.maps.places.Autocomplete(
          autoCompleteInputs[i],
          options
        );

        autocomplete.setFields(['address_components', 'formatted_address']);
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          const streetNumber = getAddressComponent(place, 'street_number', 'long_name');
          const streetName = getAddressComponent(place, 'route', 'long_name');
          const street = streetNumber && streetName ? `${streetNumber} ${streetName}` : null;
          const city = getAddressComponent(place, 'locality', 'long_name');
          const state = getAddressComponent(place, 'administrative_area_level_1', 'short_name');
          const postalCode = getAddressComponent(place, 'postal_code', 'short_name');
          const addressFull = `${(street ? (street+', ') : '')}${(city ? (city+', ') : '')}${(state ? (state+', ') : '')}`;
          if (place) {
            props.usedAutocomplete(true);
          }
          
          props.setField('address', addressFull ? `${addressFull}USA` : '');
          props.setField('street', street);
          props.setField('city', city);
          props.setField('state', state);
          props.setField('postalCode', postalCode);

          //todo, temp fix to update the store, need to redo this
          dispatchSetField('address', addressFull ? `${addressFull}USA` : '');
          dispatchSetField('street', street);
          dispatchSetField('city', city);
          dispatchSetField('state', state);
          dispatchSetField('postalCode', postalCode);
          
        });
      }
    }
  },[loaded]);

  return (
    <React.Fragment>
      <Input
        dataCY={'enter_address'}
        autoComplete="off"
        value={props.address}
        onChange={updateField('address')}
        placeholder={props.placeholder}
        id="input_for_address"
        name="input_for_address"
        className= {props.enabledField ? 'autocomplete' : 'input--invalid'}
      />
      <Script
        url={`//maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_KEY}&libraries=places&callback=initMap`}
        onLoad={handleScriptLoaded}
      />
    </React.Fragment>
  )
};

GoogleAutocomplete.propTypes = propTypes;

export default GoogleAutocomplete;
