import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import { ANSWERS } from 'lib/constants/funnelMeta';

const BUTTONS = [
  { text: 'Yes', value: ANSWERS.OTHERAGENT.YES },
  { text: 'No', value: ANSWERS.OTHERAGENT.NO },
  { text: 'I\'m already working with one', value: ANSWERS.OTHERAGENT.HASONE },
];

const Connect = ({ fields = {}, setField, nextStep, previousStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: BUTTON_COLORS.CTA.DEFAULT,
    ACTIVE: BUTTON_COLORS.CTA.DARK_ROSE,
  }

  const onBack = () => { previousStep(); setField('otherAgent', null); };
  const { otherAgent = null } = fields;
  const setActiveButton = (value) => () => {
    setField('otherAgent', value);
    nextStep();
  };

  return (
    <div className='container container--normal ta-center rebranding'>
      <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: 'Would you like us to connect you to a real estate agent?'
          }}
        />
      </div>
      <div className='container container--narrow'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={otherAgent === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'property-button'}
            dataCY={'property_button'}
          />
        )}
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
          <div className='disclaimer'>
            An agent will be able to connect you to even more programs and grants that will help eliminate the burden of a high down payment.
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Connect;
