import React from 'react';

const SvgBuy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="53" viewBox="0 0 65 53" fill="none">
      <path d="M31.8295 52.4655C45.3729 52.4655 56.3519 41.4864 56.3519 27.9431C56.3519 14.3998 45.3729 3.42078 31.8295 3.42078C18.2862 3.42078 7.30719 14.3998 7.30719 27.9431C7.30719 41.4864 18.2862 52.4655 31.8295 52.4655Z" fill="#CFD3F9"/>
      <path d="M25.2281 1.53442H21.4554V10.9661H25.2281V1.53442Z" fill="white" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M31.8292 5.30713L20.5112 14.7388V30.7726H43.1472V14.7388L31.8292 5.30713Z" fill="white"/>
      <path d="M34.6599 21.3409H29.0009V29.8294H34.6599V21.3409Z" fill="#D88E70" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M42.9317 28.2449L37.3858 30.8763L37.084 30.9895C36.6879 30.2915 36.0182 29.8294 35.0845 29.8294H29.4255C28.1334 29.8294 26.1527 27.9431 24.5965 27.9431H19.0507C16.7682 27.9431 14.731 29.8105 13.5897 30.8763C11.8166 32.6495 8.2514 36.2335 8.2514 36.2335L16.9191 44.9106C18.3339 43.4959 20.0033 42.0906 21.4557 42.0906H32.7738C34.5375 42.0906 36.3012 41.5435 37.5462 40.2986L46.9307 30.914L49.5999 24.2458C47.9776 22.6236 44.2615 24.359 42.9317 28.2449Z" fill="white"/>
      <path d="M25.2284 35.4884H35.0845C36.6502 35.4884 37.4896 34.2623 37.4896 32.6966V32.6778C37.4896 31.1121 36.6502 29.8294 35.0845 29.8294H29.4255C28.1334 29.8294 26.1527 27.9431 24.5965 27.9431H19.0507C16.7682 27.9431 14.731 29.8105 13.5897 30.8763C11.8166 32.6495 8.2514 36.2335 8.2514 36.2335L16.9191 44.9106C18.3339 43.4959 20.0033 42.0906 21.4557 42.0906H32.7738C34.5375 42.0906 36.3012 41.5435 37.5462 40.2986L46.9307 30.914L49.5999 24.2458C47.9776 22.6236 44.2615 24.359 42.9317 28.2449L37.3858 30.8763" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M7.31071 33.5969L1.64856 39.259L13.9066 51.517L19.5687 45.8549L7.31071 33.5969Z" fill="#D88E70" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M8.76957 40.6286L6.88324 38.7423" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M43.1475 14.7388V27.9431" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M20.5112 27.9431V14.7388" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M31.8296 1.53442L14.8526 15.6819L17.6821 18.5114L31.8296 7.19343L45.9771 18.5114L48.8066 15.6819L31.8296 1.53442Z" fill="#D88E70" stroke="#434CA0" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  );
};

export default SvgBuy;