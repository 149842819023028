import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import { BUTTON_COLORS } from 'lib/constants/colors';
import validator from 'validator';
import { ANSWERS } from 'lib/constants/funnelMeta';
import { FUNNEL } from 'lib/constants/translations';

const NameMaster = (props = {}) => {
  const {fields = {}, setField, nextStep } = props;
  const {name = '', otherAgent, isSpanishFunnel} = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const updateField = (field) => (e) => {
    onNameFieldStatus(true);
    setField(field, e.target.value);
  }

  const isNameValid = (name) => {
    return (validator.isAlpha(name, 'en-US', { ignore: ' -' }));
  }
  
  const onSubmit = () => {
    if (name && isNameValid(name)) {
      onNameFieldStatus(true);
      nextStep();
    } else {
      onNameFieldStatus(false);
    }
  };

  const [nameField, setNameField] = useState(true);

  const onNameFieldStatus = (state) => {
    setNameField(state);
  }
  
  return (
    <div className='form-wrapper'>
      {
        otherAgent === ANSWERS.OTHERAGENT.YES &&
        <span className="form-subtitle">{FUNNEL.name.subTitle[language]}</span>
      }
      <h2 className='form-title'>{FUNNEL.name.question[language]}</h2>
      <div>
        <Input
          value={name}
          onChange={updateField('name')}
          placeholder={FUNNEL.name.placeholder[language]}
          dataCY={'full_name'}
          className={nameField ? null : 'input--invalid'}
        />
      </div>
      <Button
        text={FUNNEL.name.nextButton[language]}
        color={BUTTON_COLORS.CTA.DEFAULT}
        onClick={onSubmit}
        dataCY={'search_cta'}
      />
    </div>
  );
};

export default NameMaster;
