// The values have to match dataLayer, otherwise tracking will break
export const FORM = {
  AGENTFINDER: 'agentfindermain',
  CALLMEBACK: 'callmeback',
  SHORTSALE: 'shortsale',
  CONTACT: 'contact',
  MAFSELLER: 'maf-seller',
  MAFBUYER: 'maf-buyer',
  GRANTSANDPROGRAMS: 'grants_programs',
  HOMEVAL: 'homeval',
};

export const ANSWERS = {

  TIMEFRAME: {
    Months_0_3: '0 - 3 Months',
    Months_3_6: '3 - 6 Months',
    Months_6_12: '6 - 12 Months',
    Months_12: 'Over 1 Year',
  },

  STATUS: {
    YES: 'Pre-Approved',
    NO: 'Haven\'t Applied Yet',
    INPROCESS: 'I am in the process of getting pre-approved',
  },

  OTHERAGENT : {
    YES: 'Connect with an agent',
    NO: 'Declined',
    HASONE: 'Already has an agent'
  },

}

//Used to sync WP slugs with React paths to clear the local storage
export const SLUG = {
  AGENTFINDER: 'agent-finder',
  SHORTSALE: 'short-sale',
  LENDERMATCH: 'lender-match',
  SELLER: 'sell-your-home',
  BUYER: 'first-time-home-buyer',
  FUNNEL_COMPLETED: 'complete',
  VALUATION: 'valuation',
  CONTACT: 'contact',
  GRANTS: 'grants-and-programs',
  HOMELOANS: 'home-loan',
  HOMEVAL: 'home-valuation',
  HOMEVALREPORT: 'home-valuation-report',
};


//Used to toggle body classes
export const CLASS = {
  AGENTFINDER: {
    DEFAULT: 'funnel-maf',
    ACTIVE: 'funnel-maf__active',
    COMPLETED: 'funnel-maf__completed',
  },
  SHORTSALE: {
    DEFAULT: 'funnel-short-sale',
    ACTIVE: 'funnel-short-sale__active',
    COMPLETED: 'funnel-short-sale__completed',
  },
  LENDERMATCH: {
    DEFAULT: 'funnel-lender-match',
  },
  MASTERFUNNEL: {
    DEFAULT: 'funnel-master',
  }
};

export const SOURCE = {
  MAF_SHORT_ADDRESS: 'MAF Short Address',
  PROGRAMS: 'HAM Programs',
  HAM_VERIFIED: 'HAM Verified',
  HAM_INFLUENCERS: 'HAM Influencers',
  HOMEVAL: 'HAM Home Value',
  HAM_AGENT_PROFILE: 'HAM Agent Profile',
}

export const PROGRAMS = {
  REBATE: {
    ID: 'rebate',
    NAME: 'MyAgentFinder Buyer Rebate',
    TITLE: 'Do you want to receive up to $1,000 cash back after closing?',
    CONTENT: 'Our friends at MyAgentFinder by Home & Money are willing to pay you cash just by using their service.  If you\'re in an eligible state and utilize a buyer\'s agent that MyAgentFinder refers to purchase your home, you could be eligible for a cash rebate based on the sale price.'
  },
  FHA: {
    ID: 'fha',
    NAME: 'FHA Loan',
    TITLE: 'Worried about whether or not a low credit score will hold you back from getting a home?',
    CONTENT: 'That\'s where an FHA Loan can help! This government-backed mortgage loan is specifically for people who have been experiencing financial hardship. Whether you have a low credit score, debt, or have even filed for bankruptcy, an FHA Loan can help you finally become a homeowner.'
  }
  ,
  HOMEONE: {
    ID: 'homeone',
    NAME: 'HomeOne Loan',
    TITLE: 'Want to buy your own home but don\'t think you can afford it? HomeOne will make it possible!',
    CONTENT: 'HomeOne Loans were created for first-time homebuyers that don\'t have a lot of disposable income. It makes buying a home accessible by lowering the necessary downpayment so that homeownership can be within reach. So if you\'re needing a bit of help with funding, HomeOne Loans may be the perfect option!'
  },
  VA: {
    ID: 'va',
    NAME: 'VA Loan',
    TITLE: 'If you\'re a qualified U.S Veteran, active-duty military personnel, or a surviving spouse of military personnel, then this program will make it much easier to buy a house. ',
    CONTENT: 'That\'s because the VA Loan program was made specifically for you to enjoy your civilian life. With this loan, you could potentially eliminate the need to make a down payment. Making purchasing your home easier than ever!'
  },
  LTV: {
    ID: 'ltv',
    NAME: '97% LTV Loan',
    TITLE: 'If you\'re a first-time home buyer wanting to make the smallest down payment possible, keep reading. ',
    CONTENT: 'The 97% LTV loan may be your best bet. With this loan, all that is required is a 3% downpayment (instead of the typical 20%). So if you want to keep even more money in your pocket, this may be the perfect fit!'
  }
}
