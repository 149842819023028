import React, { useEffect } from 'react';
import CTA from './cta';
import AccordionWrapper from './accordion1Master';
import { ANSWERS } from 'lib/constants/funnelMeta';
import { FUNNEL } from 'lib/constants/translations';

const Completed1Master = (props = {}) => {

  const { fields = {} } = props; 

  const { militaryVeteran, otherAgent, firstHome, state, name, email, phone, isSpanishFunnel } = fields;

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';


  useEffect(() => {
    const element = document.getElementById('sgi');
    const container = document.getElementById('pasteCalculator');
    const copyCalculator = document.getElementById('copyCalculator');

    if (element && container && copyCalculator) {
      container.appendChild(element);
      copyCalculator.parentNode.removeChild(copyCalculator);
    }
  }, []);

  const ctaProps = { name, email, phone, state, isSpanishFunnel };

  return (
    <>
      <div className='form-wrapper completed1master'>
        <h2 className='form-title'>{FUNNEL.completed1Master.stepDone[language]}</h2>
        {otherAgent === ANSWERS.OTHERAGENT.YES ?
          <ul className='form-list'>
            <li className='form-list__item'>{FUNNEL.completed1Master.searchingDatabase[language].text1}</li>
            <li className='form-list__item' dangerouslySetInnerHTML={{__html: FUNNEL.completed1Master.searchingDatabase[language].text2}}></li>
          </ul>
          :
          <ul className='form-list'>
            <li className='form-list__item'>{FUNNEL.completed1Master.buildingPortfolio[language].text1}</li>
            <li className='form-list__item'>{FUNNEL.completed1Master.buildingPortfolio[language].text2}</li>
          </ul>
        }

        <CTA
          {...ctaProps}
          title={FUNNEL.completed1Master.financeWhileWait[language]}
        />



        {!isSpanishFunnel &&
          <div className='accordion_wrapper'>
            <h2 className='form-title'>Here are some programs you're eligible for:</h2>
            <AccordionWrapper
              isMilitary={militaryVeteran}
              firstHome={firstHome}
              state={state}
            />
          </div>
        }
        {!isSpanishFunnel &&
          <h2 className='form-title'>See how much house you can afford.</h2>
        }
      </div>
      {!isSpanishFunnel &&
        <div id='pasteCalculator' className='calculator'>
          
        </div>
      }

      {!isSpanishFunnel &&
        <div className='form-wrapper'>
          <CTA
            {...ctaProps}
            title={'Finance Your Home'}
          />
        </div>
      }
    </>
  )
};

export default Completed1Master;
