import React from 'react';
import Spinner from 'components/global/spinner';
import mrcLogo from 'images/logos/mrc_logo.png';

const ModalWrapper = () => {

  return (
    <div className='modal-wrapper'>
      <div className="dialog">
        <img className="logo" src={mrcLogo}></img>
        <div className="header">
          <span className="title">Hang Tight!</span>
          <span className='subtitle'>We're redirecting you to get pre-approved.</span>
        </div>
        <Spinner />
        <p>In the meantime, our team is searching through our database of over 40,000 realtors to find your perfect agent match.</p>
      </div>
    </div>
  );
};

export default ModalWrapper;