export const tempAgentInfo = [
  {
    "sfid": "0033a00002ev7BbAAI",
    "firstname": "Christina",
    "lastname": "Cardenas",
    "profile_image": "https://s3.amazonaws.com/images.maf.com/files/agents/pictures/d46e105a-e331-11eb-b437-f566e56c5f8c/profile/christina-cardenas.jpg",
    "company_name": "Vision Real Estate",
    "zillow": "https://www.zillow.com/profile/Christileetx",
    "youtube": "https://www.youtube.com/watch?v=StzDM74sXcI&list=PLFUMTcM5WcY2Tp5NAzZhs21oJw6GxkN6M",
    "facebook_url": "https://www.facebook.com/DreamHomeVibes,",
    "linkedin_url": "https://www.linkedin.com/in/realtorsanantonio/",
    "yelp": null,
    "twitter_url": null,
    "instagram_url": null,
    "pinterest_url": null,
    "description": "Hello! I am Christina Cardenas, full-time licensed Realtor® and the owner of Dream Home Vibes LLC, brokered by: Vision Real Estate. We are a family-owned boutique brokerage servicing San Antonio and the surrounding areas, with branch offices in Houston and McAllen, Texas. I am a native of San Antonio, Texas. Since licensed in June 2021 I have proudly been able to help over 100 clients successfully achieve their real estate goals, equating to over 27.5 million dollars in sales volume. I have experience not only in the real estate industry but also in service industry management positions in areas such as moving services, HVAC, plumbing, home handyman repairs, and solar energy. I look forward to helping you achieve your individual goals with my expertise to guide you along the way. Contact me today to schedule your no-obligation consultation!",
    "email": "christina@dreamhomevibes.com",
    "main_phone": "(210) 213-8409",
    "website": "http://www.dreamhomevibes.com",
    "street": "116 Sunflower Lane",
    "city": "San Antonio",
    "state": "Texas",
    "postalcode": 78213,
    "transactions": 129,
    "experience_years": 3.5,
    "num_sold_opps": 31,
    "service_area": "Bexar County, Wilson County, Atascosa County, Medina County"
  },
  {
    "sfid": "0033a00002gCh4KAAS",
    "firstname": "Jennifer",
    "lastname": "Hemstrom",
    "profile_image": "https://s3.amazonaws.com/images.maf.com/files/agents/pictures/9b4791d2-2622-11ec-b285-cdb32eecb797/profile/jennifer-genord.jpg",
    "company_name": "Thrive Realty Company",
    "zillow": "",
    "youtube": "",
    "facebook_url": "https://www.facebook.com/lillyjen24",
    "linkedin_url": "",
    "yelp": null,
    "twitter_url": null,
    "instagram_url": null,
    "pinterest_url": null,
    "description": "As a lifetime real estate agent I bring a wealth of experience to the dynamic team at Thrive Realty Co. With a proven track record in successfully navigating the intricate world of real estate, I have earned a reputation for being a reliable and dedicated agent, providing unparalleled service to clients in various real estate transactions.\n\n\nSpecializing in residential properties, I understand of the diverse needs and preferences of the homebuyers and sellers I work with. Whether it's guiding first-time homebuyers through the complex purchasing process or effectively marketing and negotiating on behalf of sellers, I strive to ensure a seamless and satisfactory experience for all parties involved.\n\n\nBeyond residential real estate, I spent a decade in the Commercial Real Estate industry. As a former business owner I possesses a keen understanding of the unique demands of businesses seeking the perfect space.\n\n\nRecognizing the importance of clear communication, I place a strong emphasis on keeping clients informed at every stage of the process. I know first hand well informed clients make the best decisions.\n\n\nAs a veteran member of the Thrive Realty Co team, I am committed to upholding the company's reputation for excellence in service. Thrive Realty Co's collaborative and forward-thinking approach aligns seamlessly with my values, ensuring clients receive the highest level of professional support throughout their real estate journey.",
    "email": "jennifer@thriverealtyco.com",
    "main_phone": "(248) 256-5046",
    "website": "https://thriverealtyco.com/team/jennifer-hemstrom",
    "street": "880 W. Long Lake Rd, STE 175",
    "city": "Troy",
    "state": "Michigan",
    "postalcode": 48098,
    "transactions": "100+",
    "experience_years": 8,
    "num_sold_opps": 22,
    "service_area": "Oakland County, Wayne County, Livingston County, Macomb County, Washtenaw County, Howell"
  },
  {
    "sfid": "0033a00002lhy9RAAQ",
    "firstname": "Jennifer",
    "lastname": "Gotlewski",
    "profile_image": "https://s3.amazonaws.com/images.maf.com/files/agents/pictures/3e29f62e-2a6e-11ed-973a-d8561546acf6/profile/jennifer-gotlewski.jpg",
    "company_name": "Olympus Executive Realty",
    "zillow": "https://www.zillow.com/profile/flrealestatejen#reviews",
    "description": "I love helping the buyer obtain their dream of being a homeowner. Knowing that I provide the best resources and best lending partners to allow them to buy their home with comfortably affording it",
    "email": "flrealestatejen@gmail.com",
    "main_phone": "(352) 988-9210",
    "street": "16903 Lakeside Drive, #6,",
    "city": "Montverde",
    "state": "Florida",
    "postalcode": 34756,
    "num_sold_opps": 15,
    "experience_years": 9,
    "transactions": "50-100",
    "service_area": "Lake County,\nPolk County,\nMarion County,\nSumter County,\nOsceola County,\nOrange County,\nSeminole County,\nVolusia County,\nBrevard County"
  },
  {
    "sfid": "0033a00002qn2PKAAY",
    "firstname": "Sheana",
    "lastname": "Evans",
    "profile_image": "https://lirp.cdn-website.com/5c18f7ba/dms3rep/multi/opt/Untitled+design+%282%29-193w.jpg",
    "company_name": "Keller Williams Coastal Area Partners",
    "zillow": "https://www.zillow.com/profile/sheanaevanskw",
    "description": "With a passion for serving the Southeast Geogia area, Sheana Evans is ready to help with your homebuying and selling needs. As a proud member of the 1st Class Real Estate family, Sheana Evans carries the values of hard work, integrity, and outstanding client service into everything she does.\n\nWhen Sheana Evans isn’t making homeownership dreams come true for her clients, she enjoys spending time riding Atvs and Spending time with her family. Sheana Evans lives in Hinesville with her husb",
    "email": "sheanaevans@kw.com",
    "main_phone": "(386) 983-4464",
    "website": "https://www.sheanaevans.realtor/",
    "street": "3111 John Wells Rd",
    "city": "Hinesville",
    "state": "Georgia",
    "postalcode": 31313,
    "transactions": 96,
    "experience_years": 6,
    "num_sold_opps": 11,
    "service_area": "Appling, Bryan, Bulloch, Charlton, Chatham, Effingham, Emanuel, Glynn, Jeff Davis, Liberty, Long, McIntosh, Pierce, Screven Telfair, Toombs, Ware, Wayne"
  },
  {
    "sfid": "003j0000015uFZRAA2",
    "firstname": "Troy",
    "lastname": "Sanders",
    "profile_image": "https://s3.amazonaws.com/images.maf.com/files/agents/pictures/32cb9ea3-81a9-4659-84e2-9da5b36ab777/profile/troy-sanders.jpg",
    "company_name": "Better Homes and Gardens",
    "zillow": "https://www.zillow.com/profile/troyrsanders1",
    "description": "Not all real estate experiences are the same…\n\nIf you're looking for a 1 - on - 1 real estate experience, you have found the right realtor. I pride myself on being available for my clients and being a realtor who you can depend on throughout the entire process of buying or selling a home.  I'm the realtor who will meet with you personally from the first initial consultation/showing and be there for you all the way throughout the transaction to the closing table.  My passion is to constantly take care of my clients so that they think I'm their only customer.\n\nI'm here to give you my Full Attention and not pass you along to other team members or assistances along the way. I want you to feel like you're a Top Priority when working with me.\n\nMy believe is that Everyone Can Enjoy the process of Buying/Selling Real Estate in any market condition by being a good adviser, listener and problem solver.",
    "email": "troyrsanders@gmail.com",
    "main_phone": "(402) 750-0608",
    "website": "https://betteromaha.com",
    "street": "1210 N 205th Street",
    "city": "Elkhorn",
    "state": "Nebraska",
    "postalcode": 68022,
    "transactions": 1178,
    "num_sold_opps": 64,
    "experience_years": 22,
    "service_area": "Douglas County, Sarpy County, Cass County, Saunders County, Washington County, Lancaster County, Dodge County"
  },
  {
    "sfid": "003Rl000001ynTXIAY",
    "firstname": "Brian",
    "lastname": "Jonczy",
    "profile_image": "https://photos.zillowstatic.com/p_m/IS3zrbbkidgy4l1000000000.jpg",
    "company_name": "Engel & Volkers First Coast",
    "zillow": "https://www.zillow.com/profile/brian62952",
    "facebook_url": "https://www.facebook.com/profile.php?id=100076215289594",
    "description": "More than just an expert real estate agent, I’m a true partner with my clients advising them throughout the home buying and selling journey to find the best opportunity for them. I’m a real estate advisor who believes being kind and patient is just as important as my integrity and professionalism. Whether you’re a first time home buyer, luxury client or savvy investor looking to make your next strategic move - I’m the trusted real estate advisor you’ve been looking for.",
    "email": "brian.jonczy@evrealestate.com",
    "main_phone": "(813) 451-7877",
    "website": "https://brianjonczy.evrealestate.com/",
    "street": "160 Shops Blvd, Ste 10",
    "city": "St Johns",
    "state": "Florida",
    "postalcode": 32259,
    "transactions": 32,
    "experience_years": 3,
    "num_sold_opps": 5,
    "service_area": "Clay County, Duval County, Flagler County, Nassau County, St Johns County"
  },
  {
    "sfid": "003j000001MZYIuAAP",
    "firstname": "Cody",
    "lastname": "McMillian",
    "profile_image": "https://photos.zillowstatic.com/p_m/ISyv6iu381z4a21000000000.jpg",
    "company_name": "Vision Real Estate",
    "zillow": "http://www.zillow.com/profile/codym39",
    "facebook_url": "https://www.facebook.com/McMillian-Realty-605500159621371/",
    "linkedin_url": "https://www.linkedin.com/in/cody-mcmillian-ba998335",
    "description": "I was born and raised here in Houston and have been a full-time realtor in the market for over 10 years. Over that time I have seen all different kinds of markets, and have closed all different kinds of deals. The constant through it all is that I always make it my top priority to treat my clients with honesty, professionalism and respect. I will always do my best to get them the best deal possible.\n\n\nBefore becoming a realtor I earned degrees in Economics and Entrepreneurship from Baylor University. I then worked as a general contractor for 2 years before getting my realtor license. My time as a GC honed my knowledge of construction and also negotiating skills. My degrees help me analyze markets and establish property values, which is extremely valuable in getting a good deal. I have 3 young children as my motivation to do my best for my clients and earn their continued business.",
    "email": "codyvre@gmail.com",
    "main_phone": "(713) 301-7111",
    "website": "http://vretexas.com",
    "street": "10202 Dude Rd",
    "city": "Houston",
    "state": "Texas",
    "postalcode": 77064,
    "transactions": "25-50",
    "experience_years": 10,
    "num_sold_opps": 51,
    "service_area": "Brazoria County (Partial - Friendswood, Pearland), Fort Bend County (Partial - Sugar Land, Katy, Richmond), Harris County (Keep south and west of the 99. I.E. Cypress, Houston, Katy)"
  },
  {
    "sfid": "003Rl0000026VRNIA2",
    "firstname": "Monique",
    "lastname": "Castro",
    "profile_image": "https://lh3.googleusercontent.com/p/AF1QipO5BFrpkogk60rEMA8JGmQmlbmIJMtPTQznK1Kj=w768-h768-n-o-v1",
    "company_name": "Third Coast",
    "zillow": "http://www.zillow.com/profile/monique2680",
    "description": "Since 2017, I've thrived as a dedicated real estate agent, bringing a unique perspective with my background in mortgages. Passionate about assisting buyers, sellers, and investors, I blend market expertise with a commitment to achieving optimal outcomes for my clients.",
    "email": "monique@thirdcoast.team",
    "main_phone": "(714) 914-0450",
    "website": "https://www.har.com/web/moniquencastro",
    "street": "One Riverway",
    "city": "Houston",
    "state": "Texas",
    "postalcode": 77056,
    "transactions": 90,
    "experience_years": 6,
    "num_sold_opps": 1,
    "service_area": "Brazoria, Fort- Bend, Cypress, Fulshear, Houston, Katy, Missouri City, Pearland, Richmond, Spring, Sugarland"
  }
];