import React, { useEffect, useState } from 'react';
import Input from 'components/global/input';
import Button from 'components/global/button';
import Dropdown from 'components/global/dropdown';
import { BUTTON_COLORS } from 'lib/constants/colors';
import {useSelector, useDispatch} from 'react-redux';
import {getShortsaleForm, setShortsaleComplete, setShortsaleField, submitForm, updateParams} from 'store/index';
import { maskValue } from 'utils/mask';
import { getExperimentID } from 'lib/trackingParams';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import Script from 'react-load-script';
import { FORM, SLUG } from 'lib/constants/funnelMeta';
import { sendToDataLayer } from 'lib/logGTM';

const addressInput = React.createRef();

const getAddressComponent = (place, componentName, property) => {
  if (!place || !place.address_components) return null;
  var comps = place.address_components.filter(function(component) {
    return component.types.indexOf(componentName) !== -1;
  });
  if(comps && comps.length && comps[0] && comps[0][property]) return comps[0][property];
  return null;
};

const ShortSaleForm = () => {

  const { fields = {} } = useSelector(getShortsaleForm);
  const {
    name = '',
    phone = '',
    email = '',
    address = '',
    searchType,
  } = fields;

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [isAutocomplete, setIsAutocomplete] = useState(false);

  const handleScriptLoaded = () => setLoaded(true);

  const dispatch = useDispatch();
  
  const updateField = (field, value) => (e) => {
    if (!value) {
      value = e.target.value;
    }
    dispatch(setShortsaleField(field, value));
  }
  const updatePhone = (e) => dispatch(setShortsaleField('phone',maskValue(e)));

  const captureSubmit = (event) => {
    event.preventDefault();
    return false;
  };

  useEffect(() => {
    //required for maps API callback
    window.initMap = () => {};
    
    if (loaded) {
      const options = {
        types: ['geocode'],
        componentRestrictions: {country: 'us'}
      };

      let autocomplete = new google.maps.places.Autocomplete(
        addressInput.current,
        options,
      );

      autocomplete.setFields(['address_components', 'formatted_address']);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const streetNumber = getAddressComponent(place, 'street_number', 'long_name');
        const streetName = getAddressComponent(place, 'route', 'long_name');
        const street = streetNumber && streetName ? `${streetNumber} ${streetName}` : null;
        const city = getAddressComponent(place, 'locality', 'long_name');
        const state = getAddressComponent(place, 'administrative_area_level_1', 'short_name');
        const postalCode = getAddressComponent(place, 'postal_code', 'short_name');
        const addressFull = `${(street ? (street+', ') : '')}${(city ? (city+', ') : '')}${(state ? (state+', ') : '')}`;

        if (place) {
          setIsAutocomplete(true)
        }

        dispatch(setShortsaleField('address', addressFull ? `${addressFull}USA` : ''));
        dispatch(setShortsaleField('street',street));
        dispatch(setShortsaleField('city', city));
        dispatch(setShortsaleField('state', state));
        dispatch(setShortsaleField('postalCode', postalCode));
      });
    }
  },[loaded]);

  // validate the fields
  const [errors, setError] = useState({
    fields: {
      isNameValid: true,
      isEmailValid: true,
      isPhoneValid: true,
      isAddressValid: true,
      isSearchTypeValid: true,
    }
  });

  const validateFields = () => {
    
    setError({...errors, fields: {
      isNameValid: name,
      isPhoneValid: phone && (phone.toString()).length === 14,
      isEmailValid: email && validator.isEmail(email),
      isAddressValid: address,
      isSearchTypeValid: searchType !== "" ,
    }});

    return name && phone && email && address && searchType;
  }

  const onSubmit = () => {

    if (validateFields()) {
      checkFields();
      // send Optimize cookie value to Salesforce
      dispatch(updateParams(getExperimentID()));
      dispatch(setShortsaleComplete(true));
      dispatch(submitForm(FORM.SHORTSALE));
      setLoading(false);

      sendToDataLayer({
        event: 'funnel_completed_shortsale',
        formName: FORM.SHORTSALE,
        fields
      });

      navigate('?step=' + SLUG.FUNNEL_COMPLETED);
    }
  };

  const checkFields = () => {
    if (!isAutocomplete) {
      dispatch(setShortsaleField('street', address));
      dispatch(setShortsaleField('city', ''));
      dispatch(setShortsaleField('state', ''));
      dispatch(setShortsaleField('postalCode', ''));
    }
  }

  return (
    <div className="split-wrapper__item split-wrapper__item--form">
      <form onSubmit={captureSubmit} className='flex input-wrapper ta-center space-between'>
        <div className='field field--wide'>
          <Input 
            value={name} 
            name='fullname' 
            onChange={updateField('name')} 
            placeholder='Name'
            className= {errors.fields.isNameValid ? null : 'input--invalid'}
          />
        </div>
        <div className='field field--wide'>
          <Input 
            value={email} 
            name='email' 
            onChange={updateField('email')} 
            placeholder='Email'
            className= {errors.fields.isEmailValid ? null : 'input--invalid'}
          />
        </div>
        <div className='field field--wide'>
          <Input 
            value={phone} 
            name='phone' 
            onChange={updatePhone} 
            placeholder='Phone'
            className= {errors.fields.isPhoneValid ? null : 'input--invalid'}
          />
        </div>
        <div className='field field--wide'>
          <Dropdown
            value={searchType}
            options={[
              {value: '', text: `I am a..`, default: true },
              {value: `SELLER`, text: `Seller`},
              {value: `BUYER`, text: `Buyer`},
            ]}
            onChange={updateField('searchType')}
            placeholder='Search Type'
            dataCY={'searchType'}
            className= {errors.fields.isSearchTypeValid ? null : 'input--invalid'}
          />
        </div>
        <div className='field field--wide'>
          <Input
            returnRef={addressInput}
            autoComplete="off"
            value={address}
            onChange={updateField('address')}
            name='street'
            placeholder='Property Address'
            className= {errors.fields.isAddressValid ? null : 'input--invalid'}
          />
        </div>
      </form>
      <div className='submit-wrapper'>
        <Button
          text={loading ? 'Submitting...' : 'Find an Agent'}
          onClick={onSubmit}
          color={BUTTON_COLORS.CTA.DARKEN_PEACH}
          classes='short-sale-cta'
        />
      </div>
      <Script
        url={`//maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_KEY}&libraries=places&callback=initMap`}
        onLoad={handleScriptLoaded}
      />
    </div>
  );
};

export default ShortSaleForm;
