import React, { useEffect, memo } from 'react';
import * as nprogress from 'nprogress';
import { useNavigate, Link } from 'react-router-dom';

const ProgressBar = () => (
  <div className='progress-bar'>
    <div className='overlap-group'>
      <span className='progress-line' alt='Bar'></span>
    </div>
  </div>
);

const BackButton = ({onClick}) => (
  <Link to='#' onClick={onClick} className='back'>
    <span className='back-button-chevron'></span>
  </Link>
);

const Header = memo(() => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    nprogress.configure({
      minimum: 0.0,
      trickle: false,
      showSpinner: false,
      parent: '.progress-line',
    });
  }, []);

  return (
    <header className='header'>
      <div className='nav-logo'>
        <BackButton onClick={handleClick} />
        <span className='hamlogo' alt='Home&Money Logo'></span>
      </div>
      <ProgressBar />
      <div className='overlap-group'>
        <span className='avatar'></span>
      </div>
    </header>
  );
});

export default Header;
