import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import AutoComplete from 'components/global/googleAutocomplete';
import { BUTTON_COLORS } from 'lib/constants/colors';
import validator from 'validator';
import { FUNNEL } from 'lib/constants/translations';

const NameAddressMaster = (props = {}) => {
  const {fields = {}, setField, nextStep } = props;
  const {name = '', address = '', isSpanishFunnel} = fields;

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const updateField = (field) => (e) => {
    onNameFieldStatus(true);
    setField(field, e.target.value);
  }

  const isNameValid = (name) => {
    return (validator.isAlpha(name, 'en-US', {
      ignore: ' -'
    }));
  }
  
  const onSubmit = () => {
    if (name && isNameValid(name)) {
      onNameFieldStatus(true);
    } else {
      onNameFieldStatus(false);
    }
    if (address) {
      checkFields();
      onAddressFieldStatus(true);
    } else {
      onAddressFieldStatus(false);
    }

    if (name && isNameValid(name) && address) {
      nextStep();
    }
  };
  
  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [addressField, setAddressField] = useState(true);
  const [nameField, setNameField] = useState(true);

  const onAddressFieldStatus = (state) => {
    setAddressField(state);
  }

  const onNameFieldStatus = (state) => {
    setNameField(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  } 

  const checkFields = () => {
    setField('street', address); 
    setField('city', '');
    setField('state', '');
    setField('postalCode', '');
  }
  
  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{FUNNEL.nameAddressMaster.question[language]}</h2>
      <div>
        <AutoComplete
          address={address}
          placeholder={FUNNEL.nameAddressMaster.placeholders.propertyAddress[language]}
          setField={setField}
          enabledField={addressField}
          onFieldStatus={onAddressFieldStatus}
          checkFields={checkFields}
          usedAutocomplete={usedAutocomplete}
        />
      </div>
      <div>
        <Input
          value={name}
          onChange={updateField('name')}
          placeholder={FUNNEL.nameAddressMaster.placeholders.name[language]}
          dataCY={'full_name'}
          className= { nameField ? null : 'input--invalid'}
        />
      </div>
      <Button
        text={FUNNEL.nameAddressMaster.button[language]}
        color={BUTTON_COLORS.CTA.DEFAULT}
        onClick={onSubmit}
        dataCY={'search_cta'}
      />
    </div>
  );
};

export default NameAddressMaster;