import React from 'react';
import Heading from 'components/global/heading';
import { COLORS } from 'lib/constants/colors';

const OurMission = () => (
  <div className='container container--wide'>
    <div className='container container--normal ta-center p-t-40'>
      <Heading
          loose
          color={COLORS.NAVY}
          headline={{
            copy: <b>Our Mission Is To Make Buying And Selling Homes Easier For You</b>,
            size: 'h4'
          }}
          subHeadingColor={COLORS.DARK_BACKGROUND}
          subHeading={'At HomeAndMoney, quality customer support is our priority! We would be happy to personally assist you in finding your perfect agent, or respond to any questions or concerns you might have. Email us with the form below. Thanks and we look forward to hearing from you!'}
        />
    </div>
  </div>
);

export default OurMission;
