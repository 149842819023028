import React from 'react';
import { abbreviateState } from 'utils/utils.js';

const PropDetails = (props) => {

  const { fields = {} } = props;
  const { propertyPhoto, street, city, state, postalCode, sqft, beds, baths } = fields;

  const propSpecs = [beds + ' bd', baths + ' ba', sqft + ' sqft'];

  return (
    <div className="prop-details">
      {propertyPhoto &&
        <div className="avatar">
          <img
            src={propertyPhoto}
            alt='property photo'
          />
        </div>
      }
      <div className={`prop-wrapper${!propertyPhoto ? ' centered' : ''}`}>
        <span className="street">{street}</span>
        <span className="city">{city}, {abbreviateState(state)} {postalCode}</span>
        <div className="prop-specs">
          {propSpecs.map((spec, index) => (
            <span key={index} className={spec.split(' ')[1]}>
              {spec}
              {index !== propSpecs.length - 1 && <span className="dot-separator"> &middot; </span>}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PropDetails;
