import React from 'react';
import Heading from 'components/global/heading';
import { COLORS } from "lib/constants/colors";;
import IntroLendCTA from "components/global/introLendCTA";

const Content = () => {
  return (
    <div>
      <div className="content-top">
        <Heading
          tight
          color={COLORS.BLACK}
          headline={{
            copy: 'Great news! You\'re Eligible for the Following Programs',
            size: 'h4'
          }}
        />
        <hr className="hr"/>
        <p>We've included the programs below. But if you're ready to see just how much house you're approved for in just 2 minutes, click the link below to get pre-approved today.</p>
        <IntroLendCTA
          text='Get Pre-Approved'
          classes='button--primary'
          displayLogo={true}
        />
      </div>
      <div className="content-bottom">
        <Heading
          tight
          color={COLORS.BLACK}
          headline={{
            copy: 'Here\'s What Happens Next',
            size: 'h4'
          }}
        />
        <hr className="hr"/>
        <ul>
          <li>We'll reach out shortly to get you connected with an agent</li>
          <li>Your agent will be able to match you with even more programs.</li>
        </ul>
      </div>
    </div>
  );
};

export default Content;
