import React, {useEffect} from 'react'
import Reviews from './reviews';
import Engagement from './engagement';
import { addBodyClass } from "utils/utils";

const Testimonials = () => {
  useEffect(() => {
    addBodyClass('reviews');
  },[]);
  return (
    <div>
      <Engagement/>
      <Reviews all={true} />
    </div>
  );
};

export default Testimonials;
