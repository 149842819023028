import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Button from 'components/global/button';
import AutoComplete from 'components/global/googleAutocomplete';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { SLUG } from 'lib/constants/funnelMeta';
import { setField, setHomeValField, setHomeValComplete, getHomeValForm } from '../../../../store';
import { getTitleCase } from 'utils/utils.js'

import services from '../../../../services';

const SearchField = () => {

  const { fields = {} } = useSelector(getHomeValForm);
  const {
    address,
    street,
    postalCode,
  } = fields;

  const dispatch = useDispatch();

  const [isAutocomplete, setIsAutocomplete] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const onFieldStatus = (state) => {
    setEnabled(state);
  }

  const usedAutocomplete = (state) => {
    setIsAutocomplete(state);
  }

  const dispatchSetField = (field, value) => {

    const allowedFields = ['address', 'city', 'street', 'state', 'postalCode', 'county', 'neighborhood', 'priceLow', 'priceHigh', 'priceAverage'];
  
    dispatch(setHomeValField(field, value));
  
    if (allowedFields.includes(field)) {
      dispatch(setField(field, value));
    }
  };
  

  const onSubmit = () => {

    if (address) {
      onFieldStatus(true);
      if (!isAutocomplete) {
        searchManually();
      }
      getHomeValReport(street, postalCode);
    } else {
      onFieldStatus(false);
    }
  };

  const getHomeValReport = async (street, postalCode) => {
    const reportType = 'QVMFull_Data';
    const isTest = false;
  
    try {
      const response = await services.getHomeValuation(street, postalCode, reportType, isTest);
      
      if (response && response.body) {
        if (response.body.result_code == 0) {

          const reportData = response.body.report_data;

          dispatchSetField('reportFound', true);

          // Setting address details
          const propertyAddress = reportData.PropertyAddress;
          dispatchSetField('address', `${getTitleCase(propertyAddress.StreetAddress)}, ${getTitleCase(propertyAddress.City)}, ${propertyAddress.State}`);
          dispatchSetField('street', propertyAddress.StreetAddress);
          dispatchSetField('city', propertyAddress.City);
          dispatchSetField('state', propertyAddress.State);
          dispatchSetField('postalCode', propertyAddress.ZipCode);


          // Setting property details
          const propertyDetails = reportData.PropertyDetails.PropertyBasics;
          dispatchSetField('beds', propertyDetails.Bedrooms);
          dispatchSetField('baths', propertyDetails.FullBaths); 
          dispatchSetField('sqft', propertyDetails.BuildingSqft);

          const propertySummary = reportData.PropertySummary;
          dispatchSetField('propertyPhoto', propertySummary.PropertyPhotoUrl);
          
          // Setting property valuation details
          const propertyValuation = reportData.PropertyValuation;
          dispatchSetField('priceLow', propertyValuation.ValuationRangeLow);
          dispatchSetField('priceHigh', propertyValuation.ValuationRangeHigh);
          dispatchSetField('priceAverage', propertyValuation.EstimatedValue);
          dispatchSetField('valuationHistory', propertyValuation.ValuationHistory);
        } else {
          dispatchSetField('reportFound', false);
        }
      }
      
      //setLoading(false);
      setHomeValComplete(true);
      redirect();
  
      return response.body;
  
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const redirect = () => {
    window.location.href = '/' + SLUG.HOMEVALREPORT;
  }

  const searchManually = () => {
    const service = new google.maps.places.AutocompleteService();

    const options = {
      types: ['address'],
      componentRestrictions: {country: 'us'}
    };

    service.getPlacePredictions({
      input: address,
      options
    }, displaySuggestions);

  };

  const displaySuggestions = (predictions, status) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) {
        onFieldStatus(false);
        return;
    }
    getDetailsManually(predictions[0].place_id);
  };

  const getDetailsManually = async placeId => {
    new Promise((resolve, reject) => {
      if (!placeId) reject("placeId not provided")
      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          details => {
            const address = details.address_components.reduce((seed, { long_name, types }) => {
              types.forEach(t => {
                seed[t] = long_name;
              });
            
              return seed;
            }, {});

            const city = address.locality;
            const state = address.administrative_area_level_1;
            const county = address.administrative_area_level_2;
            const neighborhood = address.neighborhood;
            const postalCode = address.postal_code;
            const addressFull = `${(city ? (city+', ') : '')}${(state ? (state+', ') : '')}`;
            const streetName = address.route;
            const streetNumber = address.street_number;
            const street = streetNumber && streetName ? `${streetNumber} ${streetName}` : null;

            dispatchSetField('address', addressFull ? `${addressFull}USA` : '');
            dispatchSetField('street', street);
            dispatchSetField('city', city);
            dispatchSetField('state', state);
            dispatchSetField('postalCode', postalCode);
            dispatchSetField('county', county);
            dispatchSetField('neighborhood', neighborhood);

            return resolve(details)
          }
        )
      } catch (e) {
        reject(e)
      }
    })
  };

  return (
    <div className='homeval-search'>
      <div className={address ? 'homeval-address' : 'homeval-address required'}>
        <AutoComplete
          geoType={['address']}
          placeholder={'Enter your address'}
          setField={setHomeValField}
          enabledField={enabled}
          onFieldStatus={onFieldStatus}
          usedAutocomplete={usedAutocomplete}
          isHomeVal={true}
        />
        <Button
          text={'Next'}
          color={BUTTON_COLORS.CTA.DEFAULT}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default SearchField;
