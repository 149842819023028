import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import Heading from 'components/global/heading';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';
import { sendToDataLayer } from 'lib/logGTM';
import { getExperimentID } from 'lib/trackingParams';
import validator from 'validator';
import Avatar from 'images/funnel-steps/agent.png';
import { ANSWERS } from 'lib/constants/funnelMeta';



const Email = (props = {}) => {
  const {fields = {}, step, setField, previousStep, nextStep, submitForm, setComplete, updateParams, isBuyerPage, isBuyerPrograms} = props;
  const {email = '', leadEntry, otherAgent} = fields;
  const updateField = (field) => (e) => {
    onEmailFieldStatus(true);
    setField(field, e.target.value);
  }

  let header = 'Where should we email your agent match report?';
  if (isBuyerPage) {
    header = 'What is the best email address to reach you?';
  }
  if (isBuyerPrograms) {
    header = 'What is your email address?';
  }

  const skipPhone = () => {
    return isBuyerPrograms && otherAgent !== ANSWERS.OTHERAGENT.YES;
  }

  const onSubmit = async () => {

    if (email && validator.isEmail(email)) {
      onEmailFieldStatus(true);
      sendToDataLayer({
        step: (step + 1),
        event: 'email_submitted',
        email,
        formName: leadEntry,
        fields
      });

      if (skipPhone()) {
        // send Optimize cookie value to Salesforce
        updateParams(getExperimentID());

        setComplete(true);
        await submitForm('main');
      } else {
        nextStep();
      }
    } else {
      onEmailFieldStatus(false);
    }
  };

  const onBack = () => previousStep();
  const [emailField, setEmailField] = useState(true);


  const onEmailFieldStatus = (state) => {
    setEmailField(state);
  }
  
  return (
    <div className='container container--narrow ta-center rebranding'>
      <div className="main-form__header m-b-30">
        {!isBuyerPrograms &&
        <>
          <img
            src={Avatar}
            alt='avatar'
            className='rebranding-avatar'
          />
          <Heading
            color={COLORS.WHITE}
            subHeading='Our service is 100% free of charge, We promise NO hidden fees.'
          />
        </>
        }
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            copy: header,
            size: 'h2'
          }}
        />
      </div>
      <div className='rebranding-group container'>
        <div className='rebranding-group-inputs'>
          <div className={email ? 'rebranding-group-inputs-wrap' : 'rebranding-group-inputs-wrap required'}>
            <Input
              value={email}
              onChange={updateField('email')}
              placeholder={'Email Address'}
              type={'email'}
              dataCY={'email'}
              className= { emailField ? null : 'input--invalid'}
            />
          </div>
          <Button
            text={'Agree and Continue'}
            color={BUTTON_COLORS.CTA.DEFAULT}
            onClick={onSubmit}
            dataCY={'search_cta'}
          />
        </div>
        <div className='disclaimer_email'>
          <span>I have read and agree to Home & Money's <a target="_blank" href="/terms">Terms of Service</a>, <a target="_blank" href="/privacy">Privacy Policy</a> and <a target="_blank" href="/electronic-communications-consent">Consent to the Use of Electronic Communications</a></span>
        </div>
      </div>
      <div className='find-agent-button-container'>
        <Button
          text={'Back'}
          color={BUTTON_COLORS.LINK.WHITE}
          onClick={onBack}
          classes={'back-button'}
          dataCY={'back_button'}
        />
      </div>
    </div>
  );
};

export default Email;
