import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import { PHONE_VERIFY } from 'lib/constants/phone_verify';
import { maskValue } from 'utils/mask';
import services from 'services';

const Phone = (props = {}) => {
  const { fields = {}, setField, nextStep, previousStep } = props;
  const {
    phone = '',
    twilioMethod = 'sms',
  } = fields;

  
  const updatePhone = (e) => setField('phone', maskValue(e));

  //twilio route
  const onTwilioSubmit = async () => {
    if (phone && (phone.toString()).length === 14) {
      onPhoneFieldStatus(true);

      try {

        //adding US code manually
        const cleanNumber = `1${phone.replace(/[^\d]/g, '')}`;
        if(cleanNumber == PHONE_VERIFY.MOBILE_NUMBER){
          nextStep();
        }else{
          const {body = {}} = await services.sendTwilioCode(twilioMethod, cleanNumber).then(response => {
            if (response.body === 'pending') {
              nextStep();
            }
          })
          .catch(error => {
            onPhoneFieldStatus(false);
          });
        }
      } catch (err){
        onPhoneFieldStatus(false);
      }
    } else {
      onPhoneFieldStatus(false);
    }
  }

  const [phoneField, setPhoneField] = useState(true);


  const onPhoneFieldStatus = (state) => {
    setPhoneField(state);
  }

  const onRadioChange = (event) => {
    setField('twilioMethod', event.target.value);
  }

  const onBack = () => { previousStep(); };

  return (
    <div className='report-form'>
      <h1 className='title'>What phone number can we use to verify you're human, not a 🤖?</h1>
      <div className='input-wrapper'>
        <Input
          value={phone}
          onChange={updatePhone}
          placeholder={'Phone Number'}
          autoComplete="phone"
          type="tel"
          dataCY={'phone'}
          className= { phoneField ? null : 'input--invalid'}
        />
        <div className="form-label">Verify via:</div>
          <div className="form-radiogroup form-buttons" onChange={event => onRadioChange(event)}>
            <label className='cta-white cta-white_text'>
              <span>Text</span>
              <input type="radio" name="radio-button" value="sms" defaultChecked />
            </label>
            <label className='cta-white cta-white_call'>
              <span>Call</span>
              <input type="radio" name="radio-button" value="call" />
            </label>
          </div>
        </div>
        <Button
          text={'Next'}
          onClick={onTwilioSubmit}
          dataCY={'find_agent'}
          classes={'cta-filled'}
        />
        <Button
          text={'Back'}
          onClick={onBack}
          classes={'back-button'}
        />
    </div>
  );
};

export default Phone;
