import React from 'react';

const VideoSection = ({ agentName, videoUrl }) => {
  return (
    <div className="flex flex-col items-center justify-start gap-6 pt-16 Section">
      <div className="flex flex-col items-center self-stretch justify-start gap-6 Content">
        <div className="Headline self-stretch text-center text-[#2a2f64] text-[27px] sm:text-[39px] font-normal font-new-spirit leading-[52.65px] sm:leading-[52.65px]">A Word From {agentName}</div>
        <div className="w-full sm:max-w-[670px]">
          <div className="relative w-full overflow-hidden" style={{ paddingTop: '56.25%' }}>
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src="https://www.youtube.com/embed/u31qwQUeGuM?si=GT1SfBf_2qxhJ2X7&amp;controls=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
