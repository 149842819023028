// Global Utils

//scroll To Top class
const scrollToEl = document.querySelectorAll('.scrollToTop');

scrollToEl.forEach(el => {
	el.addEventListener('click', ()=>{
		window.scrollTo({top: 0, behavior: 'smooth'});
	});
});

//scroll To Top class
const scrollToFocus = document.querySelectorAll('.scrollAndFocus');

scrollToFocus.forEach(el => {
	el.addEventListener('click', ()=>{
		window.scrollTo({top: 0, behavior: 'smooth'});
		const focusEl = document.querySelector('.autocomplete');
		if (focusEl) {
			focusEl.focus();
		}
	});
});


// smooth scroll for all anchors
// determining absolute position of object in document: https://javascript.info/coordinates#getCoords
document.querySelectorAll('a[href^="#"]:not(.mega-menu-link)').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();
      const offset = 100;
      const targetEl = document.querySelector(this.getAttribute('href'));
			if (targetEl){
      	const scrollY = targetEl.getBoundingClientRect().top + window.pageYOffset - offset;
      	window.scrollTo({top: scrollY, behavior: 'smooth'});
			}
  });
});


//Max Mega Menu mobile clicks
//https://www.megamenu.com/documentation/javascript-api/
jQuery(document).ready(function( $ ) {

	//close all submenus on toggle click
	$('.mega-menu-toggle').on('click', function(e) {
		$('.mega-menu').data('maxmegamenu').hideAllPanels();
	});

	//close other submenus if one is opened
	$("ul.mega-menu").on("mmm:showMobileMenu", function() {
		$("li.mega-menu-item").on("open_panel", function() {
			var current_menu = $(this).children("ul.mega-sub-menu");
			$('ul.mega-menu li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu').not(current_menu).each( function() { 
				$('ul.mega-menu').data('maxmegamenu').hidePanel($(this), true);
			});
	});
	});
});
