import React, { useEffect } from 'react';
import Button from 'components/global/button';
import { FUNNEL } from 'lib/constants/translations';

const PropertyTypeMaster = ({ fields = { }, setField, nextStep }) => {

  const { isSpanishFunnel } = fields;

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  useEffect(() => {
    const path = window.location.pathname;

    if (path.includes('buying')) {
      setField('searchType', 'BUYER');
    } else if (path.includes('selling')) {
      setField('searchType', 'SELLER');
    }
  }, []);

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }

  const { propertyType = null, searchType } = fields;
  const setActiveButton = (value) => () => {
    setField('propertyType', value);
    nextStep();
  };

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>
        {FUNNEL.propertyType.question[language][searchType]}
      </h2>
      <div className='form-buttons short-btns'>
        {FUNNEL.propertyType.button[language].map(item =>
          <Button
            text={item.text}
            color={propertyType === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'button--filled__proptype_' + item.value }
          />
        )}
      </div>
    </div>
  );
};

export default PropertyTypeMaster;
