import React from 'react';
import Button from 'components/global/button';

const BUTTONS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const Owner = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  };

  const { isHomeOwner = null } = fields;
  const setActiveButton = (value) => () => {
    setField('isHomeOwner', value);
    nextStep();
  };

  return (
    <div className='report-form'>
      <h1 className='title'>Found it! Do you own this property?</h1>
      <div className='cta-full-width'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={isHomeOwner === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            classes={'cta-white'}
          />
        )}
      </div>
    </div>
  );
};

export default Owner;
