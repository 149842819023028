import React from 'react';
import Button from 'components/global/button';
import { FUNNEL } from 'lib/constants/translations';

const BuyMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  };

  const { buyingAndSelling = null, isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const setActiveButton = (value) => () => {
    setField('buyingAndSelling', value);
    nextStep();
  };

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{FUNNEL.buyMaster.question[language]}</h2>
      <div>
        {FUNNEL.buyMaster.buttons.map(item =>
          <Button
            text={item.text[language]}
            color={buyingAndSelling === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
          />
        )}
      </div>
    </div>
  );
};

export default BuyMaster;
