import React from 'react';

const HeroCTA = (props) => {

  const { title, bulletPoints, buttonUrl } = props;
  
  return [
    <div className="hero-cta">

      <div className="column">
        <h1 className="title hero-headline">{title}</h1>
        <div className="hero-headline">
          
          <div className="wrapper">
            <div>
              <div className="scroller">
                <div>
                  <span>with confidence.</span>
                  <span>without stress.</span>
                  <span>with expert help.</span>
                  <span>without regrets.</span>
                  <span>with financial help.</span>
                  <span>without fear.</span>
                  <span>with ease.</span>
                  <span>and save time.</span>
                  <span>and save money.</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="bullets-cta bullets-1">
          <div className="bullets bullets-1">
            {bulletPoints.map((point, index) => (
              <div key={index} className="list-item-checkmark">
                <span className="checkmark"></span>
                <p className="we-hand-select-the poppins-normal-storm-gray-16px">{point}</p>
              </div>
            ))}
          </div>
          <a className="button-home-hero" href={buttonUrl}>
            <div className="get-started">Get Started</div>
              <div className="badge">
                <p className="label valign-text-middle">💰 GET UP TO $1,000 BACK*</p>
              </div>
          </a>
        </div>
        <div className="social-proof">
          <span className="shopper-approved"></span>
          <span className="divider"></span>
          <div className="happy-buyers-sellers">
            <div className="text-1">100,000+</div>
            <p className="happy-buyers-sellers-1 valign-text-middle">Happy Buyers &amp; Sellers 🏡 🥳</p>
          </div>
        </div>
      </div>
    </div>
  ]
};

export default HeroCTA;
