import "core-js/stable";
import "regenerator-runtime/runtime";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import { updateParams, store, bootOptimize } from './store';
import { loadParams, setFBCookies, getFBCookies, getExperimentID } from 'lib/trackingParams';
import { sendToDataLayer } from 'lib/logGTM';
import { initSubnav } from 'utils/initSubnav';
import MyAgentFinder from './components/maf';
import ShortSale from './components/shortSale';
import CallMeBack from './components/maf/callMeBack';
import LenderMatch from './components/mrc/lender-match';
import Testimonials from './components/testimonials';
import ContactPage from './components/contact';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { initFloatingCTAs } from 'utils/initFloatingCTAs';
import MortgageField from './components/maf/form-wizard/mortgage';
import BuyerSellerTabs from './components/maf/form-wizard/buyerSellerHero';
import MasterFunnel from './components/maf/form-wizard/masterFunnel';
import HomevalSearch from './components/maf/form-wizard/homeval';
import HomeValReport from "./components/maf/form-wizard/homeval/report";
import AgentProfile from './components/agentProfile';

import '../../../tailwind.css';

//list of apps/funnels rendered into WP page templates
const apps = {
  'MyAgentFinder': () => MyAgentFinder,
  'CallMeBack': () => CallMeBack,
  'ShortSale': () => ShortSale,
  'LenderMatch': () => LenderMatch,
  'Testimonials': () => Testimonials,
  'MRC': () => LenderMatch,
  'ContactPage': () => ContactPage,
  'MyAgentFinderSkinny': () => MyAgentFinder,
  'MyAgentFinderBuyer': () => MyAgentFinder,
  'MyAgentFinderBuyerPrograms': () => MyAgentFinder,
  'MortgageField': () => MortgageField,
  'BuyerSellerTabs': () => BuyerSellerTabs,
  'CreatorHero': () => BuyerSellerTabs,
  'MasterFunnel': () => MasterFunnel,
  'HomevalSearch': () => HomevalSearch,
  'HomeValReport': () => HomeValReport,
  'AgentProfile': () => AgentProfile,
}

const { dispatch } = store;


const App = (props) => {

  useEffect(() => {

    // set utm params for redux -> API -> Salesforce
    dispatch(updateParams(loadParams()));

    // set experiment id for redux -> API -> Salesforce
    const vwoID = getExperimentID();
    if (vwoID) {
      dispatch(updateParams(vwoID));
    }

    if (getFBCookies()) {

      const cookieArr = getFBCookies();

      cookieArr.forEach((obj) => {
        dispatch(updateParams(obj));
      });
    }


    if (typeof window !== 'undefined') {
      window.maf = {};
      window.maf.bootOptimize = variants => dispatch(bootOptimize(variants));

      // required and visible for Cypress only
      if (window.Cypress) {
        window.__store__ = store;
      }

      // basic logging
      sendToDataLayer({
        event: 'componentRendered',
        component: props.componentID,
      });
    }


  },[]);

  const Comp = apps[props.componentID]();
  return (
    <Comp {...props} />
  );
}

const renderAppInElement = (el) => {

  if (apps[el.id]) {

    const root = ReactDOM.createRoot(el);

    root.render(
      
      <Provider store={store}>
        <Router>
          <PersistGate persistor={store.__PERSISTOR} loading={null}>
            <App componentID={el.id}/>
          </PersistGate>
        </Router>
    </Provider>,
    );
  }
}

document
  .querySelectorAll('.__react-root')
  .forEach(renderAppInElement)

initSubnav();
initFloatingCTAs();
setFBCookies();
loadParams();
