import React, { useEffect, useState, Suspense } from 'react';
import { addBodyClass } from 'utils/utils';
import { CLASS } from 'lib/constants/funnelMeta';
import Header from './header';

const MainForm = React.lazy(() => import('components/maf/form-wizard'));

const Top = () => {

  useEffect(() => {
    addBodyClass(CLASS.MASTERFUNNEL.DEFAULT);

  },[]);

  return (
    <>
    <div className='master-funnel-wrapper'>
      <Suspense fallback={<div>Loading...</div>}>
        <Header />
        <MainForm
          isMasterFunnel
        />
      </Suspense>
    </div>
    <div id='copyCalculator' className='copyCalculator'>
        <div id='sgi' data-guid='b3953b05-36de-484b-9128-7b94bf942561'></div>
      </div>
    </>
  );
};

export default Top;
