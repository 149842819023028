import React from 'react';
import CTAButton from './cta';
import SvgBuy from './svgBuy';
import SvgSell from './svgSell';

const SocialBadge = ({ agentName, agentLastName, agentCompanyName }) => {
  return (
    <div className="SocialBadge mb-12 w-auto absolute bottom-[0] flex justify-center items-center p-[10px] bg-[white] rounded-[32px] h-20 px-8 py-4 sm:rounded-[48px] shadow border border-[#d0d5dd] flex-col">
      <div className="Headline text-center text-[#2A2F64] text-[17px] sm:text-[20px] leading-normal">{`${agentName} ${agentLastName}`}</div>
      <div className="flex flex-col items-center justify-start TextAndSupportingText">
        <div className="SupportingText text-[#667085] text-[16px] font-normal leading-normal">{agentCompanyName}</div>
      </div>
    </div>
  );
};

const HeroSection = ({ agentID, agentName, agentLastName, agentProfileImage, agentCompanyName , starRating, agentTestimonialsCount }) => {
  return (
    <div id="CreatorHero" className='w-auto max-w-[500px] lg:max-w-[1140px] flex relative mx-0 sm:mx-[48px] sm:my-[40px]'>
      <div id='agent-hero-wrapper' className={agentProfileImage ? '' : 'no-image'}>
        <div className={`w-full ${agentProfileImage ? 'lg:w-1/2' : 'lg:w-full'} px-[32px] py-[40px] sm:px-[48px] sm:py-[56px]`}>
          <div className="flex flex-col items-start gap-[24px]">
            {!agentProfileImage && (
              <div className="w-auto bottom-[0] flex justify-center items-start lg:items-center p-[10px] bg-[white] rounded-[32px] h-20 px-8 py-4 sm:rounded-[48px] shadow border border-[#d0d5dd] flex-col lg:flex-row gap-0 lg:gap-4">
                <div className="Headline text-center text-[#2A2F64] text-[17px] sm:text-[20px] leading-normal">{`${agentName} ${agentLastName}`}</div>
                <div className="flex flex-col justify-start lg:items-center lg:w-auto TextAndSupportingText">
                  <div className="SupportingText text-[#667085] text-[16px] font-normal leading-normal">{agentCompanyName}</div>
                </div>
              </div>
            )}
            <div className="flex flex-col items-start gap-[16px] self-stretch">
              <h1 className="text-[#5B3C2F] text-[32px] sm:text-[42px] not-italic font-medium leading-[120%] tracking-[-0.84px] font-new-spirit antialiased">Gain a partner on your home journey.</h1>
              <span className='text-[rgba(100,_66,_52,_0.75)] text-[16px] not-italic font-normal leading-[150%]'>Whether you're listing a property for sale or looking for the perfect place to call home, you have support.</span>
            </div>
            <div className='flex flex-col items-start self-stretch justify-center gap-6 FinderLinkWidget'>
            <div className="inline-flex flex-col items-start justify-start gap-2 Frame427319842">
              <div className="text-[#5B3C2F] text-[19px] sm:text-[25px] not-italic font-medium leading-[120%] tracking-[-0.5px]">Work with {agentName}</div>
              {starRating && agentTestimonialsCount && (
              <div className="Frame577 self-stretch justify-start items-center gap-1.5 inline-flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M11.9349 16.5C11.5892 16.4986 11.253 16.3828 10.9748 16.1693L8.66123 14.421C8.26736 14.1218 7.73272 14.1218 7.33884 14.421L5.02524 16.1693C4.45284 16.6019 3.67775 16.6019 3.10532 16.1694C2.53288 15.7369 2.29329 14.9702 2.51184 14.2702L3.39559 11.4413C3.54718 10.9591 3.38204 10.4303 2.98697 10.1328L0.673374 8.38448C0.100941 7.95191 -0.138598 7.18515 0.0800396 6.48521C0.298677 5.78527 0.925759 5.31138 1.63331 5.31138H4.49314C4.98077 5.3123 5.41316 4.98556 5.56302 4.5029L6.44664 1.674C6.66526 0.973971 7.2924 0.5 8.00003 0.5C8.70765 0.5 9.33479 0.973971 9.55341 1.674L10.437 4.5029C10.5869 4.98556 11.0193 5.3123 11.5069 5.31138H14.3667C15.0743 5.31141 15.7013 5.78531 15.92 6.48523C16.1386 7.18515 15.8991 7.9519 15.3267 8.38448L13.0131 10.1328C12.618 10.4303 12.4529 10.9591 12.6045 11.4413L13.4882 14.2702C13.715 14.9701 13.4739 15.7419 12.8948 16.1693C12.6167 16.3827 12.2805 16.4985 11.9349 16.5Z" fill="#ED9C7B"/>
                </svg>
                <div className="text-[rgba(100,_66,_52,_0.75)] text-[16px] not-italic font-semibold">{starRating} (from {agentTestimonialsCount} reviews)</div>
                </div>
              )}
            </div>
              <div className={`flex ${agentProfileImage ? 'flex-col' : 'flex-col lg:flex-row'} items-start self-stretch justify-start gap-3 Ctas`}>
                <CTAButton
                  href={`/selling?agentID=${agentID}`}
                  icon={<SvgSell />}
                  label="I'm Selling"
                />
                <CTAButton
                  href={`/buying?agentID=${agentID}`}
                  icon={<SvgBuy />}
                  label="I'm Buying"
                />
              </div>
            </div>
          </div>
        </div>
        {agentProfileImage && (
          <div className='relative flex items-end justify-center w-full h-full lg:w-1/2'>
            <img
              src={agentProfileImage} 
              alt='Hero Image'
              className="rounded-[60px] object-cover w-full h-full"
            />
            <SocialBadge
              agentName={agentName}
              agentLastName={agentLastName}
              agentCompanyName={agentCompanyName}
            />
          </div>
        )}
      </div>
      <style jsx="true">{`

        .no-image .Ctas a {
          min-width: 50%;
        }
      
        #CreatorHero::after {
          z-index: -1;
        }

        @media (min-width: 500px) {
          #CreatorHero::before {
            left: -81px;
          }
        }

        #agent-hero-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin: 40px auto;
          border-radius: 60px;
          overflow: hidden;
          margin: 32px auto;
          height: auto;
          max-width: 100%;
          background: linear-gradient(286.23deg, rgba(251, 231, 221, 0.6) 0%, rgba(255, 245, 240, 0.6) 100%);
          backdrop-filter: blur(12px);
          -webkit-backdrop-filter: blur(12px);
          border: 3px solid #FCF2ED;
        }
          @media (min-width: 1024px) {
            #agent-hero-wrapper {
              flex-direction: row;
            }
          }
      `}</style>
    </div>
  );
};

export default HeroSection;