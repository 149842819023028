import React from 'react';

const Complete = (props = {}) => {

  const { fields = {} } = props; 

  const { } = fields;


  return (
    <div>
    </div>
  )
};

export default Complete;
