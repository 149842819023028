import React from 'react';

const ContactDetails = ({ details }) => {
  return (
    <div className='Frame986 flex flex-col self-stretch py-6 border-t border-b border-[#d0d5dd] justify-start items-start gap-4'>
      <div className='inline-flex flex-col items-start justify-center gap-4 Frame987 grow shrink basis-0'>
        {details.email && (
          <div className='inline-flex items-center self-stretch justify-start gap-2 ContactText'>
            <div className='inline-flex flex-col items-center justify-center w-5 h-5 IconWrap'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M1.66675 5.83331L8.47085 10.5962C9.02182 10.9819 9.29731 11.1747 9.59697 11.2494C9.86166 11.3154 10.1385 11.3154 10.4032 11.2494C10.7029 11.1747 10.9783 10.9819 11.5293 10.5962L18.3334 5.83331M5.66675 16.6666H14.3334C15.7335 16.6666 16.4336 16.6666 16.9684 16.3942C17.4388 16.1545 17.8212 15.772 18.0609 15.3016C18.3334 14.7668 18.3334 14.0668 18.3334 12.6666V7.33331C18.3334 5.93318 18.3334 5.23312 18.0609 4.69834C17.8212 4.22793 17.4388 3.84548 16.9684 3.6058C16.4336 3.33331 15.7335 3.33331 14.3334 3.33331H5.66675C4.26662 3.33331 3.56655 3.33331 3.03177 3.6058C2.56137 3.84548 2.17892 4.22793 1.93923 4.69834C1.66675 5.23312 1.66675 5.93318 1.66675 7.33331V12.6666C1.66675 14.0668 1.66675 14.7668 1.93923 15.3016C2.17892 15.772 2.56137 16.1545 3.03177 16.3942C3.56655 16.6666 4.26662 16.6666 5.66675 16.6666Z" stroke="#6471ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <a href={`mailto:${details.email}`} className='Text self-stretch text-[#2a2f64] text-[14px] font-normal leading-tight'>{details.email}</a>
            </div>
          </div>
        )}
        {details.main_phone && (
          <div className='inline-flex items-center self-stretch justify-start gap-2 ContactText'>
            <div className='inline-flex flex-col items-center justify-center w-5 h-5 IconWrap'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M6.98356 7.37767C7.56356 8.58569 8.35422 9.71789 9.35553 10.7192C10.3568 11.7205 11.4891 12.5112 12.6971 13.0912C12.801 13.1411 12.8529 13.166 12.9187 13.1852C13.1523 13.2533 13.4392 13.2044 13.637 13.0627C13.6927 13.0228 13.7403 12.9752 13.8356 12.8799C14.1269 12.5886 14.2726 12.4429 14.4191 12.3477C14.9715 11.9885 15.6837 11.9885 16.2361 12.3477C16.3825 12.4429 16.5282 12.5886 16.8196 12.8799L16.9819 13.0423C17.4248 13.4852 17.6462 13.7066 17.7665 13.9444C18.0058 14.4174 18.0058 14.976 17.7665 15.4489C17.6462 15.6867 17.4248 15.9082 16.9819 16.351L16.8506 16.4824C16.4092 16.9238 16.1886 17.1444 15.8885 17.313C15.5556 17.5 15.0385 17.6345 14.6567 17.6333C14.3126 17.6323 14.0774 17.5655 13.607 17.432C11.0792 16.7146 8.69387 15.3608 6.70388 13.3709C4.7139 11.3809 3.36017 8.99557 2.6427 6.46774C2.50919 5.99737 2.44244 5.76218 2.44141 5.41806C2.44028 5.03621 2.57475 4.51913 2.76176 4.18621C2.9303 3.88618 3.15098 3.66551 3.59233 3.22416L3.72369 3.09279C4.16656 2.64992 4.388 2.42849 4.62581 2.3082C5.09878 2.06898 5.65734 2.06898 6.1303 2.3082C6.36812 2.42849 6.58955 2.64992 7.03242 3.09279L7.19481 3.25518C7.48615 3.54652 7.63182 3.69219 7.72706 3.83867C8.08622 4.39108 8.08622 5.10323 7.72706 5.65564C7.63182 5.80212 7.48615 5.94779 7.19481 6.23913C7.09955 6.33439 7.05192 6.38202 7.01206 6.4377C6.87038 6.63556 6.82146 6.92244 6.88957 7.15607C6.90873 7.22181 6.93367 7.27376 6.98356 7.37767Z" stroke="#6471ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <a href={`tel:${details.main_phone}`} className='Text self-stretch text-[#2a2f64] text-[14px] font-normal leading-tight'>{details.main_phone}</a>
            </div>
          </div>
        )}
      </div>
      <div className='inline-flex flex-col items-start justify-start gap-3 Frame988 grow shrink basis-0'>
        {details.street && (
          <div className='inline-flex items-start self-stretch justify-start gap-2 ContactText'>
            <div className='inline-flex flex-col items-center justify-center w-5 h-5 IconWrap'>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M10.4999 10.4167C11.8806 10.4167 12.9999 9.2974 12.9999 7.91669C12.9999 6.53598 11.8806 5.41669 10.4999 5.41669C9.11921 5.41669 7.99992 6.53598 7.99992 7.91669C7.99992 9.2974 9.11921 10.4167 10.4999 10.4167Z" stroke="#6471ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.4999 18.3334C12.1666 15 17.1666 12.8486 17.1666 8.33335C17.1666 4.65146 14.1818 1.66669 10.4999 1.66669C6.81802 1.66669 3.83325 4.65146 3.83325 8.33335C3.83325 12.8486 8.83325 15 10.4999 18.3334Z" stroke="#6471ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          <div className='inline-flex flex-col items-start self-stretch justify-start TextAndSupportingText grow shrink basis-0'>
            <div className='Text self-stretch text-[#2a2f64] text-[14px] font-normal leading-tight'>
              <div className='flex flex-col text-[#2a2f64] text-[14px] font-normal leading-tight'>
                <span>{details.street}</span>
                <span>{`${details.city}, ${details.state} ${details.postal_code}`}</span>
              </div>
            </div>
            </div>
            </div>
        )}
        {details.website && (
          <div className='inline-flex items-center self-stretch justify-start gap-2 ContactText'>
            <div className='inline-flex flex-col items-center justify-center w-5 h-5 IconWrap'>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M2.16675 10H18.8334M2.16675 10C2.16675 14.6024 5.89771 18.3334 10.5001 18.3334M2.16675 10C2.16675 5.39765 5.89771 1.66669 10.5001 1.66669M18.8334 10C18.8334 14.6024 15.1025 18.3334 10.5001 18.3334M18.8334 10C18.8334 5.39765 15.1025 1.66669 10.5001 1.66669M10.5001 1.66669C12.5845 3.94865 13.769 6.91005 13.8334 10C13.769 13.09 12.5845 16.0514 10.5001 18.3334M10.5001 1.66669C8.41568 3.94865 7.23112 6.91005 7.16675 10C7.23112 13.09 8.41568 16.0514 10.5001 18.3334" stroke="#6471ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <a target="_blank" href={details.website?.startsWith('http') ? details.website : `https://${details.website}`} className='Text self-stretch text-[#2a2f64] text-[14px] font-normal leading-tight'>{details.website?.replace(/^https?:\/\//, '')}</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactDetails;