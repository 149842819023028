/*

if Poor Credit (poor + fair) :
	PoorCredit LP
else
		MRC LP
*/

const isPoorCredit = (creditScore) => ['Very Poor', 'Fair'].includes(creditScore);

const getPoorCreditURL = 'https://self-lender.3qcw.net/c/4014707/1609879/10159';

const isMRCState = (state) => {
  const states = [
    {abbr: 'AK', name: 'Alaska'},
    {abbr: 'AL', name: 'Alabama'},
    {abbr: 'IA', name: 'Iowa'},
    {abbr: 'KS', name: 'Kansas'},
    {abbr: 'LA', name: 'Louisiana'},
    {abbr: 'MO', name: 'Missouri'},
    {abbr: 'MS', name: 'Mississippi'},
    {abbr: 'OK', name: 'Oklahoma'},
    {abbr: 'OR', name: 'Oregon'},
    {abbr: 'TN', name: 'Tennessee'},
  ];
  return states.find(s => s.abbr === state || s.name === state) !== undefined;
}

export {
  isPoorCredit,
  getPoorCreditURL,
  isMRCState
};
