import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMainForm } from '../../store';
import { isPoorCredit, getPoorCreditURL } from "utils/mortgageRedirect";

import Button from "./button";
import { SLUG } from 'lib/constants/funnelMeta';
import { sendToDataLayer } from 'lib/logGTM';


const IntroLendCTA = (props = {}) => {

  const { color, text = 'Get Pre-Approved Today', classes='gtm-pre-approved-mrc', isMasterFunnel = false } = props;
  const { fields = {} } = useSelector(getMainForm);
  const {
    creditScore,
  } = fields;

  const [loading, setLoading] = useState(false);
  const [final, setFinal] = useState({
    finalUrl: '',
    finalClass: ''
  });

  useEffect(() => {

    if (!isMasterFunnel && isPoorCredit(creditScore)) {

      setFinal({
        finalUrl: getPoorCreditURL,
        finalClass: 'get-pre-approved-self'
      });

      sendToDataLayer({
        event: 'cta_extra_credit'
      });
    
    } else {

      setFinal({
        finalUrl: '/' + SLUG.LENDERMATCH,
        finalClass: 'get-pre-approved-mrc'
      });
    }


  },[]);

  const onClick = () => {
    setLoading(true);
    window.location.href = final.finalUrl;
  };

  return (
    <>
      <Button
        classes={classes}
        text={loading ? 'Redirecting' : text}
        disabled={loading}
        loading={loading}
        color={color}
        onClick={() => onClick()}
        dataCY={final.finalClass}
      />
    </>
  );
};

export default IntroLendCTA;
