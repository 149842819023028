import React, { useState } from 'react';
import Button from 'components/global/button';
import Input from 'components/global/input';
import { BUTTON_COLORS } from 'lib/constants/colors';
import { PHONE_VERIFY } from 'lib/constants/phone_verify';
import { sendToDataLayer } from 'lib/logGTM';
import { getExperimentID } from 'lib/trackingParams';
import { maskValue } from 'utils/mask';
import { FUNNEL } from 'lib/constants/translations';
import services from 'services';

const PhoneMaster = (props = {}) => {
  const { fields = {}, step, setField, nextStep, updateParams } = props;
  const {
    phone = '',
    leadEntry,
    twilioMethod = 'sms',
    isSpanishFunnel
  } = fields;

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';
  
  const updatePhone = (e) => setField('phone', maskValue(e));

  //twilio route
  const onTwilioSubmit = async () => {
    if (phone && (phone.toString()).length === 14) {
      onPhoneFieldStatus(true);

      // send Optimize cookie value to Salesforce
      updateParams(getExperimentID());

      sendToDataLayer({
        step: (step + 1),
        event: 'funnel_steps',
        formName: leadEntry,
        fields,
      });

      try {

        //adding US code manually
        const cleanNumber = `1${phone.replace(/[^\d]/g, '')}`;
        if(cleanNumber == PHONE_VERIFY.MOBILE_NUMBER){
          nextStep();
        }else{
          const {body = {}} = await services.sendTwilioCode(twilioMethod, cleanNumber).then(response => {
            if (response.body === 'pending') {
              nextStep();
            }
          })
          .catch(error => {
            onPhoneFieldStatus(false);
          });
        }
      } catch (err){
        onPhoneFieldStatus(false);
      }
    } else {
      onPhoneFieldStatus(false);
    }
  }

  const [phoneField, setPhoneField] = useState(true);


  const onPhoneFieldStatus = (state) => {
    setPhoneField(state);
  }

  const onRadioChange = (event) => {
    setField('twilioMethod', event.target.value);
  }

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>{FUNNEL.phone.header[language]}</h2>
      <div>
        <Input
            value={phone}
            onChange={updatePhone}
            placeholder={FUNNEL.phone.placeholder[language]}
            autoComplete="phone"
            type="tel"
            dataCY={'phone'}
            className= { phoneField ? null : 'input--invalid'}
          />
          <div className="form-label">{FUNNEL.phone.phoneVerification.verifyVia[language]}</div>
            <div className="form-radiogroup form-buttons" onChange={event => onRadioChange(event)}>
              <label className='button button_text'>
                <span>{FUNNEL.phone.verify.text[language]}</span>
                <input type="radio" name="radio-button" value="sms" defaultChecked />
              </label>
              <label className='button button_call'>
                <span>{FUNNEL.phone.verify.call[language]}</span>
                <input type="radio" name="radio-button" value="call" />
              </label>
            </div>
          </div>
        
        <Button
          text={FUNNEL.phone.nextButton[language]}
          color={BUTTON_COLORS.CTA.DEFAULT}
          onClick={onTwilioSubmit}
          dataCY={'find_agent'}
        />
    </div>
  );
};

export default PhoneMaster;
