import React from 'react';
import Accordion from 'components/global/accordion';
import { FUNNEL } from 'lib/constants/translations';

import StellarFiLogo from 'images/logos/stellar-fi-logo.svg';
import SmartCreditLogo from 'images/logos/smartcredit-logo.svg';
import HamStellarFiLogo from 'images/logos/ham-stellarfi.svg';
import HamSmartCreditLogo from 'images/logos/ham-smartcredit.svg';

const Completed2Master = (props = {}) => {

  const { fields = {} } = props;
  const { isInfluencerLead, isSpanishFunnel } = fields;

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const onClick = () => {
    window.location.href = creditPartner.url;
  };

  const mortgageItems = {

    SmartCredit : {
      brand: 'SmartCredit®',
      logo: SmartCreditLogo,
      hamLogo: HamSmartCreditLogo,
      url: 'https://www.smartcredit.com/?PID=15765',
      videoUlr: 'https://www.youtube.com/embed/NiIWQHB_bWQ?si=P605a_9uhU5dPsVy&amp;controls=0&rel=0',
      accordionItem: FUNNEL.completed2Master.SmartCreditCanHelp[language],
    },
    StellarFi : {
      brand: 'StellarFi',
      logo: StellarFiLogo,
      hamLogo: HamStellarFiLogo,
      url: 'https://stellarfi.com/home-and-money',
      videoUlr: 'https://www.youtube.com/embed/Z9-A4gogC94?si=XA5Xy551mDLqPjP_&amp;controls=0&rel=0',
      accordionItem: FUNNEL.completed2Master.StellarFiWorksWith[language],
    },
    
  }

  const creditPartner = isInfluencerLead ? mortgageItems.SmartCredit : mortgageItems.StellarFi;

  const ResponsiveIframe = ({ src, title }) => {
    return (
      <div className="responsive-iframe-wrapper">
        <iframe
          src={src}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const accordionItems = [
    {
      title: FUNNEL.completed2Master.whyDoINeedToBuildCredit[language],
      content: FUNNEL.completed2Master.inShort[language],
    },
    {
      title: FUNNEL.completed2Master.howDoesItWork[language],
      content: creditPartner.accordionItem,
    },
  ];

  return (
    <div className='form-wrapper poor-credit-lp'>
      <div className='lp-hero'>
        <div className='lp-title'>
          <h2 className='title'>{FUNNEL.completed2Master.title[language]}</h2>
          <div className='partnerships'>
            <span className='support-title'>{FUNNEL.completed2Master.supportTitle[language]}</span>
            <span className='line'></span>
            <img src={creditPartner.logo} alt="Logo" />
          </div>
        </div>
        <div className='lp-ctas'>
          <button className='button button--active' onClick={onClick}>{FUNNEL.completed2Master.startNowButton[language]}</button>
        </div>
      </div>
      
      <div className='lp-about-partnership'>

        <div className='lp-about-partnership-hero'>
          <h2 className='title'>{FUNNEL.completed2Master.buildCreditWith[language]} {creditPartner.brand}</h2>
          <ResponsiveIframe
            src={creditPartner.videoUlr}
            title="YouTube video player"
          />
          <p>{FUNNEL.completed2Master.ToHelpOutWe[language]} {creditPartner.brand}, {FUNNEL.completed2Master.ToHelpOut[language]}</p>
        </div>

        <div className='lp-about-partnership-accordion'>
          <Accordion items={accordionItems} />
        </div>

        <div className='lp-about-partnership-ctas'>
          <button className='button' onClick={onClick}>{FUNNEL.completed2Master.startBuildingCredit[language]}</button>
          <div className="partnerships">
            <img src={creditPartner.hamLogo} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Completed2Master;