import React from 'react';
import Star from 'components/testimonials/star';
import {COLORS} from "lib/constants/colors";

const Review = (props = {}) => {
  const { comments = '', display_name ='', order_date = '', state = '', i = null, order = null } = props;
  const dateRaw = order_date ? new Date(order_date) : null;
  const date = dateRaw ? (`${dateRaw.getMonth()+1}/${dateRaw.getDate()}/${dateRaw.getFullYear()}`) : '';
  return comments && comments.length ? [
    <span className='hiw-step__illo'>
      <Star /><Star /><Star /><Star /><Star />
    </span>,
    <p className='body-copy hiw-step__comment'>{comments}</p>,
    <p className='body-copy' style={{width:50, borderTop: `3px solid ${COLORS.NAVY}`, margin: '1em auto'}}></p>,
    <p className='body-copy' style={{fontWeight: 'bold',}}>{display_name}</p>,
    <p className='body-copy' style={{marginBottom: '5em',marginTop:0,fontSize:'.8em'}}>{date}{state ? `, ${state}` : ''}</p>,
  ] : null;
};

export default Review;