import React from 'react';
import Review from 'components/testimonials/review';
import Column from 'components/testimonials/column';
import reviews from 'components/testimonials/data.json';

const Reviews = ({ all = false }) => {
  const one = all ? reviews.one.map((item, i) => (<Review {...item } key={`1-${i}`} />)) : (<Review {...reviews.small[1] } />);
  const two = all ? reviews.two.map((item, i) => (<Review {...item } key={`2-${i}`} />)) : (<Review {...reviews.small[2] } />);
  const three = all ? reviews.three.map((item, i) => (<Review {...item } key={`3-${i}`} />)) : (<Review {...reviews.small[0] } />);
  const four = all ? reviews.four.map((item, i) => (<Review {...item } key={`4-${i}`} />)) : (<Review {...reviews.small[3] } />);
  return (
    <div className={`section ${all ? '' : 'shopper_approved_reviews'}`} style={all ? {} : {marginBottom: `-12em`}}>
      <div className={'container container--wide'}>
        <div className={'flex flex-wrap space-between vertical-container'}>
          <Column i="1" key={1}>{one}</Column>
          <Column i="2" key={2}>{two}</Column>
          <Column i="3" key={3}>{three}</Column>
          <Column i="4" key={4}>{four}</Column>
        </div>
      </div>
    </div>
  );
};

export default Reviews;