import React from 'react';
import Heading from 'components/global/heading';
import Button from 'components/global/button';
import { COLORS, BUTTON_COLORS } from 'lib/constants/colors';

const BUTTONS = [
  { text: 'Exceptional', value: '740-850' },
  { text: 'Very Good', value: '680-739' },
  { text: 'Good', value: '620-679' },
  { text: 'Fair', value: '580-619' },
  { text: 'Poor', value: '300-579' },
];

const HEADER = {
  Buyer: 'How do you rate your credit score?',
  Seller: 'What is your target sale price?',
};

const CreditScore = ({ fields = {}, setField, nextStep, previousStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: BUTTON_COLORS.CTA.DEFAULT,
    ACTIVE: BUTTON_COLORS.CTA.DARK_ROSE,
  }
  
  const onBack = () => { previousStep(); setField('creditScore', ''); };
  const { creditScore = '', searchType } = fields;
  const setActiveButton = (value) => () => {
    //custom mapping for SF REF-876
    if (value === 'Poor') {
      value = 'Very Poor';
    }
    setField('creditScore', value);
    nextStep();
  };

  return (
    <div className='container container--normal ta-center rebranding'>
    <div className="main-form__header m-b-30">
        <Heading
          tight
          color={COLORS.WHITE}
          headline={{
            size: 'h2',
            copy: searchType === "BUYER" ? HEADER.Buyer : HEADER.Seller
          }}
        />
      </div>
      <div className='container container--narrow'>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={creditScore === item.text ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.text)}
            key={item.value}
            classes={item.value + ' property-button creditscore-button badge-top-right'}
            dataCY={'creditScore_button'}
            dataCreditScore={item.value}
          />
        )}
        <div>
          <Button
            text={'Back'}
            color={BUTTON_COLORS.LINK.WHITE}
            onClick={onBack}
            classes={'back-button'}
            dataCY={'back_button'}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditScore;
