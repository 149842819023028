import React from 'react';
import Agents from 'images/homeval/agents.png';
import { SLUG } from 'lib/constants/funnelMeta';

const NotFound = (props) => {

  return (
    <div className="report-wrapper">
      <div className='inner-wrapper'>
        <h1 className='title'>Sorry, we couldn't create a report for '{props.address}' 😕</h1>
        <p className='subtitle'>The address you entered is either mispelled, or there's not enough data for us to generate a report.</p>
        <span className="go-back"><a href={`/${SLUG.HOMEVAL}`}>Go back to edit address</a></span>
      </div>
      <div className='inner-wrapper'>
          <img
            src={Agents}
            alt='agents'
            className='agents'
          />
        <h2 className='title h2'>Connect with an agent instead?</h2>
        <p className='subtitle'>If you'd like to get an expert opinion on the property you're searching, we can match you with a top local agent for free.</p>
        <div className="cta-wrapper">
          <a className="cta-filled" href='/selling'>I am Selling</a>
          <a className="cta-filled" href='/buying'>I am Buying</a>
        </div>
      </div>

    </div>
  );
};

export default NotFound;
