import React from 'react';
import Button from 'components/global/button';
import { ANSWERS } from 'lib/constants/funnelMeta';
import { FUNNEL } from 'lib/constants/translations';

const PreApprovedMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }

  const { status = null, isInfluencerLead, isSpanishFunnel } = fields;
  const setActiveButton = (value) => () => {
    setField('status', value);
    nextStep();
  };

  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';

  const title = FUNNEL.preApproved.title[language].default;

  const subtitle = FUNNEL.preApproved.subtitle[language];

  const BUTTONS = [
    { 
      text: FUNNEL.preApproved.buttons.yes[language], 
      value: isInfluencerLead ? ANSWERS.STATUS.INPROCESS : ANSWERS.STATUS.YES 
    },
    { 
      text: FUNNEL.preApproved.buttons.no[language], 
      value: ANSWERS.STATUS.NO 
    },
  ];

  return (
    <div className='form-wrapper'>
      { !isInfluencerLead && <span className='form-subtitle'>{subtitle}</span> }
      <h2 className='form-title'>{title}</h2>
      <div>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={status === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
          />
        )}
      </div>
    </div>
  );
};

export default PreApprovedMaster;
