import React from 'react';

const Star = (props) => {
  const { color } = props;
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24" style={{padding:' 0 1px'}}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M6.10785182,23.7087738 L11.9791357,20.6226396 L17.8504195,23.7087738 C18.8926133,24.2568282 20.1369112,23.3763791 19.9347632,22.1949015 L18.811719,15.6587842 L23.5599586,11.0318422 C24.4134722,10.2007894 23.9417937,8.74982512 22.7648346,8.57913118 L16.2017818,7.62230629 L13.2683992,1.67464668 C12.7428057,0.614510502 11.2199402,0.601042745 10.6898721,1.67464668 L7.75648947,7.62230629 L1.19343673,8.57913118 C0.0164776372,8.74982512 -0.455200926,10.2007894 0.398312664,11.0318422 L5.1465523,15.6587842 L4.02350811,22.1949015 C3.82136015,23.3763791 5.06565803,24.2613028 6.10785182,23.7087738 Z" id="" fill="#FC9305" fillRule="nonzero"></path>
        </g>
      </svg>
    </>
  )
};

export default Star;
