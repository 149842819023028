import React from 'react';

const Column = (props = {}) => {
  const { children, i } = props;
  return (
    <div className={'hiw-step ta-center'} key={i}>
      {children}
    </div>
  );
};

export default Column;