import React from 'react';

const CTA = ({ href, icon, label }) => {
  return (
    <a href={href} className='MafButton min-w-[240px] self-stretch pl-6 pr-7 py-2 bg-[#434ca0] hover:bg-[#2E3570] rounded-lg shadow justify-center items-center gap-3.5 inline-flex'>
      <div className='relative w-[55px] h-[55px]'>
        {icon}
      </div>
      <div className="text-center text-white text-[22px] font-bold">
        {label}
      </div>
    </a>
  );
};

export default CTA;