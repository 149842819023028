import React from 'react';
import Button from 'components/global/button';

const BUTTONS = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

const MilitaryMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }

  const { militaryVeteran = null } = fields;
  const setActiveButton = (value) => () => {
    setField('militaryVeteran', value);
    nextStep();
  };

  return (
    <div className='form-wrapper'>
      <span className='form-subtitle'>Next, let's check for grants and programs you may be eligible for.</span>
      <h2 className='form-title'>Have you or your spouse ever served in the military?</h2>
      <div>
        {BUTTONS.map(item =>
          <Button
            text={item.text}
            color={militaryVeteran === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            dataCY={'property_button'}
          />
        )}
      </div>
    </div>
  );
};

export default MilitaryMaster;
