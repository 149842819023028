import React from 'react';
import Button from 'components/global/button';
import { FUNNEL } from 'lib/constants/translations';

const FirstHomeMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }

  const { firstHome = null, isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';
  const setActiveButton = (value) => () => {
    setField('firstHome', value);
    nextStep();
  };

  return (

    <div className='form-wrapper'>
      <h2 className='form-title'>{FUNNEL.firstHome.question[language]}</h2>
      <div>
        {FUNNEL.firstHome.button[language].map(item =>
          <Button
            text={item.text}
            color={firstHome === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
          />
        )}
      </div>
    </div>
  );
};

export default FirstHomeMaster;
