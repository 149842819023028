import React from 'react';
import Button from 'components/global/button';
import { FUNNEL } from 'lib/constants/translations';

const TimeFrameMaster = ({ fields = {}, setField, nextStep }) => {

  const BUTTON_STYLE = {
    DEFAULT: 'default',
    ACTIVE: 'active',
  }
  
  const { timeframe = '', searchType, isSpanishFunnel } = fields;
  const language = isSpanishFunnel ? 'SPANISH' : 'ENGLISH';
  
  const setActiveButton = (value) => () => {
    setField('timeframe', value);
    nextStep();
  };

  return (
    <div className='form-wrapper'>
      <h2 className='form-title'>
        {FUNNEL.timeFrame.question[searchType][language]}
      </h2>
      <div className='form-buttons'>
        {FUNNEL.timeFrame.button[language].map(item =>
          <Button
            text={item.text}
            color={timeframe === item.value ? BUTTON_STYLE.ACTIVE : BUTTON_STYLE.DEFAULT}
            onClick={setActiveButton(item.value)}
            key={item.value}
            dataCY={'timeFrame_button'}
            classes={'button--filled__proptype_' + item.value.replace(/ /g, '')}
          />
        )}
      </div>
    </div>
  );
};

export default TimeFrameMaster;
