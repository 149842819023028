import React, { useEffect } from 'react';
import WhatsNext from './whatsnext';
import Finance from './finance';
import PoorCredit from './poorCredit';
import { isPoorCredit } from "utils/mortgageRedirect";
import { addBodyClass } from "utils/utils";

const WhatsNextWrapper = (props = {}) => {
  const {fields } = props;

  const {
    creditScore
  } = fields;


  useEffect(() => {
    if (isPoorCredit(creditScore)) {
      addBodyClass('poor-credit');
    }
  },[]);


  //mainCompleted
  return (
    isPoorCredit(creditScore) ?
      <PoorCredit {...props} />
    :
      <div className='wn-wrapper col-xs-12 completionPage'>
        <WhatsNext {...props} />
        <Finance {...props} />
      </div>
  )
};

export default WhatsNextWrapper;
